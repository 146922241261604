import React, { useEffect, useRef, useState } from "react";
import Footer from "../../layout/Footer";
import Header from "../../layout/Header";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import EditInput from "../../utils/EditInput";
import { ToastContainer } from "react-toastify";
import { errorToaster, formatLotteryError, successToaster } from "../../utils/Helper";
import { betNumbersHandler } from "../../store/slices/betNumbersSlice";
import { FaTrashAlt } from "react-icons/fa";
import { getMethod, postMethod } from "../../services";
import {
  ROYAL_THAI_BET_API,
  TWO_D_LIMIT_API,
  TWO_D_STATUS_API,
} from "../../services/constant";
import { langChange } from "../../lang";
import AddNewNumModal from "../../utils/AddNewNumModel";
import { FiX } from "react-icons/fi";
import Back from "../../utils/Back";
import { infoHandler } from "../../store/slices/userInfoSlice";
import { createPortal } from "react-dom";
import Loader from "../../utils/Loader";
import { getRoyalLotteryType } from "./RoyalHelper";

const RoyalBetSubmit = () => {
  const [betLists, setBetLists] = useState([]);
  const { betListVal } = useSelector((state) => state.betNumbersSlice);
  const navigate = useNavigate();
  const toastId = useRef(null);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [editStatus, setEditStatus] = useState(false);
  const [totalBetAmount, setTotalBetAmount] = useState("");
  const { value } = useSelector((state) => state.userInfoSlice);
  const [closedNums, setClosedNums] = useState([]);
  const params = useParams();
  const [isShowAddNum, setIsShowAddNum] = useState(false);
  const [newNum, setNewNum] = useState("");
  const [errorNewNum, setErrorNewNum] = useState("");
  const [newAmount, setNewAmount] = useState("");
  const [errorNewAmount, setErrorNewAmount] = useState("");
  const [twoDstatus, setTowDStatus] = useState(true);

  const token = JSON.parse(localStorage.getItem("l_555_a"));
  const fetchtowDStatus = async () => {
    if (token) {
      const res = await getMethod(TWO_D_STATUS_API, token.token);
      res.status === 200 && setTowDStatus(true);
    }
  };

  useEffect(() => {
    fetchtowDStatus();
  }, []);

  const fetchTwoDLimit = async () => {
    if (!getRoyalLotteryType(params.time)) {
      navigate(-1);
      return;
    }

    if (!token) {
      navigate("/login");
      return;
    }

    const res = await getMethod(
      `${TWO_D_LIMIT_API}?type=Royal Thai&time=${getRoyalLotteryType(params.time)}`,
      token.token
    );

    if (!res) {
      return;
    }

    const tempLimitNums = res?.data
      ?.filter((item) => !item.status || item.percentage >= 100)
      ?.map((item) => ({ number: item.number }));

    setClosedNums(tempLimitNums);
  };

  useEffect(() => {
    fetchTwoDLimit();
  }, []);

  useEffect(() => {
    if (betListVal.length > 0) {
      setBetLists(betListVal);
    } else {
      navigate(-1);
    }
  }, [betListVal]);

  const editStatusHandler = (status) => {
    setEditStatus(status);
  };

  const getEditAmount = (id, amount) => {
    let temp = [];
    betLists.map((bet) => {
      if (bet.id === id) {
        const cloneObj = { ...bet };
        cloneObj["amount"] = amount;
        temp.push(cloneObj);
      } else {
        temp.push(bet);
      }
    });
    setBetLists(temp);
  };

  const betSubmitHandler = async () => {
    let type = getRoyalLotteryType(params.time);
    if (twoDstatus) {
      if (!editStatus) {
        if (value?.user_info?.current_balance >= totalBetAmount) {
          setLoading(true);
          if (token) {
            let finalBetLists = [];
            betLists.length > 0 &&
              betLists.map((item) =>
                finalBetLists.push({
                  digit: item.number,
                  amount: item.amount,
                  lottery_time: type,
                })
              );
            const res = await postMethod(
              ROYAL_THAI_BET_API,
              { data: { ...finalBetLists } },
              token.token, false
            );
            if (res.con) {
              successToaster("Successful", toastId);
              dispatch(infoHandler({ ...value, refresh: true }));
              setTimeout(() => {
                dispatch(betNumbersHandler([]));
                setLoading(false);
              }, 1000);
            } else {
              setLoading(false);
              formatLotteryError(res, finalBetLists);
            }
          } else {
            navigate("/login");
          }
        } else {
          errorToaster("Balance insufficient to bet!", toastId);
        }
      } else {
        errorToaster("Confirm amount edit!", toastId);
      }
    } else {
      errorToaster("သတ်မှတ်ထီထိုးချိန် ကျော်လွန်သွားပါပြီ။", toastId);
      setTimeout(() => {
        navigate(-2);
      }, 1500);
    }
  };
  const deleteHandler = (id) => {
    setBetLists(betLists.filter((bet) => bet.id !== id));
  };

  useEffect(() => {
    if (betLists.length > 0) {
      const sumWithInitial = betLists.reduce(
        (accumulator, currentValue) =>
          Number(accumulator) + Number(currentValue.amount),
        0
      );
      setTotalBetAmount(sumWithInitial);
    }
  }, [betLists]);

  const newNumHandler = (e) => {
    const value = e.target.value;
    const positiveNumberPattern = /^[+]?\d*\.?\d*$/;
    if (positiveNumberPattern.test(value)) {
      const twoTemp = value.slice(0, 2);
      setNewNum(twoTemp);
      if (
        closedNums.length > 0 &&
        closedNums?.find((num) => num.number === twoTemp)
      ) {
        setErrorNewNum(`Sorry, ${twoTemp} is not avaliable!`);
      } else {
        setErrorNewNum("");
      }
    }
  };

  const newAmountHandler = (e) => {
    const amount = e.target.value;
    const positiveNumberPattern = /^[+]?\d*\.?\d*$/;
    positiveNumberPattern.test(amount) && setNewAmount(amount);
    Number(amount) + Number(totalBetAmount) >
      value?.user_info?.current_balance &&
      setErrorNewAmount("Insufficient balance!");
    amount.length > 0 &&
      Number(amount) < 100 &&
      setErrorNewAmount("Minimum bet amount is 100");
    if (
      value?.user_info?.current_balance >=
        Number(amount) + Number(totalBetAmount) &&
      amount.length > 0 &&
      Number(amount) >= 100
    ) {
      setErrorNewAmount("");
    }
  };

  const isShowHandler = () => {
    setNewAmount("");
    setNewNum("");
    setErrorNewAmount("");
    setErrorNewNum("");
    setIsShowAddNum(true);
  };

  const addNewNumHandler = () => {
    const newNumber = {
      id: newNum,
      number: newNum,
      amount: newAmount,
      time: params.time,
    };
    if (newNum.length > 0) {
      if (newNum.length === 2) {
        if (newAmount.length > 0) {
          if (+newAmount >= 100) {
            if (betLists.length > 0) {
              const result = betLists.filter(
                (number) => `${number.number}` === `${newNum}`
              );
              if (result.length > 0) {
                const addAmount = [];
                for (let i = 0; i < betLists.length; i++) {
                  if (`${betLists[i].number}` === `${newNum}`) {
                    const item = {
                      id: betLists[i].number,
                      number: betLists[i].number,
                      amount: Number(betLists[i].amount) + Number(newAmount),
                      time: betLists[i].time,
                    };
                    addAmount.push(item);
                  } else {
                    addAmount.push(betLists[i]);
                  }
                }
                setBetLists(addAmount);
              } else {
                setBetLists([...betLists, newNumber]);
              }
            } else {
              setBetLists([newNumber]);
            }
            setIsShowAddNum(false);
          } else {
            setErrorNewAmount("Minimum bet amount is 100!");
          }
        } else {
          setErrorNewAmount("Bet Amount is required!");
        }
      } else {
        setErrorNewNum("Bet number must be 2 digits!");
      }
    } else {
      setErrorNewNum("Bet number is required!");
    }
  };

  const { lang } = useSelector((state) => state.lang);
  useEffect(() => {}, [lang]);

  const checkR = (num, arr) => {
    const result = arr.filter(
      (number) => `${number.number[1]}${number.number[0]}` === `${num}`
    );
    if (result.length > 0) {
      return true;
    } else {
      return false;
    }
  };

  const rHandler = (num, amount) => {
    const result = betLists.filter(
      (number) => `${number.number}` === `${num[1]}${num[0]}`
    );
    if (result.length > 0) {
      const noR = betLists.filter(
        (number) => `${number.number}` !== `${num[1]}${num[0]}`
      );
      setBetLists(noR);
    } else {
      if (
        closedNums.length > 0 &&
        closedNums?.find(
          (number) => `${number.number}` === `${num[1]}${num[0]}`
        )
      ) {
        errorToaster(`Sorry, ${num[1]}${num[0]} is not avaliable!`, toastId);
      } else {
        if (
          Number(amount) + totalBetAmount >
          value?.user_info?.current_balance
        ) {
          errorToaster(`Balace insufficient!`, toastId);
        } else {
          const makeR = {
            id: num,
            number: `${num[1]}${num[0]}`,
            amount: amount,
            time: params.time,
          };
          setBetLists([...betLists, makeR]);
        }
      }
    }
  };
  return (
    <>
      <Header />
      <Back />
      <div className="min-h-[85vh]">
        <div className="container mx-auto">
          <div className="w-[95%] max-w-[500px] mx-auto mb-10">
            <div className="flex justify-end mb-3">
              <button
                className="border border-[var(--accent-color)] bg-[var(--accent-color)] text-[var(--main-color)] hover:border-[var(--main-color)] rounded p-[6px] px-4 text-[16px]"
                onClick={isShowHandler}
                disabled={loading}
              >
                + {langChange.add}
              </button>
            </div>
            <div className="relative overflow-x-auto">
              <table className="w-full text-sm text-left text-gray-500">
                <thead className="text-xs text-gray-700 uppercase bg-[--light-color] border border-[var(--light-color)]">
                  <tr className="text-white whitespace-nowrap">
                    <th scope="col" className="px-6 py-4 text-center">
                      {langChange.sr}
                    </th>
                    <th scope="col" className="px-6 py-4 text-center">
                      {langChange.number}
                    </th>
                    <th scope="col" className="px-6 py-4 text-center">
                      {langChange.amount}
                    </th>
                    <th scope="col" className="px-6 py-4 text-center">
                      {langChange.time}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {betLists.length > 0 &&
                    betLists.map((list, i) => (
                      <tr
                        className="bg-[#2d3658] border border-[var(--light-color)] text-white whitespace-nowrap"
                        key={list.number}
                      >
                        <td
                          scope="row"
                          className="px-6 py-4 font-medium whitespace-nowrap text-center"
                        >
                          {++i}.
                        </td>
                        <td className="text-center">
                          <div className="flex justify-center items-center">
                            <div>{list.number}</div>
                            {`${list.number[0]}` !== `${list.number[1]}` && (
                              <div
                                className={`h-6 w-6 leading-6 border border-[var(--accent-color)] ms-2 rounded-full cursor-pointer ${
                                  checkR(list.number, betLists)
                                    ? "bg-[var(--accent-color)] text-[var(--text-color)]"
                                    : "bg-none"
                                }`}
                                onClick={() =>
                                  rHandler(list.number, list.amount)
                                }
                              >
                                R
                              </div>
                            )}
                          </div>
                        </td>
                        <td className="px-6 py-4 flex justify-center items-center">
                          <EditInput
                            id={list.number}
                            type="text"
                            amount={list.amount}
                            editStatusHandler={editStatusHandler}
                            totalAmount={totalBetAmount - +list.amount}
                            getEditAmount={getEditAmount}
                          />
                          <FaTrashAlt
                            className="ms-3 text-red-600 cursor-pointer hover:text-red-700"
                            onClick={() => deleteHandler(list.id)}
                          />
                        </td>
                        <td className="px-6 py-4 text-center">
                          {list.time}
                        </td>
                      </tr>
                    ))}
                  {betLists.length > 0 && (
                    <tr className="bg-[--light-color] border border-[var(--light-color)] text-white whitespace-nowrap">
                      <td colSpan={2} className="py-3 text-center">
                        {langChange.total_bet_amount}
                      </td>
                      <td className="py-3 text-center">
                        {totalBetAmount.toLocaleString("en-us")}
                      </td>
                      <td></td>
                    </tr>
                  )}
                </tbody>
                <tfoot>
                  <tr>
                    <td colSpan={4}>
                      <button
                        type="button"
                        className="border mt-4 w-full border-[var(--main-color)] bg-[var(--accent-color)] text-[var(--main-color)] rounded py-3 text-[16px]"
                        disabled={loading}
                        onClick={betSubmitHandler}
                      >
                        {loading ? langChange.loading : langChange.bet}
                      </button>
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <AddNewNumModal isShow={isShowAddNum} setIsShow={setIsShowAddNum}>
        <div className="flex justify-between items-center mb-5 mt-2 border-b-[.5px] border-b-slate-300 pb-1">
          <p>{langChange.add_new_2d_number}</p>
          <FiX
            className="text-2xl cursor-pointer"
            onClick={() => setIsShowAddNum(false)}
          />
        </div>
        <div className="mb-4">
          <label htmlFor="newNum" className="pb-3">
            {langChange.number}
          </label>
          <input
            className="w-full bg-white p-2 rounded-md text-slate-900"
            placeholder={langChange.enter_number}
            type="text"
            value={newNum}
            onChange={newNumHandler}
          />
          {errorNewNum && (
            <span className="mt-2 block text-red-600">{errorNewNum}</span>
          )}
        </div>
        <div className="mb-4">
          <label htmlFor="newNum" className="pb-3">
            {langChange.amount}
          </label>
          <input
            className="w-full bg-white p-2 rounded-md text-slate-900"
            placeholder={langChange.enter_amount}
            type="text"
            value={newAmount}
            onChange={newAmountHandler}
          />
          {errorNewAmount && (
            <span className="mt-2 block text-red-600">{errorNewAmount}</span>
          )}
        </div>
        <div className="flex justify-center">
          <button
            className={`border border-[var(--accent-color)] bg-[var(--accent-color)] text-[var(--main-color)] rounded p-[6px] px-4 text-[16px] mb-2 ${
              errorNewNum.length > 0 || errorNewAmount.length > 0
                ? "cursor-not-allowed hover:border-[var(--accent-color)]"
                : "cursor-pointer hover:border-[var(--main-color)]"
            }`}
            onClick={addNewNumHandler}
            disabled={errorNewNum.length > 0 || errorNewAmount.length > 0}
          >
            + {langChange.add}
          </button>
        </div>
      </AddNewNumModal>
      <ToastContainer limit={1} />
      {createPortal(loading && <Loader />, document.getElementById("portal"))}
    </>
  );
};

export default RoyalBetSubmit;

import React, { useEffect, useState } from 'react';
import Header from '../layout/Header';
import Footer from '../layout/Footer';
import { FiLock, FiLogOut, FiPhoneCall } from 'react-icons/fi';
import { Link, useNavigate } from 'react-router-dom';
import lang_image from '../assets/images/icons/Translate.png'
import { langChange } from '../lang';
import { useDispatch, useSelector } from 'react-redux';
import DetailModel from '../utils/DetailModel';
import mm_flag from '../assets/images/icons/myanmar.png'
import eng_flag from '../assets/images/icons/english.png'
import { langTypeHandler } from '../store/slices/langSlice';
import { FaCrown, FaPencilRuler } from 'react-icons/fa';
import SingleUserInfo from '../utils/SingleUserInfo';
import Divider from '../utils/Divider';
import Spinner from '../utils/Spinner';
import Title from '../utils/Title';
import profile_img from "../assets/images/vip-person.png";
import ReferalCode from './ReferalCode';

const Profile = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [ isLangChange, setIsLangChange ] = useState(false);
  const { value } = useSelector((state) => state.userInfoSlice);

  const { lang } = useSelector(state => state.lang);
  useEffect(() => {}, [lang]);

  const logoutHandler = () => {
    const token = localStorage.getItem('l_555_a');
    if(token){
       localStorage.removeItem('l_555_a');
       navigate('/');
    }else{
       navigate('/');
    };
  };

  const langHandler = (type) => {
    localStorage.setItem("s_y_m_lang", type);
    langChange.setLanguage(type);
    dispatch(langTypeHandler(type));
};

  return (
    <div>
        <Header />
          <div className='min-h-[85vh]'>
              <div className='container mx-auto mb-5'>
              {
                localStorage.getItem("l_555_a") &&
                <div className='w-[95%] max-w-[500px] p-3 mx-auto mt-5 bg-[var(--light-color)] border border-[var(--secondary-color)] rounded-lg'>
                    <div className="relative">
                      <img src={profile_img} className="w-[200px] mx-auto" />
                      <p className="text-lg font-semibold absolute bottom-[18px] left-[50%] translate-x-[-50%] text-white">
                        {value?.user_info?.name ?? <Spinner />}
                      </p>
                    </div>
                    <Title name={langChange.profile_detail} />
                    <Divider />
                    <SingleUserInfo label={langChange.user_code} value={value?.user_info?.id} />
                    <SingleUserInfo label={langChange.name} value={value?.user_info?.name} />
                    <SingleUserInfo label={langChange.phone} value={value?.user_info?.phone} />
                    <SingleUserInfo label={langChange.main_pocket} value={`${value?.user_info?.current_balance?.toLocaleString('en-us')}`} extra_label={langChange.kyat} />
                    <SingleUserInfo label={langChange.game_pocket} value={value?.game_info?.balance?.toLocaleString('en-us')} extra_label={langChange.kyat} />
                    {
                      value?.user_info?.id && <>
                      {
                        value?.user_info?.is_referral_code ?
                        <SingleUserInfo label={langChange.referral_code} value={`${value?.user_info?.referral_code}`} />
                        :
                        <ReferalCode />
                      }
                      </>
                    }
                    <div className='mt-5'>
                    <Title name={langChange.user_lottery_win} />
                    <Divider />
                    <div className='grid grid-flow-row grid-cols-2 gap-3'>
                      <Link to={'/2d-user-win'} className='bg-[var(--main-color)] border border-[var(--gold-color)] rounded-md py-2 text-[var(--gold-color)] flex flex-col justify-center items-center text-center cursor-pointer hover:opacity-80'>
                        <FaCrown className='text-xl' />
                        <p className='octin-font text-xl md:text-3xl tracking-wider'>2D</p>
                      </Link>
                      <Link to={'/3d-user-win'} className='bg-[var(--main-color)] border border-[var(--gold-color)] rounded-md py-2 text-[var(--gold-color)] flex flex-col justify-center items-center text-center cursor-pointer hover:opacity-80'>
                        <FaCrown className='text-xl' />
                        <p className='octin-font text-xl md:text-3xl tracking-wider'>3D</p>
                      </Link>
                    </div>
                    </div>
                    <ul className='mt-5'>
                      <li className='cursor-pointer'>
                        <div className='p-3 rounded-lg text-base flex items-center justify-between'>
                            <div className='flex items-center' onClick={() => setIsLangChange(true)}>
                              <img src={lang_image} className='w-[22px]' />
                              <span className='ml-2'><span className='text-sm'>{langChange.myanmar}</span> / Eng / Zaygyi / Unicode</span>
                            </div>
                        </div>   
                      </li>
                      <li className='cursor-pointer'>
                      <Link to="/rules-and-regulations">
                          <div className='p-3 rounded-lg text-base flex items-center justify-between'>
                              <div className='flex items-center'>
                              <FaPencilRuler className='text-2xl text-[#ddd]' />
                                <span className='ml-2'>{langChange.rule_and_regulation}</span>
                              </div>
                          </div>  
                          </Link>
                      </li>
                      <li className='cursor-pointer'>
                          <Link to="/change-password">
                              <div className='p-3 rounded-lg text-base flex items-center justify-between'>
                                  <div className='flex items-center'>
                                    <FiLock className='text-2xl text-[#ddd]' />
                                    <span className='ml-2'>{langChange.change_password}</span>
                                  </div>
                              </div>   
                          </Link>   
                      </li>
                      <li className='cursor-pointer'>
                        <Link to={'/contact'}>
                          <div className='p-3 rounded-lg text-base flex items-center justify-between'>
                              <div className='flex items-center'>
                                <FiPhoneCall className='text-[22px] text-[#ddd]' />
                                <span className='ml-2'>{langChange.contact}</span>
                              </div>
                          </div>
                        </Link>
                      </li>
                      <li onClick={logoutHandler} className='cursor-pointer'>
                          <div className='p-3 rounded-lg text-base flex items-center justify-between'>
                              <div className='flex items-center'>
                                <FiLogOut className='text-2xl text-[#ddd]' />
                                <span className='ml-2'>{langChange.logout}</span>
                              </div>
                          </div>  
                      </li>
                  </ul>
                </div>
              }
              </div>
          </div>
        <Footer />
        <DetailModel isShow={isLangChange} setIsShow={setIsLangChange}>
          <ul>
            <li className='p-2 flex items-center cursor-pointer' onClick={() => langHandler('unicode')}>
              <img className={`w-[30px] mr-1 rounded-full ${lang === 'unicode' ? 'border-2 border-white' : ''}`} src={mm_flag} alt="mm_flag" />
              <span>{langChange.myanmar} (Unicode)</span>
            </li>
            <li className='p-2 flex items-center cursor-pointer' onClick={() => langHandler('zawgyi')}>
            <img className={`w-[30px] mr-1 rounded-full ${lang === 'zawgyi' ? 'border-2 border-white' : ''}`} src={mm_flag} alt="mm_flag" />
              <span>{langChange.myanmar} (Zaygyi)</span>
            </li>
            <li className='p-2 flex items-center cursor-pointer' onClick={() => langHandler('english')}>
            <img className={`w-[30px] p-[2px] mr-1 rounded-full ${lang === 'english' ? 'border-2 border-white' : ''}`} src={eng_flag} alt="mm_flag" />
              <span>English</span>
            </li>
          </ul>
      </DetailModel>
    </div>
  )
}

export default Profile

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Layout_section-title__JfdA5 {
    padding: 20px 0 20px 0;
}

.Layout_section-title__JfdA5 p {
    margin: 0;
    font-size: 22px;
    text-transform: uppercase;
    text-align: center;
    color: var(--gold-color);
}
.Layout_section-title__JfdA5 p strong {
    font-weight: 600;
    margin-left: 10px;
}

@media screen and (max-width: 1024px){
    .Layout_section-title__JfdA5 h2 {
        font-size: 12px;
    }
    .Layout_section-title__JfdA5 p {
        font-size: 20px;
    }
}`, "",{"version":3,"sources":["webpack://./src/assets/styles/Layout.module.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;AAC1B;;AAEA;IACI,SAAS;IACT,eAAe;IACf,yBAAyB;IACzB,kBAAkB;IAClB,wBAAwB;AAC5B;AACA;IACI,gBAAgB;IAChB,iBAAiB;AACrB;;AAEA;IACI;QACI,eAAe;IACnB;IACA;QACI,eAAe;IACnB;AACJ","sourcesContent":[".section-title {\n    padding: 20px 0 20px 0;\n}\n\n.section-title p {\n    margin: 0;\n    font-size: 22px;\n    text-transform: uppercase;\n    text-align: center;\n    color: var(--gold-color);\n}\n.section-title p strong {\n    font-weight: 600;\n    margin-left: 10px;\n}\n\n@media screen and (max-width: 1024px){\n    .section-title h2 {\n        font-size: 12px;\n    }\n    .section-title p {\n        font-size: 20px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"section-title": `Layout_section-title__JfdA5`
};
export default ___CSS_LOADER_EXPORT___;

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import ProviderCard from "../utils/ProviderCard";
import { ToastContainer } from "react-toastify";

const GameProvider = ({providers}) => {
  const navigate = useNavigate();
  const [imageLoad, setImageLoad] = useState(true);

  const gamesListHandler = (provider) => {
    if (provider) {
      navigate(
        `/${provider.type ? provider.type.toLowerCase() : "all"}/${
          provider.provider_id ? provider.provider_id : provider.id
        }`
      );
    }
  };

  const slider = document.querySelector(".own-scroll");
  if (slider) {
    let mouseDown = false;
    let startX, scrollLeft;
    let startDragging = function (e) {
      mouseDown = true;
      startX = e.pageX - slider.offsetLeft;
      scrollLeft = slider.scrollLeft;
    };
    let stopDragging = function () {
      mouseDown = false;
    };

    slider.addEventListener("mousemove", (e) => {
      e.preventDefault();
      if (!mouseDown) {
        return;
      }
      const x = e.pageX - slider.offsetLeft;
      const scroll = x - startX;
      slider.scrollLeft = scrollLeft - scroll;
    });

    slider.addEventListener("mousedown", startDragging, false);
    slider.addEventListener("mouseup", stopDragging, false);
    slider.addEventListener("mouseleave", stopDragging, false);
  }

  return (
    <>
      <div className="w-[95%] max-w-[1215px] mx-auto">
        <ul className="grid grid-flow-row grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-2 md:gap-3">
          {providers.length > 0 &&
            providers.map((provider, i) => (
              <ProviderCard
                key={i}
                name={provider?.full_name}
                image={provider?.photo}
                imageLoad={imageLoad}
                setImageLoad={setImageLoad}
                event={(e) => gamesListHandler(provider)}
              />
            ))}
        </ul>
      </div>
      <ToastContainer limit={1} />
    </>
  );
};

export default GameProvider;

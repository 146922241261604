import React, { useEffect, useState } from "react";
import Header from "../../layout/Header";
import Footer from "../../layout/Footer";
import { useSelector } from "react-redux";
import noteImg from "../../assets/images/icons/history.png";
import prizeImg from "../../assets/images/icons/winner.png";
import numberImg from "../../assets/images/icons/threed.png";
import { useNavigate } from "react-router-dom";
import { getMethod } from "../../services";
import { THREE_D_STATUS_API } from "../../services/constant";
import { langChange } from "../../lang";
import Back from "../../utils/Back";
import Container from "../../utils/Container";
import GoldThreedResultHistory from "./GoldThreedResultHistory";

const GoldThreed = () => {
  const navigate = useNavigate();
  const [threeDStatus, setThreeDStatus] = useState(true);

  const token = JSON.parse(localStorage.getItem("l_555_a"));
  const fetchThreeDStatus = async () => {
    if (token) {
      const res = await getMethod(THREE_D_STATUS_API, token.token);
      res.status === 200 && setThreeDStatus(true);
    }
  };

  const { lang } = useSelector((state) => state.lang);
  useEffect(() => {}, [lang]);

  useEffect(() => {
    fetchThreeDStatus();
  }, []);

  const threeDStatusHandler = () => {
    if (threeDStatus) {
      navigate("number-lists");
    } else {
      navigate("/closed");
    }
  };

  return (
    <>
      <Header />
      <Back />
      <Container>
        <ul>
          <li className="bg-[var(--light-color)] border border-[var(--light-color)] p-3 rounded-lg text-[13px] flex items-center justify-between">
            <div
              className="w-1/3 flex items-center justify-center flex-col cursor-pointer"
              onClick={() => navigate("history")}
            >
              <img src={noteImg} className="w-[25px] mr-2" />
              <p className="text-[13px] mb-0 mt-1">{langChange.history}</p>
            </div>
            <div
              className="w-1/3 flex items-center justify-center flex-col cursor-pointer"
              onClick={() => navigate("winner-lists")}
            >
              <img src={prizeImg} className="w-[25px]" />
              <p className="text-[13px] mb-0 mt-1">{langChange.winner}</p>
            </div>
            <div
              className="w-1/3 flex items-center justify-center flex-col cursor-pointer"
              onClick={threeDStatusHandler}
            >
              <img src={numberImg} className="w-[25px]" />
              <p className="text-[13px] mb-0 mt-1">{langChange.bet}</p>
            </div>
          </li>
        </ul>
        <div className="mt-6">
          <GoldThreedResultHistory />
        </div>
      </Container>
      <Footer />
    </>
  );
};

export default GoldThreed;

import React, { useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import Header from "../layout/Header";
import Footer from "../layout/Footer";
import Layout from "../utils/Layout";
import { useParams } from "react-router-dom";
import {
  PROVIDER_API,
  GAMES_LIST_API,
  GAME_LAUNCH_API,
} from "../services/constant";
import { getMethod, postMethod } from "../services";
import Pagination from "../utils/Pagination";
import classes from "../assets/styles/GameList.module.css";
import GameCard from "../utils/GameCard";
import Loader from "../utils/Loader";
import { useSelector } from "react-redux";
import { langChange } from "../lang";
import { errorToaster } from "../utils/Helper";
import { ToastContainer } from "react-toastify";

const GameList = () => {
  const [loading, setLoading] = useState(true);
  const [provider, setProvider] = useState({});
  const [games, setGames] = useState([]);
  const [filterGames, setFilterGames] = useState([]);
  const [searchGames, setSearchGames] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [from, setFrom] = useState(1);
  const params = useParams();
  const [imageLoad, setImageLoad] = useState(true);
  const { value } = useSelector((state) => state.userInfoSlice);
  const toastId = useRef(null);

  const fetchProvider = async () => {
    const res = await getMethod(`${PROVIDER_API}/${params.id}`);
    if (res) {
      setLoading(false);
      if (res === "Failed to fetch") {
        errorToaster("Please check internet connection!", toastId);
      }
      if (res.con) {
        setProvider(res.data);
      }
    }
  };
  useEffect(() => {
    fetchProvider();
  }, []);

  const fetchGames = async () => {
    if (provider.name) {
      setLoading(true);
      let res = await getMethod(
        `${GAMES_LIST_API}?system_status=active&website=true&g_type=${params.name}&p_code=${provider?.name}&sortColumn=g_type&page=${page}&limit=500`
      );
      if (res) {
        setLoading(false);
        if (res.con) {
          setGames(res.data);
          setFilterGames([]);
          setSearchGames("");
          setFrom(res?.meta?.from);
          setTotalPage(res?.meta?.last_page);
        }
      }
    }
  };

  useEffect(() => {
    fetchGames();
  }, [provider, page]);

  const searchGameHandler = (e) => {
    let str = e.target.value;
    setSearchGames(e.target.value);
    let arr = str.split(" ");
    for (var i = 0; i < arr.length; i++) {
      arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
    }
    let str2 = arr.join(" ");
    let temp = [];
    if (games.length > 0) {
      games.map((el) => {
        if (el.gameName.startsWith(str2)) {
          temp.push(el);
        }
      });
    }
    setFilterGames(temp);
  };

  const launch = async (name, type, code, newPara) => {
    setLoading(true);
    const token = localStorage.getItem("l_555_a");
    if (token) {
      const deToken = JSON.parse(token);
      const data = {
        provider_name: name,
        p_code: name,
        p_type: type,
        g_code: code,
        h5: newPara,
      };
      const res = await postMethod(GAME_LAUNCH_API, data, deToken.game_token);
      if (res) {
        if (res === "Failed to fetch") {
            errorToaster('Please check internet connection!', toastId);
        }
        setLoading(false);
        if (res.con) {
          if (res.data.gameUrl) {
            if (!window.open(res.data.gameUrl)) {
              window.location.href = res.data.gameUrl;
            }
          }
        }
      }
    }
  };

  const launchGame = (name, type, code, h5) => {
    let newPara = h5 === "mobile" ? 1 : 0;
    launch(name, type, code, newPara);
  };

  const { lang } = useSelector((state) => state.lang);
  useEffect(() => {}, [lang]);

  return (
    <>
      <div>
        <Header />
        <Layout name={provider?.full_name} type="">
          <div className="w-[95%] mx-auto min-h-screen pb-10">
            <div className="pb-4 items-center my-2 bg-[var(--secondary-color)] sticky top-[64px] lg:top-[72px] rounded-md p-2 py-3">
              <div className="flex justify-between">
                <p className="mb-3 text-base md:text-xl font-semibold text-[#f5f5f5]">
                  {langChange.total} - {games?.length}
                </p>
                <p className="mb-3 text-base md:text-xl font-semibold text-[#f5f5f5]">
                  {langChange.wallet} -{" "}
                  {value?.game_info?.balance.toLocaleString("en-us")}
                </p>
              </div>
              <div className="flex flex-col md:flex-row justify-between items-end">
                <div>
                  {totalPage > 1 && (
                    <div className="">
                      <Pagination
                        setPage={setPage}
                        totalPage={totalPage}
                        setLoading={setLoading}
                      />
                    </div>
                  )}
                </div>
                <div className={`${classes.form__group} ${classes.field}`}>
                  <input
                    type="input"
                    className={classes.form__field}
                    placeholder={langChange.search_game}
                    id="name"
                    onChange={searchGameHandler}
                    style={{ borderBottom: "1px solid #eee" }}
                  />
                  <label htmlFor="name" className={classes.form__label}>
                    {langChange.search_game}
                  </label>
                </div>
              </div>
            </div>
            <div>
              <ul className="flex items-center justify-center flex-wrap w-full">
                {searchGames.length > 0
                  ? filterGames.length > 0 &&
                    filterGames.map((game, i) => (
                      <GameCard
                        key={i}
                        name={`${game.gameName.slice(0, 10)} ...`}
                        image={game.imgFileName}
                        imageLoad={imageLoad}
                        setImageLoad={setImageLoad}
                        event={() =>
                          launchGame(
                            game.p_code,
                            game.p_type,
                            game.g_code,
                            game.h5
                          )
                        }
                      />
                    ))
                  : games.length > 0 &&
                    games.map((game, i) => (
                      <GameCard
                        key={i}
                        name={`${game.gameName.slice(0, 10)} ...`}
                        image={game.imgFileName}
                        imageLoad={imageLoad}
                        setImageLoad={setImageLoad}
                        event={() =>
                          launchGame(
                            game.p_code,
                            game.p_type,
                            game.g_code,
                            game.h5
                          )
                        }
                      />
                    ))}
              </ul>
            </div>
          </div>
        </Layout>
        <Footer />
      </div>
      {ReactDOM.createPortal(
        loading && <Loader />,
        document.getElementById("portal")
      )}
      <ToastContainer limit={1} />
    </>
  );
};

export default GameList;

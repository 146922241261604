import React, { useRef, useState } from "react";
import { postMethod } from "../services";
import { useDispatch, useSelector } from "react-redux";
import { errorToaster, successToaster } from "../utils/Helper";
import { infoHandler } from "../store/slices/userInfoSlice";
import { ADD_REFERRAL_CODE_API } from "../services/constant";
import SweetAlert from 'sweetalert2';
import { createPortal } from "react-dom";
import Loader from "../utils/Loader";
import { ToastContainer } from "react-toastify";

export default function ReferalCode() {
  const [referralCode, setReferralCode] = useState("");
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { value } = useSelector((state) => state.userInfoSlice);
  const toastId = useRef(null);

  const post = async (token) => {
    setLoading(true);
    const res = await postMethod(
      ADD_REFERRAL_CODE_API,
      { referral_code: referralCode },
      token.token
    );
    res && setLoading(false);
    if (res?.con) {
      successToaster('ရည်ညွှန်းကုဒ်ထည့်သွင်းခြင်း အောင်မြင်ပါသည်။', toastId)
      dispatch(infoHandler({...value, refresh: true}));
      return;
    }
  };

  const addCode = async () => {
    const token = JSON.parse(localStorage.getItem("l_555_a"));
    if (!token) return;
    if (!referralCode) {
      errorToaster('ကျေးဇူးပြု၍ ရည်ညွှန်းကုဒ်ထည့်သွင်းပေးပါ။', toastId)
      return;
    }
    SweetAlert.fire({
      text: `ရည်ညွှန်းကုဒ် ${referralCode} ကို ထည့်မည်မှာ သေချာပြီလား။`,
      showDenyButton: true,
      confirmButtonText: "သေချာပါသည်။",
      denyButtonText: `မဟုတ်ပါ။`,
    }).then((result) => {
      if (result.isConfirmed) {
        post(token);
      }
    });
  };
  return (
    <>
      <div className="max-w-[500px] mx-auto flex mt-3 border border-white rounded-md shadow-lg">
        <input
          type="text"
          className="p-2 px-3 text-blue-900 octin-font tracking-[2px] rounded-s-md w-[60%]"
          placeholder="မိတ်ဆက်ကုဒ် ဖြည့်ပါ။"
          onChange={(e) => setReferralCode(e.target.value)}
        />
        <button
          className="p-2 px-3 bg-[var(--main-color)] text-[var(--gold-color)] rounded-e-md w-[40%] hover:opacity-80"
          onClick={addCode}
        >
          အတည်ပြုမည်။
        </button>
      </div>
      {
        createPortal(loading && <Loader />, document.getElementById('portal'))
      }
      <ToastContainer />
    </>
  );
}

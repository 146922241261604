import React, { useEffect, useState } from "react";
import "@hassanmojab/react-modern-calendar-datepicker/lib/DatePicker.css";
import DatePicker from "@hassanmojab/react-modern-calendar-datepicker";
import { FiCalendar } from "react-icons/fi";

export default function SingleDatePicker({ date, setDate }) {

  const [pickDate, setPickDate] = useState(null);

  useEffect(() => {
    if (date) {
      const temp = date.split("-");
      setPickDate({
        year: Number(temp[0]),
        month: Number(temp[1]),
        day: Number(temp[2]),
      });
    }
  }, [date]);

  useEffect(() => {
    if (pickDate) {
      setDate(
        `${pickDate.year}-${
          Number(pickDate.month) < 9 ? "0" + pickDate.month : pickDate.month
        }-${Number(pickDate.day) < 9 ? "0" + pickDate.day : pickDate.day}`
      );
    }
  }, [pickDate]);

  const renderCustomInput = ({ ref }) => (
    <label className="flex justify-between items-center bg-white rounded-[5px] px-3 py-[6px] text-blue-900 cursor-pointer">
      <input
      readOnly
      ref={ref}
      value={`${pickDate ? date : 'Select Date'}`}
      className="rounded-[5px] text-start text-[16px] pt-1 text-blue-800 max-w-[125px]"
    />
    <FiCalendar />
    </label>
  )

  return (
    <DatePicker
      calendarPopperPosition="bottom"
      value={pickDate}
      onChange={setPickDate}
      shouldHighlightWeekends
      renderInput={renderCustomInput}
      inputClassName="rounded-[5px] py-[6px] text-start text-[16px] text-blue-800 max-w-[125px]"
      inputPlaceholder="Select Date"
      colorPrimary="red"
    />
  );
}

import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import classes from "../assets/styles/WinnerList.module.css";
import NoData from "../utils/NoData";
import Loader from "../utils/Loader";
import { useNavigate, useParams } from "react-router-dom";
import { getMethod } from "../services";
import { TWO_D_WINNER_RECORD_API } from "../services/constant";
import Pagination from "../utils/Pagination";
import Header from "../layout/Header";
import Footer from "../layout/Footer";
import prizeImg from "../assets/images/icons/winner.png";
import Back from "../utils/Back";
import { langChange } from "../lang";
import Select from "react-select";
import { getRoyalLotteryTime } from "./Royal/RoyalHelper";
import { getGoldLotteryTime } from "./Gold/GoldHelper";
import { getDubaiLotteryTime } from "./Dubai/DubaiHelper";
import moment from "moment-timezone";
import SingleDatePicker from "../utils/SingleDatePicker";

const TwoWinnerList = () => {
  const params = useParams();
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [totalPage, setTotalPage] = useState(0);
  const [from, setFrom] = useState(1);
  const [page, setPage] = useState(1);
  const [lotteryType, setLotteryType] = useState('');
  const [lotteryTypes, setLotteryTypes] = useState([]);
  const [date, setDate] = useState(moment().tz('Asia/Yangon').format('YYYY-MM-DD'));

  const setInitialLottery = () => {
    const { lottery_type } = params;
    let result;
    let go_back = false;
    switch (lottery_type) {
      case 'hero2d':
        result = [
          { value: "One", label: "09:30 AM" },
          { value: "Two", label: "12:00 PM" },
          { value: "Three", label: "02:00 PM" },
          { value: "Four", label: "04:30 PM" },
          { value: "Five", label: "07:00 PM" },
          { value: "Six", label: "09:00 PM" },
        ]
        break;
      case '2d':
        result = [
          { value: 'Thirteen', label: langChange.morning },
          { value: 'Fourteen', label: langChange.evening },
        ]  
        break;
      case 'gold2d':
        result = [
          { value: "One_Gold", label: "09:30 AM" },
          { value: "Two_Gold", label: "12:00 PM" },
          { value: "Three_Gold", label: "02:00 PM" },
          { value: "Four_Gold", label: "04:30 PM" },
          { value: "Five_Gold", label: "07:00 PM" },
          { value: "Six_Gold", label: "09:00 PM" },
        ]
        break; 
      default:
        go_back = true;
        break;
    }
    go_back && navigate(-1)
    if (result?.length > 0) {
      setLotteryType(result[0].value);
      setLotteryTypes(result);
    }
  }

  useEffect(() => {
    setInitialLottery()
  }, [])

  const fetchRecords = async () => {
    const token = JSON.parse(localStorage.getItem("l_555_a"));
    if (token) {
      setLoading(true);
      const type = () => {
        let temp;
        switch (params.lottery_type) {
          case '2d':
            temp = 'MM';
            break;
          case 'gold2d':
            temp = 'Gold';
            break;
        }
        return temp;
      }
      const res = await getMethod(
        `${TWO_D_WINNER_RECORD_API}?type=${type()}&time=${lotteryType}&page=${page}&date=${date}`,
        token.token
      );
      if (res) {
        setLoading(false);
        if (res.data) {
          setData(res.data);
        }
        if (res.meta) {
          if (res.meta.from) {
            setFrom(res.meta.from);
          }
          if (res.meta.last_page) {
            setTotalPage(res.meta.last_page);
          }
        }
      }
    } else {
      navigate("/login");
    }
  };

  useEffect(() => {
    fetchRecords();
  }, [page, lotteryType, date]);

  return (
    <>
      <Header />
      <Back />
      <div className="container mx-auto min-h-[85vh] mb-3">
        <div className="w-[95%] max-w-[500px] mx-auto">
          <div className="flex items-center justify-center flex-col cursor-pointer">
            <img src={prizeImg} className="w-[50px]" />
            <p className="text-[16px] mb-0 mt-3">
            {langChange.winner}
            </p>
          </div>
          <div
            className={`${classes["auth-card-body"]} bg-[var(--light-color)] border border-[var(--light-color)] rounded-lg mt-2`}
          >
            <div className="flex justify-between mb-3">
              <SingleDatePicker date={date} setDate={setDate} />
              <Select
                className="ms-auto w-[140px] text-[var(--secondary-color)] z-[999]"
                placeholder={lotteryTypes?.length > 0 ? lotteryTypes[0].label : ''}
                onChange={(e) => setLotteryType(e.value)}
                options={lotteryTypes}
              />
            </div>
            <div className={classes["table-card-container"]}>
              <ul className={classes["table-header"]}>
                <li>
                  <b>စဉ်</b>
                </li>
                <li>အမည်</li>
                <li>ဖုန်းနံပါတ်</li>
                <li>ထီဂဏန်း</li>
                <li>ထိုးငွေ</li>
                <li>စုစုပေါင်း</li>
                <li>အချိန်</li>
                <li>ရက်စွဲ</li>
              </ul>
              {data.length > 0 ? (
                data.map((winner, i) => (
                  <ul key={i} className={classes["table-header"]}>
                    <li>{i + from}.</li>
                    <li className="text-[#f6e25d]">{winner.name}</li>
                    <li>{"****" + winner.phone?.slice(-4)}</li>
                    <li>{winner.lucky_number}</li>
                    <li>{winner.amount.toLocaleString("en-us")}</li>
                    <li>{winner.total.toLocaleString("en-us")}</li>
                    <li>
                      {getDubaiLotteryTime(winner.lottery_time)}
                      {getRoyalLotteryTime(winner.lottery_time)}
                      {getGoldLotteryTime(winner.lottery_time)}
                      {/* for mm 2d  */}
                      {winner.lottery_time === "Thirteen" && "12:01 PM"}
                      {winner.lottery_time === "Fourteen" && "04:30 PM"}
                    </li>
                    <li>{winner.date}</li>
                  </ul>
                ))
              ) : (
                <NoData loading={loading} />
              )}
            </div>
            {totalPage > 1 && (
              <div className="flex justify-center pt-2">
                <Pagination
                  setPage={setPage}
                  totalPage={totalPage}
                  setLoading={setLoading}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      <Footer />
      {ReactDOM.createPortal(
        loading && <Loader />,
        document.getElementById("portal")
      )}
    </>
  );
};

export default TwoWinnerList;

import React, { useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import Input from '../utils/Input';
import Card from '../utils/Card';
import Button from '../utils/Button';
import { FiLock, FiTablet } from 'react-icons/fi';
import logo from '../assets/images/logo/logo.png';
import { Link, useNavigate } from 'react-router-dom';
import classes from '../assets/styles/Login.module.css'
import { postMethod } from '../services';
import { GAME_REGISTER_API, LOGIN_API } from '../services/constant';
import Loader from '../utils/Loader';
import { langChange } from '../lang';
import Back from '../utils/Back';
import { errorToaster, successToaster } from '../utils/Helper';
import { ToastContainer } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { infoHandler } from '../store/slices/userInfoSlice';

const Login = () => {
    const [loading,setLoading] = useState(false);
    const [phone,setPhone] = useState("");
    const [password,setPassword] = useState("");
    const [errorPhone,setErrorPhone] = useState("");
    const [errorPassword,setErrorPassword] = useState("");
    const navigate = useNavigate();
    const toastId = useRef(null);
    const dispatch = useDispatch();

    const phoneHandler = e => {
        setPhone(e.target.value);
        setErrorPhone("");
    };
    const passwordHandler = e => {
        if(e.target.value.length > 6){
            return;
        }
        setPassword(e.target.value);
        setErrorPassword("");
    };

    const loginHandler = async (e) => {
        e.preventDefault();
        if (!phone) {
          setErrorPhone('Phone is required.');
          return;
        }
        if (!password) {
          setErrorPassword('Password is required.');
          return;
        }
        setLoading(true);
        const data = {
          phone,password,
        };
        const res = await postMethod(LOGIN_API, data);
        if(res){
            setLoading(false);
            if(res === 'Failed to fetch'){
              errorToaster('Please check internet connection!', toastId);
            }
            if(res.con){
              if (!res?.data?.game_token) {
                setLoading(true);
                await postMethod(GAME_REGISTER_API, {}, res?.data?.token).then(gameRes => {
                  setLoading(false);
                  if (gameRes?.con) {
                    const auth = { game_token: gameRes?.data?.game_token, token: res?.data.token, user_role: res.data.game_info?.role, user_id: res.data.user_info.id}
                    localStorage.setItem('l_555_a', JSON.stringify(auth));
                    dispatch(infoHandler({}));
                    successToaster('Login successful!', toastId);
                    setTimeout(() => {
                      navigate('/');
                    }, 500)
                    return;
                  }
                })
              } else {
                successToaster('Login successful!', toastId);
                const auth = {game_token: res.data.game_token, token: res.data.token, user_role: res.data.game_info?.role, user_id: res.data.user_info.id};
                localStorage.setItem('l_555_a', JSON.stringify(auth));
                dispatch(infoHandler({}));
                setTimeout(() => {
                  navigate('/');
                }, 500)
              }
            }else{
              if(res.data?.phone){
                setErrorPhone(res.data.phone[0]);
              };
              if(res.data?.password){
                setErrorPassword(res.data.password[0]);
              };
              if(res.data?.error){
                setErrorPhone(res.data.error);
              };
            };
        };
    };

  return (
    <>
    <Back home={true} />
      <div className='mx-auto h-screen pt-16'>
          <div className='mb-4'>
              <div className='w-24 mx-auto'>
                  <Link to="/">
                      <img src={logo} className='w-full rounded-lg'/>
                  </Link>
              </div>
          </div>
          <Card>
              <form onSubmit={loginHandler}>
                  <Input icon={<FiTablet />} label={langChange.phone} type="number" value={phone} event={phoneHandler} error={errorPhone} placeholder={'09xxxxxxxxx'} />
                  <Input icon={<FiLock />} label={langChange.password} type="password" value={password} event={passwordHandler} error={errorPassword} placeholder={langChange.enter_password} />
                  <div className='text-center'>
                      <Button label={langChange.login} type="submit" disable={loading}/>
                  </div>
              </form>
          </Card>
          <div className={classes['forget-pass']}>
              <Link to="/forgot-password">
                  <p className={classes['forget-text']}>{langChange.forgot_password}</p>
              </Link>
              <hr className={classes['line-through']} />
              <Link to="/register">
                  <p className={classes['forget-text']}>{langChange.create_new_account}</p>
              </Link>
          </div>
      </div>
      {
         ReactDOM.createPortal(loading && <Loader />, document.getElementById('portal'))
      }
      <ToastContainer limit={1} />
    </>
  )
}

export default Login

import React, { useEffect, useRef, useState } from "react";
import Header from "../../layout/Header";
import Footer from "../../layout/Footer";
import Number from "../../utils/Number";
import DetailModel from "../../utils/DetailModel";
import classes from "../../assets/styles/TwodNumberList.module.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { errorToaster } from "../../utils/Helper";
import { useDispatch, useSelector } from "react-redux";
import { betNumbersHandler } from "../../store/slices/betNumbersSlice";
import { getMethod } from "../../services";
import {
  TWO_D_LIMIT_API,
  TWO_D_REAL_TIME_API,
} from "../../services/constant";
import moment from "moment-timezone";
import { langChange } from "../../lang";
import Back from "../../utils/Back";
import { createPortal } from "react-dom";
import Loader from "../../utils/Loader";
import { getRoyalLotteryType } from "./RoyalHelper";

let tempNums = [];
let arrTempNums = [];
let closedTemp = [];
let initialNums = [];
const RoyalNumberList = () => {
  const [twodNumbers, setTowdNumbers] = useState([]);
  const [fastChooseModal, setFastChooseModal] = useState(false);
  const [arrStatus, setArrStatus] = useState(false);
  const [fastStatus, setFastStatus] = useState(false);
  const [activeFastChoose, setActiveFastChoose] = useState(null);
  const [amount, setAmount] = useState("");
  const navigate = useNavigate();
  const toastId = useRef(null);
  const params = useParams();
  const dispatch = useDispatch();
  const { value } = useSelector((state) => state.userInfoSlice);
  const [openTime, setOpenTime] = useState("");
  const [closeTime, setCloseTime] = useState("");
  const [currentTime, setCurrentTime] = useState("");
  const [loading, setLoading] = useState(true);

  const token = JSON.parse(localStorage.getItem("l_555_a"));

  const { lang } = useSelector((state) => state.lang);
  useEffect(() => {}, [lang]);

  const fetchTowDRealTime = async () => {
    if (token) {
      setLoading(true);
      const res = await getMethod(
        TWO_D_REAL_TIME_API + "?type=Royal Thai",
        token.token
      );
      res && setLoading(false);
      if (res.con) {
        if (res.data) {
          res.data.map((row) => {
            if (row.lottery_time === getRoyalLotteryType(params.time)) {
              setOpenTime(row.opening_time);
              setCloseTime(row.closing_time);
            }
          });
        }
      }
    }
  };

  const closedFilter = (initialArray) => {
    initialArray.length > 0 &&
      initialArray.map((num1) => {
        let filterClosed = tempNums.find(
          (num2) => `${num2.number}` === `${num1.number}`
        );
        !filterClosed && closedTemp.push(num1?.number);
      });
    if (closedTemp.length > 0) {
      errorToaster(
        `( ${[...closedTemp]} ) ${
          closedTemp.length > 1 ? "are" : "is"
        } not avaliable!`,
        toastId
      );
      setTimeout(() => {
        closedTemp = [];
        initialNums = [];
        arrTempNums.length > 0 ? setArrStatus(true) : setArrStatus(false);
        tempNums.length > 0 && setArrStatus(false);
      }, 1000);
    }
  };

  const fetchTwoDLimit = async () => {
    if (getRoyalLotteryType(params.time)) {
      tempNums = [];
      let temp = [];
      for (let i = 0; i <= 99; i++) {
        i < 10 ? temp.push(`0${i}`) : temp.push(`${i}`);
      }

      if (token) {
        setLoading(true);
        const res = await getMethod(
          `${TWO_D_LIMIT_API}?type=Royal Thai&time=${getRoyalLotteryType(
            params.time
          )}`,
          token.token
        );
        res && setLoading(false);
        if (res.con) {
          let limitFilterNums = [];
          for (let i = 0; i < temp.length; i++) {
            const matchingNumber = res.data.find(
              (num) => num.number === temp[i]
            );
            if (matchingNumber) {
              limitFilterNums.push({
                number: matchingNumber.number,
                color: matchingNumber.color,
                percentage: matchingNumber.percentage,
                status: matchingNumber.status,
              });
            } else {
              limitFilterNums.push({
                number: temp[i],
                color: "yellow",
                percentage: 0.0,
                status: true,
              });
            }
          }
          // const closeRes = await getMethod(
          //   TWO_D_CLOSED_STATUS_API,
          //   token.token
          // );
          // if (closeRes.con) {
          //   let closedResNums = [];
          //   limitFilterNums.map((num) => {
          //     const finder = closeRes.data.find(
          //       (num2) => num2.number === num.number
          //     );
          //     if (finder) {
          //       closedResNums.push({
          //         number: num.number,
          //         color: num.color,
          //         percentage: num.percentage,
          //         status: false,
          //       });
          //     } else {
          //       closedResNums.push(num);
          //     }
          //   });
          setTowdNumbers(limitFilterNums);
          // }
        }
      } else {
        navigate("/login");
      }
    } else {
      navigate(-1);
    }
  };

  useEffect(() => {
    fetchTowDRealTime();
    setCurrentTime(moment().tz("Asia/Yangon").format("HH:mm:ss"));
  }, []);

  useEffect(() => {
    if (currentTime && closeTime && openTime) {
      if (currentTime > openTime && currentTime < closeTime) {
        fetchTwoDLimit();
      } else {
        errorToaster("သတ်မှတ်ထီထိုးချိန် ကျော်လွန်သွားပါပြီ။", toastId);
        setTimeout(() => {
          navigate(-1);
        }, 1500);
      }
    }
  }, [currentTime, openTime, closeTime]);

  const numberHandler = (event, number, checkStatus, setCheckStatus) => {
    if (!arrStatus) {
      setCheckStatus(!checkStatus);
      let isChecked = event.target.checked;
      if (isChecked) {
        !tempNums.includes({ number: number }) &&
          tempNums.push({ number: number });
      } else {
        tempNums = tempNums.filter((el) => `${el.number}` !== `${number}`);
      }
    }
  };

  const arrHandler = () => {
    if (tempNums.length > 0) {
      setArrStatus(!arrStatus);
      if (!arrStatus) {
        arrTempNums = [];
        let initialArr = [];
        let initialNum = [];
        tempNums.map((i) => {
          let result = twodNumbers.find(
            (x) =>
              x.status &&
              Math.trunc(x.percentage) !== 100 &&
              x.number[0] == i.number[1] &&
              x.number[1] == i.number[0]
          );
          if (result?.number[0] !== result?.number[1]) {
            !arrTempNums.includes({ number: result.number }) &&
              arrTempNums.push(result);
          }
          let initialResult = twodNumbers.find(
            (x) => x.number[0] == i.number[1] && x.number[1] == i.number[0]
          );
          if (initialResult?.number[0] !== initialResult?.number[1]) {
            !initialArr.includes({ number: initialResult.number }) &&
              initialArr.push(initialResult);
          }
        });
        if (arrTempNums) {
          arrTempNums.map(
            (el) =>
              !tempNums.includes({ number: el.number }) && tempNums.push(el)
          );
        }
        if (initialArr) {
          initialArr.map(
            (el) =>
              !tempNums.includes({ number: el.number }) && initialNum.push(el)
          );
        }
        closedFilter(initialNum);
      } else {
        if (arrTempNums.length > 0) {
          arrTempNums.map((tn) => {
            let findNum = tempNums.indexOf(tn);
            tempNums.splice(findNum, 1);
          });
        }
      }
    }
  };

  const fastHandler = (type) => {
    tempNums = [];
    setFastStatus(true);
    setFastChooseModal(false);
    setActiveFastChoose(type);
    setArrStatus(false);
    switch (type) {
      case "စုံစုံ":
        tempNums = twodNumbers.filter(
          (num) =>
            num.status &&
            Math.trunc(num.percentage) < 100 &&
            num.number[0] % 2 == 0 &&
            num.number[1] % 2 == 0
        );
        initialNums = twodNumbers.filter(
          (num) => num.number[0] % 2 == 0 && num.number[1] % 2 == 0
        );
        closedFilter(initialNums);
        break;
      case "မမ":
        tempNums = twodNumbers.filter(
          (num) =>
            num.status &&
            Math.trunc(num.percentage) < 100 &&
            num.number[0] % 2 != 0 &&
            num.number[1] % 2 != 0
        );
        initialNums = twodNumbers.filter(
          (num) => num.number[0] % 2 != 0 && num.number[1] % 2 != 0
        );
        closedFilter(initialNums);
        break;
      case "စုံမ":
        tempNums = twodNumbers.filter(
          (num) =>
            num.status &&
            Math.trunc(num.percentage) < 100 &&
            num.number[0] % 2 == 0 &&
            num.number[1] % 2 != 0
        );
        initialNums = twodNumbers.filter(
          (num) => num.number[0] % 2 == 0 && num.number[1] % 2 != 0
        );
        closedFilter(initialNums);
        break;
      case "မစုံ":
        tempNums = twodNumbers.filter(
          (num) =>
            num.status &&
            Math.trunc(num.percentage) < 100 &&
            num.number[0] % 2 != 0 &&
            num.number[1] % 2 == 0
        );
        initialNums = twodNumbers.filter(
          (num) => num.number[0] % 2 != 0 && num.number[1] % 2 == 0
        );
        closedFilter(initialNums);
        break;
      case "နက္ခတ်":
        const netKhets = [
          { number: "07" },
          { number: "18" },
          { number: "24" },
          { number: "35" },
          { number: "42" },
          { number: "53" },
          { number: "69" },
          { number: "70" },
          { number: "81" },
          { number: "96" },
        ];
        tempNums = twodNumbers.filter(
          (num) =>
            num.status &&
            Math.trunc(num.percentage) < 100 &&
            netKhets.find((netKhet) => `${netKhet.number}` === `${num.number}`)
        );
        closedFilter(netKhets);
        break;
      case "ပါဝါ":
        const powers = [
          { number: "05" },
          { number: "16" },
          { number: "27" },
          { number: "38" },
          { number: "49" },
          { number: "50" },
          { number: "61" },
          { number: "72" },
          { number: "83" },
          { number: "94" },
        ];
        tempNums = twodNumbers.filter(
          (num) =>
            num.status &&
            Math.trunc(num.percentage) < 100 &&
            powers.find((power) => `${power.number}` === `${num.number}`)
        );
        closedFilter(powers);
        break;
      case "အပူး":
        tempNums = twodNumbers.filter(
          (num) =>
            num.status &&
            Math.trunc(num.percentage) < 100 &&
            num.number[0] == num.number[1]
        );
        initialNums = twodNumbers.filter(
          (num) => num.number[0] == num.number[1]
        );
        closedFilter(initialNums);
        break;
      case "ညီအကို":
        tempNums = twodNumbers.filter(
          (num) =>
            num.status &&
            Math.trunc(num.percentage) < 100 &&
            (num.number[1] == +num.number[0] + 1 ||
              num.number[1] == +num.number[0] - 1 ||
              num.number === "09" ||
              num.number === "90")
        );
        initialNums = twodNumbers.filter(
          (num) =>
            num.number[1] == +num.number[0] + 1 ||
            num.number[1] == +num.number[0] - 1 ||
            num.number === "09" ||
            num.number === "90"
        );
        closedFilter(initialNums);
        break;
      case "0ပါတ်":
        tempNums = twodNumbers.filter(
          (num) =>
            num.status &&
            Math.trunc(num.percentage) < 100 &&
            (num.number[0] == 0 || num.number[1] == 0)
        );
        initialNums = twodNumbers.filter(
          (num) => num.number[0] == 0 || num.number[1] == 0
        );
        closedFilter(initialNums);
        break;
      case "1ပါတ်":
        tempNums = twodNumbers.filter(
          (num) =>
            num.status &&
            Math.trunc(num.percentage) < 100 &&
            (num.number[0] == 1 || num.number[1] == 1)
        );
        initialNums = twodNumbers.filter(
          (num) => num.number[0] == 1 || num.number[1] == 1
        );
        closedFilter(initialNums);
        break;
      case "2ပါတ်":
        tempNums = twodNumbers.filter(
          (num) =>
            num.status &&
            Math.trunc(num.percentage) < 100 &&
            (num.number[0] == 2 || num.number[1] == 2)
        );
        initialNums = twodNumbers.filter(
          (num) => num.number[0] == 2 || num.number[1] == 2
        );
        closedFilter(initialNums);
        break;
      case "3ပါတ်":
        tempNums = twodNumbers.filter(
          (num) =>
            num.status &&
            Math.trunc(num.percentage) < 100 &&
            (num.number[0] == 3 || num.number[1] == 3)
        );
        initialNums = twodNumbers.filter(
          (num) => num.number[0] == 3 || num.number[1] == 3
        );
        closedFilter(initialNums);
        break;
      case "4ပါတ်":
        tempNums = twodNumbers.filter(
          (num) =>
            num.status &&
            Math.trunc(num.percentage) < 100 &&
            (num.number[0] == 4 || num.number[1] == 4)
        );
        initialNums = twodNumbers.filter(
          (num) => num.number[0] == 4 || num.number[1] == 4
        );
        closedFilter(initialNums);
        break;
      case "5ပါတ်":
        tempNums = twodNumbers.filter(
          (num) =>
            num.status &&
            Math.trunc(num.percentage) < 100 &&
            (num.number[0] == 5 || num.number[1] == 5)
        );
        initialNums = twodNumbers.filter(
          (num) => num.number[0] == 5 || num.number[1] == 5
        );
        closedFilter(initialNums);
        break;
      case "6ပါတ်":
        tempNums = twodNumbers.filter(
          (num) =>
            num.status &&
            Math.trunc(num.percentage) < 100 &&
            (num.number[0] == 6 || num.number[1] == 6)
        );
        initialNums = twodNumbers.filter(
          (num) => num.number[0] == 6 || num.number[1] == 6
        );
        closedFilter(initialNums);
        break;
      case "7ပါတ်":
        tempNums = twodNumbers.filter(
          (num) =>
            num.status &&
            Math.trunc(num.percentage) < 100 &&
            (num.number[0] == 7 || num.number[1] == 7)
        );
        initialNums = twodNumbers.filter(
          (num) => num.number[0] == 7 || num.number[1] == 7
        );
        closedFilter(initialNums);
        break;
      case "8ပါတ်":
        tempNums = twodNumbers.filter(
          (num) =>
            num.status &&
            Math.trunc(num.percentage) < 100 &&
            (num.number[0] == 8 || num.number[1] == 8)
        );
        initialNums = twodNumbers.filter(
          (num) => num.number[0] == 8 || num.number[1] == 8
        );
        closedFilter(initialNums);
        break;
      case "9ပါတ်":
        tempNums = twodNumbers.filter(
          (num) =>
            num.status &&
            Math.trunc(num.percentage) < 100 &&
            (num.number[0] == 9 || num.number[1] == 9)
        );
        initialNums = twodNumbers.filter(
          (num) => num.number[0] == 9 || num.number[1] == 9
        );
        closedFilter(initialNums);
        break;
      case "0ထိပ်":
        tempNums = twodNumbers.filter(
          (num) =>
            num.status && Math.trunc(num.percentage) < 100 && num.number[0] == 0
        );
        initialNums = twodNumbers.filter((num) => num.number[0] == 0);
        closedFilter(initialNums);
        break;
      case "1ထိပ်":
        tempNums = twodNumbers.filter(
          (num) =>
            num.status && Math.trunc(num.percentage) < 100 && num.number[0] == 1
        );
        initialNums = twodNumbers.filter((num) => num.number[0] == 1);
        closedFilter(initialNums);
        break;
      case "2ထိပ်":
        tempNums = twodNumbers.filter(
          (num) =>
            num.status && Math.trunc(num.percentage) < 100 && num.number[0] == 2
        );
        initialNums = twodNumbers.filter((num) => num.number[0] == 2);
        closedFilter(initialNums);
        break;
      case "3ထိပ်":
        tempNums = twodNumbers.filter(
          (num) =>
            num.status && Math.trunc(num.percentage) < 100 && num.number[0] == 3
        );
        initialNums = twodNumbers.filter((num) => num.number[0] == 3);
        closedFilter(initialNums);
        break;
      case "4ထိပ်":
        tempNums = twodNumbers.filter(
          (num) =>
            num.status && Math.trunc(num.percentage) < 100 && num.number[0] == 4
        );
        initialNums = twodNumbers.filter((num) => num.number[0] == 4);
        closedFilter(initialNums);
        break;
      case "5ထိပ်":
        tempNums = twodNumbers.filter(
          (num) =>
            num.status && Math.trunc(num.percentage) < 100 && num.number[0] == 5
        );
        initialNums = twodNumbers.filter((num) => num.number[0] == 5);
        closedFilter(initialNums);
        break;
      case "6ထိပ်":
        tempNums = twodNumbers.filter(
          (num) =>
            num.status && Math.trunc(num.percentage) < 100 && num.number[0] == 6
        );
        initialNums = twodNumbers.filter((num) => num.number[0] == 6);
        closedFilter(initialNums);
        break;
      case "7ထိပ်":
        tempNums = twodNumbers.filter(
          (num) =>
            num.status && Math.trunc(num.percentage) < 100 && num.number[0] == 7
        );
        initialNums = twodNumbers.filter((num) => num.number[0] == 7);
        closedFilter(initialNums);
        break;
      case "8ထိပ်":
        tempNums = twodNumbers.filter(
          (num) =>
            num.status && Math.trunc(num.percentage) < 100 && num.number[0] == 8
        );
        initialNums = twodNumbers.filter((num) => num.number[0] == 8);
        closedFilter(initialNums);
        break;
      case "9ထိပ်":
        tempNums = twodNumbers.filter(
          (num) =>
            num.status && Math.trunc(num.percentage) < 100 && num.number[0] == 9
        );
        initialNums = twodNumbers.filter((num) => num.number[0] == 9);
        closedFilter(initialNums);
        break;
      case "0နောက်":
        tempNums = twodNumbers.filter(
          (num) =>
            num.status && Math.trunc(num.percentage) < 100 && num.number[1] == 0
        );
        initialNums = twodNumbers.filter((num) => num.number[1] == 0);
        closedFilter(initialNums);
        break;
      case "1နောက်":
        tempNums = twodNumbers.filter(
          (num) =>
            num.status && Math.trunc(num.percentage) < 100 && num.number[1] == 1
        );
        initialNums = twodNumbers.filter((num) => num.number[1] == 1);
        closedFilter(initialNums);
        break;
      case "2နောက်":
        tempNums = twodNumbers.filter(
          (num) =>
            num.status && Math.trunc(num.percentage) < 100 && num.number[1] == 2
        );
        initialNums = twodNumbers.filter((num) => num.number[1] == 2);
        closedFilter(initialNums);
        break;
      case "3နောက်":
        tempNums = twodNumbers.filter(
          (num) =>
            num.status && Math.trunc(num.percentage) < 100 && num.number[1] == 3
        );
        initialNums = twodNumbers.filter((num) => num.number[1] == 3);
        closedFilter(initialNums);
        break;
      case "4နောက်":
        tempNums = twodNumbers.filter(
          (num) =>
            num.status && Math.trunc(num.percentage) < 100 && num.number[1] == 4
        );
        initialNums = twodNumbers.filter((num) => num.number[1] == 4);
        closedFilter(initialNums);
        break;
      case "5နောက်":
        tempNums = twodNumbers.filter(
          (num) =>
            num.status && Math.trunc(num.percentage) < 100 && num.number[1] == 5
        );
        initialNums = twodNumbers.filter((num) => num.number[1] == 5);
        closedFilter(initialNums);
        break;
      case "6နောက်":
        tempNums = twodNumbers.filter(
          (num) =>
            num.status && Math.trunc(num.percentage) < 100 && num.number[1] == 6
        );
        initialNums = twodNumbers.filter((num) => num.number[1] == 6);
        closedFilter(initialNums);
        break;
      case "7နောက်":
        tempNums = twodNumbers.filter(
          (num) =>
            num.status && Math.trunc(num.percentage) < 100 && num.number[1] == 7
        );
        initialNums = twodNumbers.filter((num) => num.number[1] == 7);
        closedFilter(initialNums);
        break;
      case "8နောက်":
        tempNums = twodNumbers.filter(
          (num) =>
            num.status && Math.trunc(num.percentage) < 100 && num.number[1] == 8
        );
        initialNums = twodNumbers.filter((num) => num.number[1] == 8);
        closedFilter(initialNums);
        break;
      case "9နောက်":
        tempNums = twodNumbers.filter(
          (num) =>
            num.status && Math.trunc(num.percentage) < 100 && num.number[1] == 9
        );
        initialNums = twodNumbers.filter((num) => num.number[1] == 9);
        closedFilter(initialNums);
        break;
      case "0ဘရိတ်":
        tempNums = twodNumbers.filter(
          (num) =>
            num.status &&
            Math.trunc(num.percentage) < 100 &&
            (+num.number[0] + +num.number[1] == 0 ||
              +num.number[0] + +num.number[1] == 10)
        );
        initialNums = twodNumbers.filter(
          (num) =>
            +num.number[0] + +num.number[1] == 0 ||
            +num.number[0] + +num.number[1] == 10
        );
        closedFilter(initialNums);
        break;
      case "1ဘရိတ်":
        tempNums = twodNumbers.filter(
          (num) =>
            num.status &&
            Math.trunc(num.percentage) < 100 &&
            (+num.number[0] + +num.number[1] == 1 ||
              +num.number[0] + +num.number[1] == 11)
        );
        initialNums = twodNumbers.filter(
          (num) =>
            +num.number[0] + +num.number[1] == 1 ||
            +num.number[0] + +num.number[1] == 11
        );
        closedFilter(initialNums);
        break;
      case "2ဘရိတ်":
        tempNums = twodNumbers.filter(
          (num) =>
            num.status &&
            Math.trunc(num.percentage) < 100 &&
            (+num.number[0] + +num.number[1] == 2 ||
              +num.number[0] + +num.number[1] == 12)
        );
        initialNums = twodNumbers.filter(
          (num) =>
            +num.number[0] + +num.number[1] == 2 ||
            +num.number[0] + +num.number[1] == 12
        );
        closedFilter(initialNums);
        break;
      case "3ဘရိတ်":
        tempNums = twodNumbers.filter(
          (num) =>
            num.status &&
            Math.trunc(num.percentage) < 100 &&
            (+num.number[0] + +num.number[1] == 3 ||
              +num.number[0] + +num.number[1] == 13)
        );
        initialNums = twodNumbers.filter(
          (num) =>
            +num.number[0] + +num.number[1] == 3 ||
            +num.number[0] + +num.number[1] == 13
        );
        closedFilter(initialNums);
        break;
      case "4ဘရိတ်":
        tempNums = twodNumbers.filter(
          (num) =>
            num.status &&
            Math.trunc(num.percentage) < 100 &&
            (+num.number[0] + +num.number[1] == 4 ||
              +num.number[0] + +num.number[1] == 14)
        );
        initialNums = twodNumbers.filter(
          (num) =>
            +num.number[0] + +num.number[1] == 4 ||
            +num.number[0] + +num.number[1] == 14
        );
        closedFilter(initialNums);
        break;
      case "5ဘရိတ်":
        tempNums = twodNumbers.filter(
          (num) =>
            num.status &&
            Math.trunc(num.percentage) < 100 &&
            (+num.number[0] + +num.number[1] == 5 ||
              +num.number[0] + +num.number[1] == 15)
        );
        initialNums = twodNumbers.filter(
          (num) =>
            +num.number[0] + +num.number[1] == 5 ||
            +num.number[0] + +num.number[1] == 15
        );
        closedFilter(initialNums);
        break;
      case "6ဘရိတ်":
        tempNums = twodNumbers.filter(
          (num) =>
            num.status &&
            Math.trunc(num.percentage) < 100 &&
            (+num.number[0] + +num.number[1] == 6 ||
              +num.number[0] + +num.number[1] == 16)
        );
        initialNums = twodNumbers.filter(
          (num) =>
            +num.number[0] + +num.number[1] == 6 ||
            +num.number[0] + +num.number[1] == 16
        );
        closedFilter(initialNums);
        break;
      case "7ဘရိတ်":
        tempNums = twodNumbers.filter(
          (num) =>
            num.status &&
            Math.trunc(num.percentage) < 100 &&
            (+num.number[0] + +num.number[1] == 7 ||
              +num.number[0] + +num.number[1] == 17)
        );
        initialNums = twodNumbers.filter(
          (num) =>
            +num.number[0] + +num.number[1] == 7 ||
            +num.number[0] + +num.number[1] == 17
        );
        closedFilter(initialNums);
        break;
      case "8ဘရိတ်":
        tempNums = twodNumbers.filter(
          (num) =>
            num.status &&
            Math.trunc(num.percentage) < 100 &&
            (+num.number[0] + +num.number[1] == 8 ||
              +num.number[0] + +num.number[1] == 18)
        );
        initialNums = twodNumbers.filter(
          (num) =>
            +num.number[0] + +num.number[1] == 8 ||
            +num.number[0] + +num.number[1] == 18
        );
        closedFilter(initialNums);
        break;
      case "9ဘရိတ်":
        tempNums = twodNumbers.filter(
          (num) =>
            num.status &&
            Math.trunc(num.percentage) < 100 &&
            (+num.number[0] + +num.number[1] == 9 ||
              +num.number[0] + +num.number[1] == 19)
        );
        initialNums = twodNumbers.filter(
          (num) =>
            +num.number[0] + +num.number[1] == 9 ||
            +num.number[0] + +num.number[1] == 19
        );
        closedFilter(initialNums);
        break;
      default:
        return false;
    }
  };

  const removeFastChooseHandler = () => {
    tempNums = [];
    initialNums = [];
    setFastStatus(false);
    setActiveFastChoose(null);
  };

  const setAmountHandler = (e) => {
    const amount = e.target.value;
    const positiveNumberPattern = /^[+]?\d*\.?\d*$/;
    positiveNumberPattern.test(amount) && setAmount(amount);
  };

  const submitHandler = () => {
    const submitNums = [];
    const totalAmount = tempNums.length * amount;
    if (tempNums.length > 0) {
      if (amount) {
        if (value?.user_info?.current_balance >= totalAmount) {
          if (amount >= 100) {
            tempNums.map((num, i) =>
              submitNums.push({
                id: num.number,
                number: num.number,
                amount,
                time: params.time,
              })
            );
            if (submitNums.length > 0) {
              dispatch(betNumbersHandler(submitNums));
              navigate("confirm");
            }
          } else {
            errorToaster("Minium bet amount is 100", toastId);
          }
        } else {
          errorToaster("Balance insufficient to bet!", toastId);
        }
      } else {
        errorToaster("Amount is required", toastId);
      }
    } else {
      errorToaster("Please choose bet numbers", toastId);
    }
  };

  return (
    <>
      <Header />
      <Back />
      <div className="min-h-[85vh]">
        <div className="container mx-auto">
          <div className="w-[95%] max-w-[500px] mx-auto mt-3 mb-10">
            <div className="py-5 sticky lg:top-[70px] top-[56px] z-10 bg-[var(--secondary-color)] px-2 rounded-lg">
              <div className="block sm:flex items-center justify-between">
                <div className="flex items-center mb-3 sm:mb-0">
                  {activeFastChoose ? (
                    <button
                      className="border border-[var(--accent-color)] bg-[var(--primary-color)] transition-[1s] rounded-lg py-2 px-3"
                      onClick={removeFastChooseHandler}
                    >
                      {langChange.remove}
                    </button>
                  ) : (
                    <button
                      className="border border-[var(--accent-color)] transition-[1s] rounded-lg py-2 px-3"
                      onClick={() => setFastChooseModal(true)}
                    >
                      {langChange.choose_fast}
                    </button>
                  )}
                  <input
                    className={classes.arr}
                    id="ar"
                    type="checkbox"
                    disabled={activeFastChoose}
                    checked={arrStatus}
                    onChange={arrHandler}
                  />
                  <label htmlFor="ar" className={classes.arr_label}>
                    R
                  </label>
                  <Link
                    to={`hkway-htoe`}
                    className="border border-[var(--accent-color)] transition-[1s] rounded-lg py-2 px-3 ms-2 cursor-pointer"
                  >
                    ခွေထိုးမည်
                  </Link>
                </div>
                <div className="flex items-center">
                  <input
                    className={`p-2 w-[70%] sm:w-auto ${classes["amount-insert"]}`}
                    type="text"
                    placeholder={langChange.min_100}
                    value={amount}
                    onChange={setAmountHandler}
                  />
                  <button
                    className="border w-[30%] sm:w-auto border-[var(--accent-color)] bg-[var(--accent-color)] text-[var(--main-color)] transition-[1s] rounded-e-lg py-2 px-3"
                    onClick={submitHandler}
                  >
                    {langChange.bet}
                  </button>
                </div>
              </div>
            </div>
            <ul className="flex items-center justify-center flex-wrap">
              {twodNumbers.length > 0 &&
                twodNumbers.map((num, i) => (
                  <li key={num.number} className="p-[6px]">
                    <Number
                      number={num.number}
                      id={num.number}
                      event={numberHandler}
                      disable={
                        arrStatus ||
                        fastStatus ||
                        !num.status ||
                        Math.trunc(num.percentage) >= 100
                      }
                      activeArr={
                        tempNums.length > 0
                          ? tempNums.some((el) => el.number == num.number)
                          : false
                      }
                      twodNumbers={twodNumbers}
                      percentage={num.percentage}
                      color={num.color}
                      status={num.status}
                    />
                  </li>
                ))}
            </ul>
          </div>
        </div>
      </div>
      <Footer />
      <DetailModel isShow={fastChooseModal} setIsShow={setFastChooseModal}>
        <div className="max-h-[500px] overflow-auto">
          <div className="text-[13px] mb-2">
            <h3 className="text-slate-100 text-[17px] mb-2 mt-2">ရိုးရိုး</h3>
            <ul className="flex items-center justify-start flex-wrap">
              <li className="p-[5px]">
                <button
                  type="button"
                  className="border border-[var(--light-color)] bg-[var(--main-color)] text-[var(--text-color)] font-semibold transition-[1s] rounded-sm py-2 px-5 select-none"
                  onClick={() => fastHandler("စုံစုံ")}
                >
                  စုံစုံ
                </button>
              </li>
              <li className="p-[5px]">
                <button
                  type="button"
                  className="border border-[var(--light-color)] bg-[var(--main-color)] text-[var(--text-color)] font-semibold transition-[1s] rounded-sm py-2 px-5 select-none"
                  onClick={() => fastHandler("မမ")}
                >
                  မမ
                </button>
              </li>
              <li className="p-[5px]">
                <button
                  type="button"
                  className="border border-[var(--light-color)] bg-[var(--main-color)] text-[var(--text-color)] font-semibold transition-[1s] rounded-sm py-2 px-5 select-none"
                  onClick={() => fastHandler("စုံမ")}
                >
                  စုံမ
                </button>
              </li>
              <li className="p-[5px]">
                <button
                  type="button"
                  className="border border-[var(--light-color)] bg-[var(--main-color)] text-[var(--text-color)] font-semibold transition-[1s] rounded-sm py-2 px-5 select-none"
                  onClick={() => fastHandler("မစုံ")}
                >
                  မစုံ
                </button>
              </li>
            </ul>
          </div>
          <div className="text-[13px] mb-2">
            <h3 className="text-slate-100 text-[17px] mb-2 mt-2">
              နက္ခတ်၊ ပါဝါ
            </h3>
            <ul className="flex items-center justify-start flex-wrap">
              <li className="p-[5px]">
                <button
                  type="button"
                  className="border border-[var(--light-color)] bg-[var(--main-color)] text-[var(--text-color)] font-semibold transition-[1s] rounded-sm py-2 px-5 select-none"
                  onClick={() => fastHandler("နက္ခတ်")}
                >
                  နက္ခတ်
                </button>
              </li>
              <li className="p-[5px]">
                <button
                  type="button"
                  className="border border-[var(--light-color)] bg-[var(--main-color)] text-[var(--text-color)] font-semibold transition-[1s] rounded-sm py-2 px-5 select-none"
                  onClick={() => fastHandler("ပါဝါ")}
                >
                  ပါဝါ
                </button>
              </li>
              <li className="p-[5px]">
                <button
                  type="button"
                  className="border border-[var(--light-color)] bg-[var(--main-color)] text-[var(--text-color)] font-semibold transition-[1s] rounded-sm py-2 px-5 select-none"
                  onClick={() => fastHandler("အပူး")}
                >
                  အပူး
                </button>
              </li>
              <li className="p-[5px]">
                <button
                  type="button"
                  className="border border-[var(--light-color)] bg-[var(--main-color)] text-[var(--text-color)] font-semibold transition-[1s] rounded-sm py-2 px-5 select-none"
                  onClick={() => fastHandler("ညီအကို")}
                >
                  ညီအကို
                </button>
              </li>
            </ul>
          </div>
          <div className="text-[13px] mb-2">
            <h3 className="text-slate-100 text-[17px] mb-2 mt-2">ပါတ်</h3>
            <ul className="flex items-center justify-start flex-wrap">
              <li className="p-[5px]">
                <button
                  type="button"
                  className="border border-[var(--light-color)] bg-[var(--main-color)] text-[var(--text-color)] font-semibold transition-[1s] rounded-sm py-2 px-5 select-none"
                  onClick={() => fastHandler("0ပါတ်")}
                >
                  0
                </button>
              </li>
              <li className="p-[5px]">
                <button
                  type="button"
                  className="border border-[var(--light-color)] bg-[var(--main-color)] text-[var(--text-color)] font-semibold transition-[1s] rounded-sm py-2 px-5 select-none"
                  onClick={() => fastHandler("1ပါတ်")}
                >
                  1
                </button>
              </li>
              <li className="p-[5px]">
                <button
                  type="button"
                  className="border border-[var(--light-color)] bg-[var(--main-color)] text-[var(--text-color)] font-semibold transition-[1s] rounded-sm py-2 px-5 select-none"
                  onClick={() => fastHandler("2ပါတ်")}
                >
                  2
                </button>
              </li>
              <li className="p-[5px]">
                <button
                  type="button"
                  className="border border-[var(--light-color)] bg-[var(--main-color)] text-[var(--text-color)] font-semibold transition-[1s] rounded-sm py-2 px-5 select-none"
                  onClick={() => fastHandler("3ပါတ်")}
                >
                  3
                </button>
              </li>
              <li className="p-[5px]">
                <button
                  type="button"
                  className="border border-[var(--light-color)] bg-[var(--main-color)] text-[var(--text-color)] font-semibold transition-[1s] rounded-sm py-2 px-5 select-none"
                  onClick={() => fastHandler("4ပါတ်")}
                >
                  4
                </button>
              </li>
              <li className="p-[5px]">
                <button
                  type="button"
                  className="border border-[var(--light-color)] bg-[var(--main-color)] text-[var(--text-color)] font-semibold transition-[1s] rounded-sm py-2 px-5 select-none"
                  onClick={() => fastHandler("5ပါတ်")}
                >
                  5
                </button>
              </li>
              <li className="p-[5px]">
                <button
                  type="button"
                  className="border border-[var(--light-color)] bg-[var(--main-color)] text-[var(--text-color)] font-semibold transition-[1s] rounded-sm py-2 px-5 select-none"
                  onClick={() => fastHandler("6ပါတ်")}
                >
                  6
                </button>
              </li>
              <li className="p-[5px]">
                <button
                  type="button"
                  className="border border-[var(--light-color)] bg-[var(--main-color)] text-[var(--text-color)] font-semibold transition-[1s] rounded-sm py-2 px-5 select-none"
                  onClick={() => fastHandler("7ပါတ်")}
                >
                  7
                </button>
              </li>
              <li className="p-[5px]">
                <button
                  type="button"
                  className="border border-[var(--light-color)] bg-[var(--main-color)] text-[var(--text-color)] font-semibold transition-[1s] rounded-sm py-2 px-5 select-none"
                  onClick={() => fastHandler("8ပါတ်")}
                >
                  8
                </button>
              </li>
              <li className="p-[5px]">
                <button
                  type="button"
                  className="border border-[var(--light-color)] bg-[var(--main-color)] text-[var(--text-color)] font-semibold transition-[1s] rounded-sm py-2 px-5 select-none"
                  onClick={() => fastHandler("9ပါတ်")}
                >
                  9
                </button>
              </li>
            </ul>
          </div>
          <div className="text-[13px] mb-2">
            <h3 className="text-slate-100 text-[17px] mb-2 mt-2">ထိပ်</h3>
            <ul className="flex items-center justify-start flex-wrap">
              <li className="p-[5px]">
                <button
                  type="button"
                  className="border border-[var(--light-color)] bg-[var(--main-color)] text-[var(--text-color)] font-semibold transition-[1s] rounded-sm py-2 px-5 select-none"
                  onClick={() => fastHandler("0ထိပ်")}
                >
                  0
                </button>
              </li>
              <li className="p-[5px]">
                <button
                  type="button"
                  className="border border-[var(--light-color)] bg-[var(--main-color)] text-[var(--text-color)] font-semibold transition-[1s] rounded-sm py-2 px-5 select-none"
                  onClick={() => fastHandler("1ထိပ်")}
                >
                  1
                </button>
              </li>
              <li className="p-[5px]">
                <button
                  type="button"
                  className="border border-[var(--light-color)] bg-[var(--main-color)] text-[var(--text-color)] font-semibold transition-[1s] rounded-sm py-2 px-5 select-none"
                  onClick={() => fastHandler("2ထိပ်")}
                >
                  2
                </button>
              </li>
              <li className="p-[5px]">
                <button
                  type="button"
                  className="border border-[var(--light-color)] bg-[var(--main-color)] text-[var(--text-color)] font-semibold transition-[1s] rounded-sm py-2 px-5 select-none"
                  onClick={() => fastHandler("3ထိပ်")}
                >
                  3
                </button>
              </li>
              <li className="p-[5px]">
                <button
                  type="button"
                  className="border border-[var(--light-color)] bg-[var(--main-color)] text-[var(--text-color)] font-semibold transition-[1s] rounded-sm py-2 px-5 select-none"
                  onClick={() => fastHandler("4ထိပ်")}
                >
                  4
                </button>
              </li>
              <li className="p-[5px]">
                <button
                  type="button"
                  className="border border-[var(--light-color)] bg-[var(--main-color)] text-[var(--text-color)] font-semibold transition-[1s] rounded-sm py-2 px-5 select-none"
                  onClick={() => fastHandler("5ထိပ်")}
                >
                  5
                </button>
              </li>
              <li className="p-[5px]">
                <button
                  type="button"
                  className="border border-[var(--light-color)] bg-[var(--main-color)] text-[var(--text-color)] font-semibold transition-[1s] rounded-sm py-2 px-5 select-none"
                  onClick={() => fastHandler("6ထိပ်")}
                >
                  6
                </button>
              </li>
              <li className="p-[5px]">
                <button
                  type="button"
                  className="border border-[var(--light-color)] bg-[var(--main-color)] text-[var(--text-color)] font-semibold transition-[1s] rounded-sm py-2 px-5 select-none"
                  onClick={() => fastHandler("7ထိပ်")}
                >
                  7
                </button>
              </li>
              <li className="p-[5px]">
                <button
                  type="button"
                  className="border border-[var(--light-color)] bg-[var(--main-color)] text-[var(--text-color)] font-semibold transition-[1s] rounded-sm py-2 px-5 select-none"
                  onClick={() => fastHandler("8ထိပ်")}
                >
                  8
                </button>
              </li>
              <li className="p-[5px]">
                <button
                  type="button"
                  className="border border-[var(--light-color)] bg-[var(--main-color)] text-[var(--text-color)] font-semibold transition-[1s] rounded-sm py-2 px-5 select-none"
                  onClick={() => fastHandler("9ထိပ်")}
                >
                  9
                </button>
              </li>
            </ul>
          </div>
          <div className="text-[13px] mb-2">
            <h3 className="text-slate-100 text-[17px] mb-2 mt-2">နောက်</h3>
            <ul className="flex items-center justify-start flex-wrap">
              <li className="p-[5px]">
                <button
                  type="button"
                  className="border border-[var(--light-color)] bg-[var(--main-color)] text-[var(--text-color)] font-semibold transition-[1s] rounded-sm py-2 px-5 select-none"
                  onClick={() => fastHandler("0နောက်")}
                >
                  0
                </button>
              </li>
              <li className="p-[5px]">
                <button
                  type="button"
                  className="border border-[var(--light-color)] bg-[var(--main-color)] text-[var(--text-color)] font-semibold transition-[1s] rounded-sm py-2 px-5 select-none"
                  onClick={() => fastHandler("1နောက်")}
                >
                  1
                </button>
              </li>
              <li className="p-[5px]">
                <button
                  type="button"
                  className="border border-[var(--light-color)] bg-[var(--main-color)] text-[var(--text-color)] font-semibold transition-[1s] rounded-sm py-2 px-5 select-none"
                  onClick={() => fastHandler("2နောက်")}
                >
                  2
                </button>
              </li>
              <li className="p-[5px]">
                <button
                  type="button"
                  className="border border-[var(--light-color)] bg-[var(--main-color)] text-[var(--text-color)] font-semibold transition-[1s] rounded-sm py-2 px-5 select-none"
                  onClick={() => fastHandler("3နောက်")}
                >
                  3
                </button>
              </li>
              <li className="p-[5px]">
                <button
                  type="button"
                  className="border border-[var(--light-color)] bg-[var(--main-color)] text-[var(--text-color)] font-semibold transition-[1s] rounded-sm py-2 px-5 select-none"
                  onClick={() => fastHandler("4နောက်")}
                >
                  4
                </button>
              </li>
              <li className="p-[5px]">
                <button
                  type="button"
                  className="border border-[var(--light-color)] bg-[var(--main-color)] text-[var(--text-color)] font-semibold transition-[1s] rounded-sm py-2 px-5 select-none"
                  onClick={() => fastHandler("5နောက်")}
                >
                  5
                </button>
              </li>
              <li className="p-[5px]">
                <button
                  type="button"
                  className="border border-[var(--light-color)] bg-[var(--main-color)] text-[var(--text-color)] font-semibold transition-[1s] rounded-sm py-2 px-5 select-none"
                  onClick={() => fastHandler("6နောက်")}
                >
                  6
                </button>
              </li>
              <li className="p-[5px]">
                <button
                  type="button"
                  className="border border-[var(--light-color)] bg-[var(--main-color)] text-[var(--text-color)] font-semibold transition-[1s] rounded-sm py-2 px-5 select-none"
                  onClick={() => fastHandler("7နောက်")}
                >
                  7
                </button>
              </li>
              <li className="p-[5px]">
                <button
                  type="button"
                  className="border border-[var(--light-color)] bg-[var(--main-color)] text-[var(--text-color)] font-semibold transition-[1s] rounded-sm py-2 px-5 select-none"
                  onClick={() => fastHandler("8နောက်")}
                >
                  8
                </button>
              </li>
              <li className="p-[5px]">
                <button
                  type="button"
                  className="border border-[var(--light-color)] bg-[var(--main-color)] text-[var(--text-color)] font-semibold transition-[1s] rounded-sm py-2 px-5 select-none"
                  onClick={() => fastHandler("9နောက်")}
                >
                  9
                </button>
              </li>
            </ul>
          </div>
          <div className="text-[13px] mb-2">
            <h3 className="text-slate-100 text-[17px] mb-2 mt-2">ဘရိတ်</h3>
            <ul className="flex items-center justify-start flex-wrap">
              <li className="p-[5px]">
                <button
                  type="button"
                  className="border border-[var(--light-color)] bg-[var(--main-color)] text-[var(--text-color)] font-semibold transition-[1s] rounded-sm py-2 px-5 select-none"
                  onClick={() => fastHandler("0ဘရိတ်")}
                >
                  0
                </button>
              </li>
              <li className="p-[5px]">
                <button
                  type="button"
                  className="border border-[var(--light-color)] bg-[var(--main-color)] text-[var(--text-color)] font-semibold transition-[1s] rounded-sm py-2 px-5 select-none"
                  onClick={() => fastHandler("1ဘရိတ်")}
                >
                  1
                </button>
              </li>
              <li className="p-[5px]">
                <button
                  type="button"
                  className="border border-[var(--light-color)] bg-[var(--main-color)] text-[var(--text-color)] font-semibold transition-[1s] rounded-sm py-2 px-5 select-none"
                  onClick={() => fastHandler("2ဘရိတ်")}
                >
                  2
                </button>
              </li>
              <li className="p-[5px]">
                <button
                  type="button"
                  className="border border-[var(--light-color)] bg-[var(--main-color)] text-[var(--text-color)] font-semibold transition-[1s] rounded-sm py-2 px-5 select-none"
                  onClick={() => fastHandler("3ဘရိတ်")}
                >
                  3
                </button>
              </li>
              <li className="p-[5px]">
                <button
                  type="button"
                  className="border border-[var(--light-color)] bg-[var(--main-color)] text-[var(--text-color)] font-semibold transition-[1s] rounded-sm py-2 px-5 select-none"
                  onClick={() => fastHandler("4ဘရိတ်")}
                >
                  4
                </button>
              </li>
              <li className="p-[5px]">
                <button
                  type="button"
                  className="border border-[var(--light-color)] bg-[var(--main-color)] text-[var(--text-color)] font-semibold transition-[1s] rounded-sm py-2 px-5 select-none"
                  onClick={() => fastHandler("5ဘရိတ်")}
                >
                  5
                </button>
              </li>
              <li className="p-[5px]">
                <button
                  type="button"
                  className="border border-[var(--light-color)] bg-[var(--main-color)] text-[var(--text-color)] font-semibold transition-[1s] rounded-sm py-2 px-5 select-none"
                  onClick={() => fastHandler("6ဘရိတ်")}
                >
                  6
                </button>
              </li>
              <li className="p-[5px]">
                <button
                  type="button"
                  className="border border-[var(--light-color)] bg-[var(--main-color)] text-[var(--text-color)] font-semibold transition-[1s] rounded-sm py-2 px-5 select-none"
                  onClick={() => fastHandler("7ဘရိတ်")}
                >
                  7
                </button>
              </li>
              <li className="p-[5px]">
                <button
                  type="button"
                  className="border border-[var(--light-color)] bg-[var(--main-color)] text-[var(--text-color)] font-semibold transition-[1s] rounded-sm py-2 px-5 select-none"
                  onClick={() => fastHandler("8ဘရိတ်")}
                >
                  8
                </button>
              </li>
              <li className="p-[5px]">
                <button
                  type="button"
                  className="border border-[var(--light-color)] bg-[var(--main-color)] text-[var(--text-color)] font-semibold transition-[1s] rounded-sm py-2 px-5 select-none"
                  onClick={() => fastHandler("9ဘရိတ်")}
                >
                  9
                </button>
              </li>
            </ul>
          </div>
        </div>
      </DetailModel>
      <ToastContainer limit={1} />
      {createPortal(loading && <Loader />, document.getElementById("portal"))}
    </>
  );
};

export default RoyalNumberList;

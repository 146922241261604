import React from "react";
const Card = ({ children }) => {
  return (
    <div
    className={`w-[95%] max-w-[500px] mx-auto pt-4 md:p-5 md:bg-[var(--light-color)] md:border md:border-[var(--secondary-color)] rounded-md`}
    >
      {children}
    </div>
  );
};

export default Card;

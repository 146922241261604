import React, { useEffect, useState } from "react";
import classes from "../../assets/styles/TwodResultHistory.module.css";
import { DUBAI_TWO_D_RESULT_API } from "../../services/constant";
import { getMethod } from "../../services";
import { useNavigate } from "react-router-dom";
import Loader from "../../utils/Loader";
import { getDubaiLotteryTime } from "./DubaiHelper";

const DubaiTwodResultHistory = () => {
  const [loading, setLoading] = useState(false);
  const [history, setHistory] = useState([]);
  const navigate = useNavigate();

  const fetchHistory = async () => {
    setLoading(true);
    const token = JSON.parse(localStorage.getItem("l_555_a"));
    if (token) {
      const res = await getMethod(
        `${DUBAI_TWO_D_RESULT_API}?type=Dubai`,
        token.token
      );
      if (res) {
        setLoading(false);
        if (res.data) {
          setHistory(res.data);
        }
      }
    } else {
      navigate("/login");
    }
  };

  useEffect(() => {
    fetchHistory();
  }, []);
  return (
    <div className="mt-6">
      {history.length > 0 && (
        <div className="mb-5">
          <h2 className="text-center text-[100px] font-bold">
            {history.sort((a, b) => b.id - a.id)[0].two_digit_number ?? "!!"}
          </h2>
          <p className="text-center translate-y-[-10px]">
            {history.sort((a, b) => b.id - a.id)[0].lottery_date ?? "--:--:--"} {' '}
            {getDubaiLotteryTime(
              history.sort((a, b) => b.id - a.id)[0].lottery_time
            )}
          </p>
        </div>
      )}
      {history.length > 0 ? (
        history.map((el) => (
          <div
            key={el.id}
            className="bg-[var(--light-color)] border border-[var(--light-color)] p-6 rounded-lg text-base mb-3"
          >
            <div className={`text-center ${classes.result}`}>
              <div style={{ width: "33.33%" }} className="translate-y-[-4px]">{el.lottery_date}</div>
              <div
                style={{ width: "33.33%" }}
                className="text-[50px] text-[var(--gold-color)]"
              >
                {el.two_digit_number}
              </div>
              <div style={{ width: "33.33%" }} className="translate-y-[-4px]">
                <span
                  className={`p-1 rounded shadow-lg px-3 text-xs
                    ${el.lottery_time === "One" && "bg-green-600"}
                    ${el.lottery_time === "Two" && "bg-red-600"}
                    ${el.lottery_time === "Three" && "bg-yellow-600"}
                    ${el.lottery_time === "Four" && "bg-blue-400"}
                    ${el.lottery_time === "Five" && "bg-purple-600"}
                    ${el.lottery_time === "Six" && "bg-orange-600"}
                  `}
                >
                  {getDubaiLotteryTime(el.lottery_time)}
                </span>
              </div>
            </div>
          </div>
        ))
      ) : (
        <div className="text-xl h-24 flex items-end justify-center">
          No Result Found!
        </div>
      )}
      {loading && <Loader />}
    </div>
  );
};

export default DubaiTwodResultHistory;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NoData_nofount__vqtm6 {
    padding: 20px;
    text-align: center;
    font-size: 20px;
    color: var(--font-color);
    width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/assets/styles/NoData.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,kBAAkB;IAClB,eAAe;IACf,wBAAwB;IACxB,WAAW;AACf","sourcesContent":[".nofount {\n    padding: 20px;\n    text-align: center;\n    font-size: 20px;\n    color: var(--font-color);\n    width: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"nofount": `NoData_nofount__vqtm6`
};
export default ___CSS_LOADER_EXPORT___;

import React, { useEffect, useState } from "react";
import Header from "../layout/Header";
import Banner from "./Banner";
import ProviderList from "./ProviderList";
import Contact from "./Contact";
import Footer from "../layout/Footer";
import GameProvider from "./GameProvider";
import { PROVIDER_API } from "../services/constant";
import { getMethod } from "../services";
import { createPortal } from "react-dom";
import Loader from "../utils/Loader";
import NavTab from "../utils/NavTab";
import styled from "styled-components";

const Main = () => {
  const [loading, setLoading] = useState(false);
  const [providers, setProviders] = useState([]);

  const fetchProvider = async () => {
    setLoading(true);
    const res = await getMethod(`${PROVIDER_API}?status=active`);
    if (res) {
      setLoading(false);
      if (res?.status === "success") {
        res?.data.sort((a, b) => a?.id - b?.id);
        setProviders(res.data);
      }
    }
  };
  useEffect(() => {
    fetchProvider();
  }, []);

  const tabs = ["ထီများ", "ဂိမ်းများ"];
  const [activeTab, setActiveTab] = useState("ထီများ");

  const AnimateDiv = styled.div`
    transition: left 0.5s ease-in-out;
    position: relative;
  `;

  return (
    <div>
      <Header />
      <div className="min-h-screen">
        <Banner />
        <div className="w-[95%] max-w-[480px] mx-auto my-3">
          <NavTab
            tabs={tabs}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            tabWidth={50}
          />
        </div>
        <AnimateDiv
          style={{
            left: activeTab === "ထီများ" ? "0" : "100%",
          }}
        >
          {activeTab === "ထီများ" && <ProviderList />}
        </AnimateDiv>
        <AnimateDiv
          style={{
            left: activeTab === "ဂိမ်းများ" ? "0" : "-100%",
          }}
        >
        {activeTab === "ဂိမ်းများ" && <GameProvider providers={providers} />}
        </AnimateDiv>
        <Contact />
      </div>
      <Footer />
      {createPortal(loading && <Loader />, document.getElementById("portal"))}
    </div>
  );
};

export default Main;

import React, { useEffect, useRef, useState } from "react";
import Header from "../../layout/Header";
import Footer from "../../layout/Footer";
import Number from "../../utils/Number";
import classes from "../../assets/styles/ThreedNumberList.module.css";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { errorToaster } from "../../utils/Helper";
import { useDispatch, useSelector } from "react-redux";
import { betNumbersHandler } from "../../store/slices/betNumbersSlice";
import { getMethod } from "../../services";
import { THREE_D_LOTTERY_CLOSE_API, THREE_D_STATUS_API } from "../../services/constant";
import Back from "../../utils/Back";
import { createPortal } from "react-dom";
import Loader from "../../utils/Loader";
import moment from "moment-timezone";

let tempNums = [];
let arrTempNums = [];
let closedTemp = [];
const GoldThreedNumberList = () => {
  const [threedNumbers, setThreedNumbers] = useState([]);
  const [arrStatus, setArrStatus] = useState(false);
  const [amount, setAmount] = useState("");
  const navigate = useNavigate();
  const toastId = useRef(null);
  const dispatch = useDispatch();
  const [closedNums, setClosedNums] = useState([]);
  const { value } = useSelector((state) => state.userInfoSlice);

  const [initial, setInitial] = useState(0);
  const [optionNums, setOptionNums] = useState([]);
  const [chooseNums, setChooseNums] = useState([]);
  const [showNums, setShowNums] = useState([]);
  const [loading, setLoading] = useState(true);
  const [lotteryRange, setLotteryRange] = useState({});

  const token = JSON.parse(localStorage.getItem("l_555_a"));

  useEffect(() => {
    ( async() => {
      await getMethod(THREE_D_LOTTERY_CLOSE_API).then(res => {
        if (res?.con) {
          setLotteryRange(res?.data)
        }
      })
    })()
  }, [])

  const fetchThreeDStatus = async () => {
    if (token) {
      setLoading(true);
      const res = await getMethod(THREE_D_STATUS_API, token.token);
      res && setLoading(false);
      // if (res?.status !== 200) {
      //   errorToaster("သတ်မှတ်ထီထိုးချိန် ကျော်လွန်သွားပါပြီ။", toastId);
      //   setTimeout(() => {
      //     navigate(-1);
      //   }, 1500);
      // }
    }
  };

  useEffect(() => {
    fetchThreeDStatus();
  }, []);

  useEffect(() => {
    if (closedNums.length > 0) {
      errorToaster(
        `( ${[...closedNums]} ) ${
          closedNums.length > 1 ? "are" : "is"
        } not avaliable!`,
        toastId
      );
      setTimeout(() => {
        setClosedNums([]);
        closedTemp = [];
      }, 1000);
    }
  }, [closedNums]);

  const closedFilter = (initialArray) => {
    closedTemp = [];
    initialArray.map((num1) => {
      let filterClosed = tempNums.find((num2) => num2.number == num1.number);
      !filterClosed && closedTemp.push(num1.number);
    });
    setClosedNums(closedTemp);
  };

  // for permutation
  const permut = (number) => {
    const digits = number.toString().split("");
    const permutations = [];
    const generatePermut = (current, remaining) => {
      if (remaining.length === 0) {
        !permutations.includes(current.join("")) &&
          permutations.push(current.join(""));
        return;
      }
      for (let i = 0; i < remaining.length; i++) {
        const newCurrent = current.concat(remaining[i]);
        const newRemaining = remaining
          .slice(0, i)
          .concat(remaining.slice(i + 1));
        generatePermut(newCurrent, newRemaining);
      }
    };
    generatePermut([], digits);
    return permutations;
  };

  const fetchThreeDLimit = async () => {
    // if (currentDate) {
    let temp = [];
    let tempOptionNums = [];
    for (let i = 0; i < 10; i++) {
      tempOptionNums.push(i * 100);
    }
    setOptionNums(tempOptionNums);
    for (let i = 0; i < 1000; i++) {
      if (i < 10) {
        const num = {
          number: `00${i}`,
          color: "yellow",
          percentage: 0.0,
          status: true,
        };
        temp.push(num);
      } else if (i < 100) {
        const num = {
          number: `0${i}`,
          color: "yellow",
          percentage: 0.0,
          status: true,
        };
        temp.push(num);
      } else {
        const num = {
          number: `${i}`,
          color: "yellow",
          percentage: 0.0,
          status: true,
        };
        temp.push(num);
      }
    }
    setThreedNumbers(temp);
  };

  useEffect(() => {
    fetchThreeDLimit();
  }, [initial]);

  const numberHandler = (event, number, checkStatus, setCheckStatus) => {
    if (!arrStatus) {
      setCheckStatus(!checkStatus);
      let isChecked = event.target.checked;
      if (isChecked) {
        !tempNums.includes({ number: number }) &&
          tempNums.push({ number: number });
        setChooseNums(tempNums);
      } else {
        tempNums = tempNums.filter((el) => `${el.number}` !== `${number}`);
        setChooseNums(tempNums);
      }
    }
  };

  useEffect(() => {
    if (threedNumbers.length > 0) {
      switch (+initial) {
        case 0:
          arrTempNums = [];
          for (let i = 0; i < 100; i++) {
            arrTempNums.push(threedNumbers[i]);
          }
          setShowNums(arrTempNums);
          break;
        case 100:
          arrTempNums = [];
          for (let i = +initial; i < +initial + 100; i++) {
            arrTempNums.push(threedNumbers[i]);
          }
          setShowNums(arrTempNums);
          break;
        default:
          arrTempNums = [];
          for (let i = +initial; i < +initial + 100; i++) {
            arrTempNums.push(threedNumbers[i]);
          }
          setShowNums(arrTempNums);
          break;
      }
    }
  }, [threedNumbers, initial]);

  const arrStatusHandler = () => {
    chooseNums.length > 0
      ? setArrStatus(!arrStatus)
      : errorToaster("Please choose number first!", toastId);
  };

  useEffect(() => {
    if (tempNums.length > 0) {
      if (arrStatus) {
        const tempR = tempNums.map((num) => permut(num.number));
        const afterR = [];
        const initialArr = [];
        for (let i = 0; i < tempR.length; i++) {
          tempR[i].map((num1) => {
            const finder = threedNumbers.find((num2) => num2.number == num1);
            if (finder) {
              if (finder.status && finder.percentage !== 100) {
                afterR.push({ number: finder.number });
                tempNums.push({ number: finder.number });
              }
            }
            initialArr.push({ number: num1 });
          });
        }
        closedFilter(initialArr);
        setChooseNums(afterR);
      } else {
        chooseNums.map((num) => {
          const index = tempNums.lastIndexOf(num);
          tempNums.splice(index, 1);
        });
        setChooseNums(tempNums);
      }
    }
  }, [arrStatus]);

  const setAmountHandler = (e) => {
    const amount = e.target.value;
    const positiveNumberPattern = /^[+]?\d*\.?\d*$/;
    positiveNumberPattern.test(amount) && setAmount(amount);
  };

  const submitHandler = () => {
    const submitNums = [];
    const totalAmount = chooseNums.length * amount;
    if (chooseNums.length > 0) {
      if (amount) {
        if (value?.user_info?.current_balance >= totalAmount) {
          let temp_date;
          const date = moment(lotteryRange?.closing_time).format('DD')
          if (date === 16 || date === 17) {
            temp_date = "One"
          } else {
            temp_date = 'Two'
          }
          console.log('date', date)
          if (amount >= 100) {
            chooseNums.map((num, i) =>
              submitNums.push({
                id: i + 1,
                number: num.number,
                amount,
                time: temp_date,
              })
            );
            if (submitNums.length > 0) {
              dispatch(betNumbersHandler(submitNums));
              navigate("confirm");
              setChooseNums([]);
              tempNums = [];
            }
          } else {
            errorToaster("Minium bet amount is 100", toastId);
          }
        } else {
          errorToaster("Balance insufficient to bet!", toastId);
        }
      } else {
        errorToaster("Amount is required", toastId);
      }
    } else {
      errorToaster("Please choose bet numbers", toastId);
    }
  };

  return (
    <>
      <Header />
      <Back />
      <div className="min-h-[85vh]">
        <div className="container mx-auto">
          <div className="w-[95%] max-w-[500px] mx-auto mb-10">
            <div className="sticky lg:top-[70px] top-[56px] z-10 bg-[var(--secondary-color)] p-2 rounded-lg">
              <div className="block sm:flex items-center justify-between">
                <div className="flex items-center mb-3 sm:mb-0">
                  <div className="">
                    <select
                      className="px-2 h-[40px] bg-[var(--secondary-color)] text-[var(--text-color)] text-sm border border-[var(--accent-color)] rounded-lg"
                      onChange={(e) => setInitial(e.target.value)}
                    >
                      {optionNums.length > 0 &&
                        optionNums.map((num, i) => (
                          <option key={i} value={num}>
                            {num === 0 ? "000" : num} မှစ၍
                          </option>
                        ))}
                    </select>
                  </div>
                  <input
                    className={`${classes.arr}`}
                    id="ar"
                    type="checkbox"
                    checked={arrStatus}
                    onChange={arrStatusHandler}
                  />
                  <label htmlFor="ar" className={`${classes.arr_label} font-semibold`}>
                    R
                  </label>
                </div>
                <div className="flex items-center">
                  <input
                    className={`p-2 w-[70%] sm:w-auto ${classes["amount-insert"]}`}
                    type="text"
                    placeholder="Minium 100"
                    value={amount}
                    onChange={setAmountHandler}
                  />
                  <button
                    className="border w-[30%] sm:w-auto border-[var(--accent-color)] bg-[var(--accent-color)] text-[var(--primary-color)] transition-[1s] rounded-e-lg py-2 px-3"
                    onClick={submitHandler}
                  >
                    ထိုးမည်
                  </button>
                </div>
              </div>
            </div>
            <ul className="flex items-center justify-center flex-wrap">
              {showNums.length > 0 &&
                showNums.map((num, i) => (
                  <li key={i} className="p-[6px]">
                    <Number
                      number={num.number}
                      id={i}
                      event={numberHandler}
                      disable={
                        arrStatus ||
                        !num.status ||
                        Math.trunc(num.percentage) >= 100
                      }
                      activeArr={
                        chooseNums.length > 0
                          ? chooseNums.some((el) => el.number == num.number)
                          : false
                      }
                      showNums={showNums}
                      percentage={num.percentage > 100 ? 100 : num.percentage}
                      color={num.color}
                      status={num.status}
                    />
                  </li>
                ))}
            </ul>
          </div>
        </div>
      </div>
      <Footer />
      <ToastContainer limit={1} />
      {
        createPortal(loading && <Loader />, document.getElementById('portal'))
      }
    </>
  );
};

export default GoldThreedNumberList;

import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Autoplay, Pagination } from "swiper";
import  '../assets/styles/Banner.css';
import {BANNER_API} from '../services/constant';
import {getMethod} from '../services';
import Caption from './Caption';

const Banner = () => {
    const [banners,setBanners] = useState([]);
    const fetchBanner = async () => {
        const res = await getMethod(`${BANNER_API}`);
        if(res.con){
            setBanners(res.data.photos);
        }
    };
    useEffect(()=>{
        fetchBanner();
    },[]);
  return (
    <>
       <div className='container mx-auto mt-3'>
            <div className='w-[95%] mx-auto relative'>
                <Swiper
                    spaceBetween={10}
                    pagination={{
                    clickable: true,
                    }}
                    modules={[Pagination,Autoplay]}
                    className="mySwiper rounded-xl"
                    autoplay={{
                    delay: 2000,
                    disableOnInteraction: false,
                    }}
                    loop={true}
                    speed={1500}
                >
                {
                    banners.length > 0 &&
                    banners.map((banner,i) => 
                    <SwiperSlide key={i}>
                        <img className={'banner-img rounded-xl'} src={banner} />
                    </SwiperSlide>
                    )
                }
                </Swiper>
            </div>
            <div className=''>
                <Caption />
            </div>
       </div>
    
    {/* <div className='container mx-auto px-4 sticky top-[67px] lg:top-[78px]'>
        <div id='hero'>
          <div className='px-4'>
          </div>
          <svg className="hero-waves" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" viewBox="0 24 150 28 " preserveAspectRatio="none">
              <defs>
                  <path id="wave-path" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
              </defs>
              <g className="wave1">
                  <use href="#wave-path" x="50" y="3" fill="rgba(255,255,255, .1)"/>
              </g>
              <g className="wave2">
                  <use href="#wave-path" x="50" y="0" fill="rgba(255,255,255, .2)"/>
              </g>
              <g className="wave3">
                  <use href="#wave-path" x="50" y="9" fill="#06114f"/>
              </g>
          </svg>
        </div>
    </div> */}
    </>
  )
}

export default Banner
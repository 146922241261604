import React, { useEffect, useState } from "react";
import { GOLD_RESULT_API, HERO_RESULT_API } from "../../services/constant";
import { getMethod } from "../../services";
import { useNavigate } from "react-router-dom";
import Loader from "../../utils/Loader";
import moment from "moment-timezone";
import { getHeroLotteryTime, getHeroName } from "./HeroHelper";

const HeroResultHistory = () => {
  const [loading, setLoading] = useState(false);
  const [history, setHistory] = useState([]);
  const navigate = useNavigate();
  const lotteryTimes = [9, 12, 14, 16, 19, 21];

  const fetchHistory = async () => {
    setLoading(true);
    const token = JSON.parse(localStorage.getItem("l_555_a"));
    if (token) {
      const res = await getMethod(HERO_RESULT_API, token.token);
      res && setLoading(false);
      setLoading(false);
      if (res.data) {
        setHistory(res.data);
      }
    } else {
      navigate("/login");
    }
  };

  useEffect(() => {
    fetchHistory();
  }, []);

  return (
    <div className="mt-6">
      {history.length > 0 && (
        <div className="mb-5">
          <h2 className="text-center text-[100px] font-bold">
            {history[0].name ?? "!!"}
          </h2>
          <p className="text-center translate-y-[-10px]">
            {history[0]
              .date ?? "--/--/----"},{" "}
            {getHeroLotteryTime(history[0].time)}
          </p>
        </div>
      )}
      {history.length > 0 ? (
        history
          .sort((a, b) => a.time - b.time)
          .map((el) => (
            <div
              key={el.id}
              className="bg-[var(--light-color)] border border-[var(--light-color)] p-3 rounded-lg text-base mb-3"
            >
              <div className={`text-center grid grid-flow-row grid-cols-3`}>
                <div className="flex justify-center items-center">
                  <p
                    className={`p-1 rounded shadow-lg px-2 text-xs
                  ${el.time === 'One' && "bg-green-600"}
                  ${el.time === 'Two' && "bg-red-600"}
                  ${el.time === 'Three' && "bg-yellow-600"}
                  ${el.time === 'Four' && "bg-blue-400"}
                  ${el.time === 'Five' && "bg-purple-600"}
                  ${el.time === 'Six' && "bg-orange-600"}
                  `}
                  >
                    {/* {getGoldLotteryTime(el.time)} */}
                    {el.time === 'One' && "09:30 AM"}
                    {el.time === 'Two' && "12:00 PM"}
                    {el.time === 'Three' && "02:00 PM"}
                    {el.time === 'Four' && "04:30 PM"}
                    {el.time === 'Five' && "07:00 PM"}
                    {el.time === 'Six' && "09:00 PM"}
                  </p>
                </div>
                <div className="flex justify-center items-center">
                  <img width={50} className="rounded-md border border-white" src={require(`../../assets/images/hero/${el.name}.jpg`)} alt={el.name} />
                </div>
                <div className="flex flex-col justify-center items-center">
                  <p>{getHeroName(el.name)}</p>
                  <p className="text-xl font-semibold text-[var(--gold-color)]">
                    {el.name}
                  </p>
                </div>
              </div>
            </div>
          ))
      ) : (
        <div className="text-xl h-24 flex items-end justify-center">
          No Result Found!
        </div>
      )}
      {loading && <Loader />}
    </div>
  );
};

export default HeroResultHistory;

import { useState } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  border-radius: 25px;
  background-color: #f4f4f4;
  padding: 2.5px;
  width: 100%;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
`;

const TaebSwitch = styled.div`
  position: relative;
`;

const Tab = styled.div`
  white-space: nowrap;
  display: inline-block;
  width: ${props => props.tabWidth}%;
  padding: 7.5px;
  z-index: 1;
  position: relative;
  cursor: pointer;
  transition: color 100ms;
  user-select: none;
  text-align: center;

  &:hover {
    color: blue; /* Change the hover color as needed */
  }
`;

const AnimatedBar = styled.div`
  position: absolute;
  width: ${props => props.tabWidth}%;
  top: 0;
  transition: left cubic-bezier(0.88, -0.35, 0.565, 1.35) 0.3s;
  border-radius: 25px;
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.1);
  background-color: #09a031;
  height: 100%;
  z-index: 0;
  left: ${(props) => props.left}%;
`;

const NavTab = ({ activeTab, setActiveTab, tabs, tabWidth = 33 }) => {
  const [leftPosition, setLeftPosition] = useState(0);

  const handleTabToggle = (index) => {
    setLeftPosition(index * tabWidth);
  };

  return (
    <Wrapper>
      <TaebSwitch>
        {tabs.map((tab, index) => (
          <Tab
            tabWidth={tabWidth}
            key={index}
            onClick={() => {
              setActiveTab(tab);
              handleTabToggle(index);
            }}
            style={{ color: activeTab === tab ? 'white' : 'black' }}
          >
            {tab}
          </Tab>
        ))}
        <AnimatedBar tabWidth={tabWidth} left={leftPosition} />
      </TaebSwitch>
    </Wrapper>
  );
};

export default NavTab;

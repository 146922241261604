import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import DepositForm from "./DepositForm";
import DepositHistory from "./DepositHistory";
import Header from "../../layout/Header";
import Card from "../../utils/Card";
import Footer from "../../layout/Footer";
import { langChange } from "../../lang";
import { useSelector } from "react-redux";
import Container from "../../utils/Container";
import { BsBank, BsBank2 } from "react-icons/bs";
import { FiClock } from "react-icons/fi";

const Deposit = () => {
  const navigate = useNavigate();
  const url = window.location.pathname;
  const { lang } = useSelector((state) => state.lang);
  useEffect(() => {}, [lang]);
  return (
    <>
      <Header />
      <Container>
        <Card>
          <div className="grid grid-flow-row grid-cols-2 mb-2">
            <div
              onClick={() => navigate("/deposit")}
              className={`flex justify-center items-center gap-2 pb-3 cursor-pointer border-r border-[var(--accent-color)] ${
                url === "/deposit"
                  ? " border-b-[3px] border-[var(--accent-color)] text-[var(--accent-color)]"
                  : "border-b-0"
              }`}
            >
              <BsBank2 className="text-2xl" />
              <p>{langChange.deposit}</p>
            </div>
            <div
              onClick={() => navigate("/deposit-history")}
              className={`flex justify-center items-center gap-2 pb-3 cursor-pointer ${
                url === "/deposit-history"
                  ? "border-b-[3px] border-[var(--accent-color)] text-[var(--accent-color)]"
                  : "border-b-0"
              }`}
            >
              <FiClock className="text-2xl" />
              <p>{langChange.history}</p>
            </div>
          </div>
          {url === "/deposit" ? <DepositForm /> : <DepositHistory />}
        </Card>
      </Container>
      <Footer />
    </>
  );
};

export default Deposit;

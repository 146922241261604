// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Pagination_pagi-container__wreHl {
    display: flex;
    list-style-type: none;
    -webkit-user-select: none;
            user-select: none;
    align-items: center;
}
.Pagination_pagi-container__wreHl li {
   border: 1px solid #999;
   cursor: pointer;
   transition: 0.1s;
   color: #424242;
}
.Pagination_pagi-container__wreHl li a {
    display: block;
}
.Pagination_pagi-container__wreHl li:first-child {
    margin-right: 5px;
    border-radius: 5px;
    width: 30px;
    text-align: center;
    font-size: 20px;
    border: none;
}
.Pagination_pagi-container__wreHl li:last-child {
    margin-left: 5px;
    border-radius: 5px;
    width: 30px;
    text-align: center;
    font-size: 20px;
    border: none;
}
.Pagination_pagi-container__wreHl li:not(:first-child):not(:last-child) {
    border-radius: 50%;
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    font-size: 14px;
    margin: 0 1px;
}
.Pagination_pagi-container__wreHl li a {
    color: #FFF;
}
.Pagination_active__LXc4j {
    background-color: var(--main-color);
}`, "",{"version":3,"sources":["webpack://./src/assets/styles/Pagination.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,qBAAqB;IACrB,yBAAiB;YAAjB,iBAAiB;IACjB,mBAAmB;AACvB;AACA;GACG,sBAAsB;GACtB,eAAe;GACf,gBAAgB;GAChB,cAAc;AACjB;AACA;IACI,cAAc;AAClB;AACA;IACI,iBAAiB;IACjB,kBAAkB;IAClB,WAAW;IACX,kBAAkB;IAClB,eAAe;IACf,YAAY;AAChB;AACA;IACI,gBAAgB;IAChB,kBAAkB;IAClB,WAAW;IACX,kBAAkB;IAClB,eAAe;IACf,YAAY;AAChB;AACA;IACI,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,iBAAiB;IACjB,kBAAkB;IAClB,eAAe;IACf,aAAa;AACjB;AACA;IACI,WAAW;AACf;AACA;IACI,mCAAmC;AACvC","sourcesContent":[".pagi-container {\n    display: flex;\n    list-style-type: none;\n    user-select: none;\n    align-items: center;\n}\n.pagi-container li {\n   border: 1px solid #999;\n   cursor: pointer;\n   transition: 0.1s;\n   color: #424242;\n}\n.pagi-container li a {\n    display: block;\n}\n.pagi-container li:first-child {\n    margin-right: 5px;\n    border-radius: 5px;\n    width: 30px;\n    text-align: center;\n    font-size: 20px;\n    border: none;\n}\n.pagi-container li:last-child {\n    margin-left: 5px;\n    border-radius: 5px;\n    width: 30px;\n    text-align: center;\n    font-size: 20px;\n    border: none;\n}\n.pagi-container li:not(:first-child):not(:last-child) {\n    border-radius: 50%;\n    width: 40px;\n    height: 40px;\n    line-height: 40px;\n    text-align: center;\n    font-size: 14px;\n    margin: 0 1px;\n}\n.pagi-container li a {\n    color: #FFF;\n}\n.active {\n    background-color: var(--main-color);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pagi-container": `Pagination_pagi-container__wreHl`,
	"active": `Pagination_active__LXc4j`
};
export default ___CSS_LOADER_EXPORT___;

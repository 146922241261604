import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import classes from "../assets/styles/WinnerList.module.css";
import NoData from "../utils/NoData";
import Loader from "../utils/Loader";
import { useNavigate } from "react-router-dom";
import { getMethod } from "../services";
import { TWO_D_WINNER_API } from "../services/constant";
import Pagination from "../utils/Pagination";
import Header from "../layout/Header";
import Footer from "../layout/Footer";
import prizeImg from "../assets/images/icons/winner.png";
import Back from "../utils/Back";
import { langChange } from "../lang";
import Select from "react-select";
import { getGoldLotteryTime } from "./Gold/GoldHelper";

const TwodUserWin = () => {
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [totalPage, setTotalPage] = useState(0);
  const [from, setFrom] = useState(1);
  const [page, setPage] = useState(1);
  const [lotteryType, setLotteryType] = useState("MM");

  const lotteryTypes = [
    { value: "MM", label: "Thai 2D" },
    { value: "gold", label: "Gold 2D" },
  ];

  const fetchRecords = async () => {
    setLoading(true);
    const token = JSON.parse(localStorage.getItem("l_555_a"));
    if (token) {
      const res = await getMethod(
        `${TWO_D_WINNER_API}?type=${lotteryType}&page=${page}`,
        token.token
      );
      if (res) {
        setLoading(false);
        if (res.data) {
          setData(res.data);
        }
        if (res.meta) {
          if (res.meta.from) {
            setFrom(res.meta.from);
          }
          if (res.meta.last_page) {
            setTotalPage(res.meta.last_page);
          }
        }
      }
    } else {
      navigate("/login");
    }
  };
  useEffect(() => {
    fetchRecords();
  }, [page, lotteryType]);
  return (
    <>
      <Header />
      <Back />
      <div className="container mx-auto min-h-[85vh] mb-3">
        <div className="w-[95%] max-w-[500px] mx-auto">
          <div className="flex items-center justify-center flex-col cursor-pointer">
            <img src={prizeImg} className="w-[50px]" />
            <p className="text-[16px] mb-0 mt-3">
              {langChange.user_lottery_win} <span className="octin-font">( 2D )</span>
            </p>
          </div>
          <div
            className={`${classes["auth-card-body"]} bg-[var(--light-color)] border border-[var(--light-color)] rounded-lg mt-2`}
          >
            <div className="flex justify-end mb-2">
              <Select
                className="ms-auto w-[160px] text-[var(--secondary-color)] z-[999]"
                placeholder={lotteryTypes[0].label}
                onChange={(e) => setLotteryType(e.value)}
                options={lotteryTypes}
              />
            </div>
            <div className={classes["table-card-container"]}>
              <ul className={classes["table-header"]}>
                <li>
                  <b>စဉ်</b>
                </li>
                <li>အမည်</li>
                <li>ဖုန်းနံပါတ်</li>
                <li>ထီဂဏန်း</li>
                <li>ထိုးငွေ</li>
                <li>စုစုပေါင်း</li>
                <li>အချိန်</li>
                <li>ရက်စွဲ</li>
              </ul>
              {data.length > 0 ? (
                data.map((winner, i) => (
                  <ul key={i} className={classes["table-header"]}>
                    <li>{i + from}.</li>
                    <li className="text-[#f6e25d]">{winner.name}</li>
                    <li>{"****" + winner.phone?.slice(-4)}</li>
                    <li>{winner.lucky_number}</li>
                    <li>{winner.amount.toLocaleString("en-us")}</li>
                    <li>{winner.total.toLocaleString("en-us")}</li>
                    <li>
                      {getGoldLotteryTime(winner.time)}
                      {/* for mm 2d  */}
                      {winner.time === "Thirteen" && "12:01 PM"}
                      {winner.time === "Fourteen" && "04:30 PM"}
                    </li>
                    <li>{winner.date}</li>
                  </ul>
                ))
              ) : (
                <NoData loading={loading} />
              )}
            </div>
            {totalPage > 1 && (
              <div className="flex justify-center pt-2">
                <Pagination
                  setPage={setPage}
                  totalPage={totalPage}
                  setLoading={setLoading}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      <Footer />
      {ReactDOM.createPortal(
        loading && <Loader />,
        document.getElementById("portal")
      )}
    </>
  );
};

export default TwodUserWin;

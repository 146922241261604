import React from 'react'
import classes from '../assets/styles/Layout.module.css';
const Layout = ({type,name,children}) => {
  return (
    <div className='container mx-auto'>
        {
          (type && name) &&
          <div className={classes['section-title']} data-aos="fade-up">
            <p>{type}<strong>{name}</strong></p>
          </div>
        }
        {children}
    </div>
  )
}

export default Layout

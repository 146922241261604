// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PopupFrame_popup__E8MsS {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.536);
  z-index: 1;
}
.PopupFrame_popup_motion__M-Ry2 {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background-color: rgba(0, 0, 0, 0.293); */
  z-index: 1;
}

.PopupFrame_popup_content__Bsp33 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  overflow: hidden;
}

.PopupFrame_close__yTRpl {
  position: absolute;
  top: -10px;
  right: 5px;
  cursor: pointer;
  font-size: 35px;
  color: #fff;
}
`, "",{"version":3,"sources":["webpack://./src/assets/styles/PopupFrame.module.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,eAAe;EACf,MAAM;EACN,OAAO;EACP,WAAW;EACX,YAAY;EACZ,sCAAsC;EACtC,UAAU;AACZ;AACA;EACE,cAAc;EACd,eAAe;EACf,MAAM;EACN,OAAO;EACP,WAAW;EACX,YAAY;EACZ,4CAA4C;EAC5C,UAAU;AACZ;;AAEA;EACE,kBAAkB;EAClB,QAAQ;EACR,SAAS;EACT,gCAAgC;EAChC,kBAAkB;EAClB,2CAA2C;EAC3C,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;EAClB,UAAU;EACV,UAAU;EACV,eAAe;EACf,eAAe;EACf,WAAW;AACb","sourcesContent":[".popup {\n  display: block;\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  background-color: rgba(0, 0, 0, 0.536);\n  z-index: 1;\n}\n.popup_motion {\n  display: block;\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  /* background-color: rgba(0, 0, 0, 0.293); */\n  z-index: 1;\n}\n\n.popup_content {\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n  border-radius: 5px;\n  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);\n  overflow: hidden;\n}\n\n.close {\n  position: absolute;\n  top: -10px;\n  right: 5px;\n  cursor: pointer;\n  font-size: 35px;\n  color: #fff;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"popup": `PopupFrame_popup__E8MsS`,
	"popup_motion": `PopupFrame_popup_motion__M-Ry2`,
	"popup_content": `PopupFrame_popup_content__Bsp33`,
	"close": `PopupFrame_close__yTRpl`
};
export default ___CSS_LOADER_EXPORT___;

import React from 'react';
import ReactPaginate from 'react-paginate';
import classes from '../assets/styles/Pagination.module.css';
import {BsArrowLeft,BsArrowRight} from 'react-icons/bs';
const Pagination = ({setPage,setLoading,totalPage}) => {
    const handlePageClick = async (pag) => {
        setLoading(true);
        setPage(pag.selected + 1);
      };
  return (
    <>
      <ReactPaginate
        className={classes['pagi-container']}
        nextLabel={<BsArrowRight />}
        onPageChange={handlePageClick}
        pageRangeDisplayed={1}
        marginPagesDisplayed={1}
        pageCount={totalPage}
        previousLabel={<BsArrowLeft/>}
        pageClassName="page-item"
        pageLinkClassName="page-link"
        previousClassName="page-item"
        previousLinkClassName="page-link"
        nextClassName="page-item"
        nextLinkClassName="page-link"
        breakLabel="..."
        breakClassName="page-item"
        breakLinkClassName="page-link"
        containerClassName="pagination"
        activeClassName={classes.active}
        renderOnZeroPageCount={null}
      />
    </>
  )
}

export default Pagination
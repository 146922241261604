import React, { useEffect, useState } from "react";
import Footer from "../layout/Footer";
import Header from "../layout/Header";
import { CONTACT_API } from "../services/constant";
import { getMethod } from "../services";
import Loader from "../utils/Loader";
import { langChange } from "../lang";
import Layout from "../utils/Layout";

export default function RuleAndRegulation() {
  const [messages, setMessages] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const fetchMessage = async () => {
    setIsLoading(true);
    const res = await getMethod(CONTACT_API);
    res.con && setMessages(res.data);
    res && setIsLoading(false);
  };

  useEffect(() => {
    fetchMessage();
  }, []);

  return (
    <>
      <Header />
      <Layout type={langChange.rule_and_regulation} name=" ">
        <div className="min-h-[85vh]">
          <div className="container mx-auto">
            <div className="w-[95%] max-w-[500px] mx-auto mt-5 mb-10">
              <div className="leading-8 max-w-[500px] mx-auto text-center" dangerouslySetInnerHTML={{__html: messages.rule}} />
            </div>
          </div>
        </div>
      </Layout>
      
      {isLoading && <Loader />}
      <Footer />
    </>
  );
}

import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import DepositHistory from './Pocket/DepositHistory';
import Header from '../layout/Header';
import Card from '../utils/Card';
import Footer from '../layout/Footer';
import classes from '../assets/styles/Deposit.module.css';
import { langChange } from '../lang';
import { useSelector } from 'react-redux';
import WithdrawHistory from './Pocket/WithdrawHistory';

const TransactionHistory = () => {
  const navigate = useNavigate();
  const url = window.location.pathname;
  const { lang } = useSelector(state => state.lang)
  useEffect(()=> {}, [lang])
  return (
    <>
      <Header />
      <div className='mx-auto h-screen pt-5'>
        <Card>
            <div className={classes['auth-card-header']}>
                 <span className={`${url === '/deposit-transaction' && classes.active} border-r border-[var(--accent-color)]`} onClick={()=> navigate('/deposit-transaction')}>{langChange.deposit_history}</span>
                 <span className={`${url === '/withdraw-transaction' && classes.active}`} onClick={()=> navigate('/withdraw-transaction')}>{langChange.withdraw_history}</span>
            </div>
            {
              url === '/deposit-transaction'?
              <DepositHistory />:
              <WithdrawHistory />
            } 
        </Card>
      </div>
      <Footer />
    </>
  )
}

export default TransactionHistory

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.WithdrawForm_auth-card-body__PkD3Y {
    padding: 10px 0;
}
.WithdrawForm_btn-position__PU2lC { 
    text-align: center;
 }`, "",{"version":3,"sources":["webpack://./src/assets/styles/WithdrawForm.module.css"],"names":[],"mappings":"AAAA;IACI,eAAe;AACnB;AACA;IACI,kBAAkB;CACrB","sourcesContent":[".auth-card-body {\n    padding: 10px 0;\n}\n.btn-position { \n    text-align: center;\n }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"auth-card-body": `WithdrawForm_auth-card-body__PkD3Y`,
	"btn-position": `WithdrawForm_btn-position__PU2lC`
};
export default ___CSS_LOADER_EXPORT___;

import React, { useEffect } from 'react'
import closeImg from '../assets/images/close.png'
import Header from '../layout/Header'
import Footer from '../layout/Footer'
import { useNavigate } from 'react-router-dom'
import { langChange } from '../lang'
import { useSelector } from 'react-redux'
import Back from '../utils/Back';
import { FiHome } from 'react-icons/fi'

export default function Closed() {
    const navigate = useNavigate();
    const { lang } = useSelector(state => state.lang);
    useEffect(() => {}, [lang]);
  return (
    <>
        <Header />
        <Back />
        <div className='flex justify-center flex-col min-h-[85vh]'>
            <img className='w-[320px] mx-auto' src={closeImg} alt="Close.png" />
            <button className='mt-10 bg-[var(--main-color)] w-[120px] mx-auto py-[5px] rounded-md text-lg font-semibold text-[var(--text-color)] flex  items-start justify-center' onClick={()=>navigate('/')}>
             <FiHome className='mt-[2px] me-1' />   {langChange.home}
            </button>
        </div>
        <Footer />
    </>
  )
}

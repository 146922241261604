import { useRef, useState } from "react";
import React, { useEffect } from "react";
import { langChange } from "../../lang";
import { useSelector } from "react-redux";
import { motion } from "framer-motion";
import { postMethod } from "../../services";
import { PROMOTION_API } from "../../services/constant";
import Loader from "../../utils/Loader";
import { errorToaster, successToaster } from "../../utils/Helper";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";

export default function PromotionDetail({
  showDetail,
  setShowDetail,
  promotion,
}) {
  const { lang } = useSelector((state) => state.lang);
  useEffect(() => {}, [lang]);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const toastId = useRef(null);
  const { value } = useSelector((state) => state.userInfoSlice);

  const applyPromotion = async (id) => {
    setIsLoading(true);
    const token = JSON.parse(localStorage.getItem("l_555_a"));
    if (token) {
      const res = await postMethod(
        `${PROMOTION_API}/${id}/claim?user_game_id=${value?.game_info?.id}`,
        {},
        token.token
      );
      res && setIsLoading(false);
      if (res?.con) {
        successToaster("Apply promotion successful", toastId);
      } else {
        errorToaster(res?.data?.error || "Apply promotion failed", toastId);
      }
    } else {
      errorToaster("Login first", toastId);
      setTimeout(() => {
        navigate("/login");
      }, 1000);
    }
  };

  return (
    <div
      className={`${
        showDetail
          ? "w-full h-[100vh] fixed top-0 left-0 z-50 bg-slate-900 bg-opacity-75 px-[5px]"
          : "h-0"
      }`}
    >
      <motion.div
        animate={{ scale: showDetail ? 1 : 0 }}
        transition={{ duration: 0.5 }}
        className=""
      >
        {showDetail && (
          <div className="max-w-[500px] mx-auto mt-10 bg-[var(--primary-color)] border border-[var(--light-color)] rounded-md overflow-hidden relative cursor-pointer">
            <div className="relative">
              <img className="w-full" src={promotion?.photo} alt="Photo" />
              <div className="h-[35px] bg-slate-700 bg-opacity-75 w-full absolute bottom-0 left-0">
                <div className="flex justify-between items-center h-full px-2">
                  <div>
                    {langChange.from}: {promotion?.from}
                  </div>
                  <div>
                    {langChange.to}: {promotion?.to}
                  </div>
                </div>
              </div>
            </div>
            <p className="text-2xl font-semibold text-center py-4 pt-5">
              {promotion?.en_title}
            </p>
            <p className="text-xl font-semibold text-center py-4">
              {promotion?.mm_title}
            </p>
            <p className="text-center px-2 max-h-52 overflow-scroll">
              {promotion?.description}
            </p>
            <div className="flex justify-center gap-3 items-center h-full p-2 py-5">
              <button
                className="p-2 px-4 rounded-md bg-orange-600 border border-orange-600 hover:border-white"
                onClick={(e) => applyPromotion(promotion?.id)}
              >
                {langChange.apply}
              </button>
              <button
                className="p-2 px-4 rounded-md bg-purple-700 border border-slate-500 hover:border-white"
                onClick={setShowDetail}
              >
                {langChange.later}
              </button>
            </div>
          </div>
        )}
      </motion.div>
      {isLoading && <Loader />}
      <ToastContainer limit={1} />
    </div>
  );
}

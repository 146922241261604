// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DepositForm_auth-card-body__v60Vb {
    padding: 10px;
}

.DepositForm_submit-position__dyhXQ {
    text-align: center;
}

/* --------------------------- */
.DepositForm_payment-flex__nxIpX {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    flex-wrap: wrap;
}

.DepositForm_payment-select__587Rw {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}
.DepositForm_payment-select__587Rw img {
    width: 50px;
    margin-right: 15px;
    border-radius: 5px;
}
.DepositForm_payment-select__587Rw p {
    margin-bottom: 0;
    margin-right: 15px;
    font-weight: normal;
}
.DepositForm_payment-select__587Rw p:first-child {
    font-size: 20px;
    margin-bottom: 5px;
}
.DepositForm_payment-select__587Rw p:last-child {
    font-size: 14px;
}
.DepositForm_payment-select__587Rw span {
    font-size: 25px;
    cursor: pointer;
}

.DepositForm_amount-container__1PMbV {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 20px;
}

/* ---------------------------- */
.DepositForm_confirm-container__9O344 p {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 18px;
    margin-bottom: 20px;
}
.DepositForm_btn-position__IOLxo { 
   text-align: center;
}
.DepositForm_note__dDsOP {
    font-size: 14px;
    color: var(--font-color);
    margin-bottom: 10px;
}`, "",{"version":3,"sources":["webpack://./src/assets/styles/DepositForm.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;AACjB;;AAEA;IACI,kBAAkB;AACtB;;AAEA,gCAAgC;AAChC;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,mBAAmB;IACnB,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,mBAAmB;AACvB;AACA;IACI,WAAW;IACX,kBAAkB;IAClB,kBAAkB;AACtB;AACA;IACI,gBAAgB;IAChB,kBAAkB;IAClB,mBAAmB;AACvB;AACA;IACI,eAAe;IACf,kBAAkB;AACtB;AACA;IACI,eAAe;AACnB;AACA;IACI,eAAe;IACf,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,eAAe;IACf,mBAAmB;AACvB;;AAEA,iCAAiC;AACjC;IACI,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,eAAe;IACf,mBAAmB;AACvB;AACA;GACG,kBAAkB;AACrB;AACA;IACI,eAAe;IACf,wBAAwB;IACxB,mBAAmB;AACvB","sourcesContent":[".auth-card-body {\n    padding: 10px;\n}\n\n.submit-position {\n    text-align: center;\n}\n\n/* --------------------------- */\n.payment-flex {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    margin-bottom: 20px;\n    flex-wrap: wrap;\n}\n\n.payment-select {\n    display: flex;\n    align-items: center;\n    margin-bottom: 15px;\n}\n.payment-select img {\n    width: 50px;\n    margin-right: 15px;\n    border-radius: 5px;\n}\n.payment-select p {\n    margin-bottom: 0;\n    margin-right: 15px;\n    font-weight: normal;\n}\n.payment-select p:first-child {\n    font-size: 20px;\n    margin-bottom: 5px;\n}\n.payment-select p:last-child {\n    font-size: 14px;\n}\n.payment-select span {\n    font-size: 25px;\n    cursor: pointer;\n}\n\n.amount-container {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    flex-wrap: wrap;\n    margin-bottom: 20px;\n}\n\n/* ---------------------------- */\n.confirm-container p {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    font-size: 18px;\n    margin-bottom: 20px;\n}\n.btn-position { \n   text-align: center;\n}\n.note {\n    font-size: 14px;\n    color: var(--font-color);\n    margin-bottom: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"auth-card-body": `DepositForm_auth-card-body__v60Vb`,
	"submit-position": `DepositForm_submit-position__dyhXQ`,
	"payment-flex": `DepositForm_payment-flex__nxIpX`,
	"payment-select": `DepositForm_payment-select__587Rw`,
	"amount-container": `DepositForm_amount-container__1PMbV`,
	"confirm-container": `DepositForm_confirm-container__9O344`,
	"btn-position": `DepositForm_btn-position__IOLxo`,
	"note": `DepositForm_note__dDsOP`
};
export default ___CSS_LOADER_EXPORT___;

import { createSlice } from "@reduxjs/toolkit";

const pocketStatusSlice =  createSlice({
    name: 'pocket_status',
    initialState: {
        pocketStatusData: {}
    },
    reducers: {
        pocketStatusDataHandler: (state,action) => {
            state.pocketStatusData = action.payload;
        },
        cleanPocketStatusDataHandler: (state) => {
            state.pocketStatusData = {};
        },
    }
});

export const {pocketStatusDataHandler,cleanPocketStatusDataHandler} = pocketStatusSlice.actions;
export default pocketStatusSlice.reducer;
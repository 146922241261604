import React, { useEffect, useState } from 'react';
import OtpInput from 'react-otp-input';
import classes from '../assets/styles/RegisterVerify.module.css';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import logo from '../assets/images/logo/logo.png';
import Card from '../utils/Card';
import { userDataHandler } from '../store/slices/registerSlice';
import { langChange } from '../lang';
import Back from '../utils/Back';

const ForgotVerify = () => {
    const [otp, setOtp] = useState('');
    const {registerVal} = useSelector(state => state.registerSlice);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { lang } = useSelector(state => state.lang);
    useEffect(() => {}, [lang]);

    useEffect(() => {
        if(!registerVal.phone){
           navigate('/forgot-password');
        };
    },[registerVal]);

    const submitHandler = async () => {
        const data = {
           ...registerVal,
           token: otp
        };
        dispatch(userDataHandler(data));
        navigate('/verify/password-create');
    };

    useEffect(()=>{
        if(otp.length === 6){
            submitHandler();
        };
      },[otp]);

  return (
    <>
    <Back />
        <div className='mx-auto h-screen py-16'>
          <div className='mb-4'>
              <div className='w-24 mx-auto'>
                  <Link to="/">
                      <img src={logo} className='w-full rounded-lg'/>
                  </Link>
              </div>
          </div>
            <Card>
                <div className={classes['auth-card-header']}>
                    {langChange.enter_otp_from_sms}
                </div>
                <form>
                    <div className='flex items-center justify-center pb-5'>
                        <OtpInput
                            value={otp}
                            onChange={setOtp}
                            numInputs={6}
                            inputType='number'
                            renderSeparator={<span>&nbsp;&nbsp;&nbsp;</span>}
                            renderInput={(props) => <input {...props} className={classes['opt-input']} />}
                        />
                    </div>
                </form>
            </Card>
        </div>
    </>
  )
}

export default ForgotVerify
import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from "../assets/images/logo/logo.png";
import classes from "../assets/styles/Header.module.css";
import profileImage from "../assets/images/icons/profile.png";
import { USER_INFO_API } from "../services/constant";
import { getMethod } from "../services";
import { useDispatch, useSelector } from "react-redux";
import { infoHandler } from "../store/slices/userInfoSlice";
import { langChange } from "../lang";
import { FiDownload } from "react-icons/fi";

let deferredPrompt;

window.addEventListener("beforeinstallprompt", (event) => {
  event.preventDefault();
  deferredPrompt = event;
});

const Header = () => {
  const { value } = useSelector((state) => state.userInfoSlice);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { lang } = useSelector((state) => state.lang);
  useEffect(() => {}, [lang]);

  const fetchUserInfo = async () => {
    const token = localStorage.getItem("l_555_a");
    if (token) {
      const deToken = JSON.parse(token);
      let res = await getMethod(
        USER_INFO_API + "?game_token=" + deToken.game_token,
        deToken.token
      );
      if (res) {
        if (res === "Unauthenticated.") {
          localStorage.removeItem("l_555_a");
          navigate("/login");
        }
        if (res.con) {
          dispatch(infoHandler(res?.data));
        }
      }
    }
  };

  useEffect(() => {
    if (!value?.user_info) {
      fetchUserInfo();
    }
  },[]);

  useEffect(() => {
    if(value?.refresh)
    {
      fetchUserInfo();
    };
  }, [value]);

  const triggerPWAInstallation = () => {
    if (deferredPrompt) {
      deferredPrompt.prompt();
    } else {
      window.open('https://www.mediafire.com/file/40t4jggg51l86s1/Lucky_555_1.apk/file')
    }
  };

  const url = window.location.pathname;

  return (
    <div className={`bg-[var(--primary-color)] sticky top-0 z-[9999] shadow-md`}>
      {
        url === '/' && <div
        className={`absolute right-[10px] md:right-[400px] text-[var(--main-color)] bg-[var(--accent-color)] rounded-md py-1 px-3 flex justify-end items-center gap-2 hover:opacity-80 cursor-pointer max-h-[35px] pwa-button top-[76px] md:top-[50%] md:translate-y-[-50%]`}
        onClick={() => triggerPWAInstallation()}
      >
        App <FiDownload />
      </div>
      }
      <nav className="py-2 px-4 container mx-auto bg-transparent flex justify-between items-center">
        <div className="flex items-center">
          <Link to="/">
            <img src={logo} alt="logo" className="w-12 lg:w-14 rounded-lg" />
          </Link>
          <div className="hidden lg:block ml-20">
            <ul className="flex justify-between items-center select-none">
              <li className={`relative ${classes["sidebar-icon"]}`}>
                <Link to="/">
                  <span
                    className={`px-3.5 py-1 block ${classes["hover-underline"]}`}
                  >
                    {langChange.home}
                  </span>
                </Link>
              </li>
              {localStorage.getItem("l_555_a") && (
                <>
                  <li className={`relative ${classes["sidebar-icon"]}`}>
                    <Link to="/notifications">
                      <span
                        className={`px-3.5 py-1 block ${classes["hover-underline"]}`}
                      >
                        {langChange.notification}
                      </span>
                    </Link>
                  </li>
                  <li className={`relative ${classes["sidebar-icon"]}`}>
                    <Link to="/pocket">
                      <span
                        className={`px-3.5 py-1 block ${classes["hover-underline"]}`}
                      >
                        {langChange.pocket}
                      </span>
                    </Link>
                  </li>
                  <li className={`relative ${classes["sidebar-icon"]}`}>
                    <Link to="/profile">
                      <span
                        className={`px-3.5 py-1 block ${classes["hover-underline"]}`}
                      >
                        {langChange.profile}
                      </span>
                    </Link>
                  </li>
                </>
              )}
            </ul>
          </div>
        </div>
        {localStorage.getItem("l_555_a") ? (
          <div>
            <Link to="/profile">
              <div className={`${classes["profile"]}`}>
                <img src={profileImage} className={classes["profile-img"]} alt="profile" />
                <div>
                  <p className={classes["user-name"]}>{`${
                    value?.user_info?.name?.slice(0, 14) ?? "--"
                  } ${value?.user_info?.name?.length > 14 ? '...' : ''}`}</p>
                  <p className={classes["user-balance"]}>
                    {value?.user_info?.current_balance?.toLocaleString("en-us") ?? 0}
                  </p>
                </div>
              </div>
            </Link>
          </div>
        ) : (
          <ul className="flex justify-between items-center select-none py-2">
            <li className={`relative ${classes["sidebar-icon"]}`}>
              <Link to="/login">
                <span
                  className={`px-3.5 py-2 block whitespace-nowrap ${classes["hover-underline"]}`}
                >
                  {langChange.login}
                </span>
              </Link>
            </li>
            <li className={`${classes["sidebar-icon"]} ${classes.authActive}`}>
              <Link to="/register">
                <span
                  className={`px-5 py-2 block whitespace-nowrap text-[var(--main-color)]`}
                >
                  {langChange.register}
                </span>
              </Link>
            </li>
          </ul>
        )}
      </nav>
    </div>
  );
};

export default Header;

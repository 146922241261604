import React from 'react'
import { BrowserRouter as Router, Routes,Route} from "react-router-dom";
import Main from '../components/Main';
import GameList from '../components/GameList';
import Login from '../auths/Login';
import Register from '../auths/Register';
import RegisterVerify from '../auths/RegisterVerify';
import ForgotPassword from '../auths/ForgotPassword';
import ForgotVerify from '../auths/ForgotVerify';
import PasswordCreate from '../auths/PasswordCreate';
import ChangePassword from '../auths/ChangePassword';
import RouteGuard from '../routes/RouteGuard';
import Deposit from '../components/Pocket/Deposit';
import Withdraw from '../components/Pocket/Withdraw';
import Twod from '../components/Twod/Twod';
import Threed from '../components/ThreeD/Threed';
import Pocket from '../components/Pocket';
import Profile from '../components/Profile';
import TwodHistory from '../components/Twod/TwodHistory';
import TwodNumberList from '../components/Twod/TwodNumberList';
import BetSubmit from '../components/Twod/BetSubmit';
import Closed from '../utils/Closed';
import ThreedNumberList from '../components/ThreeD/ThreedNumberList';
import ThreedBetSubmit from '../components/ThreeD/ThreedBetSubmit';
import ThreedHistory from '../components/ThreeD/ThreedHistory';
import ThreedWinnerList from '../components/ThreeD/ThreedWinnerList';
import LetterAndHelp from '../components/LetterAndHelp';
import { useDispatch } from 'react-redux';
import { langTypeHandler } from '../store/slices/langSlice';
import { langChange } from '../lang';
import Promotion from '../components/Promotion/Promotion';
import Notification from '../components/Notification';
import TwodHkwayHtoe from '../components/Twod/TwodHkwayHtoe';
import ContactPage from '../components/ContactPage';
import RuleAndRegulation from '../components/RuleAndRegulation';
import TransactionHistory from '../components/TransactionHistory';
import ScrollToTop from '../utils/ScrollToTop';
import DubaiTwod from '../components/Dubai/DubaiTwod';
import DubaiTwodNumberList from '../components/Dubai/DubaiTwodNumberList';
import DubaiBetSubmit from '../components/Dubai/DubaiBetSubmit';
import DubaiTwodHkwayHtoe from '../components/Dubai/DubaiTwodHkwayHtoe';
import DubaiTwodHistory from '../components/Dubai/DubaiTwodHistory';
import RoyalTwod from '../components/Royal/RoyalTwod';
import RoyalHkwayHtoe from '../components/Royal/RoyalHkwayHtoe';
import RoyalNumberList from '../components/Royal/RoyalNumberList';
import RoyalBetSubmit from '../components/Royal/RoyalBetSubmit';
import RoyalHistory from '../components/Royal/RoyalHistory';
import GoldTwod from '../components/Gold/GoldTwod';
import GoldHkwayHtoe from '../components/Gold/GoldHkwayHtoe';
import GoldNumberList from '../components/Gold/GoldNumberList';
import GoldBetSubmit from '../components/Gold/GoldBetSubmit';
import GoldHistory from '../components/Gold/GoldHistory';
import TwodUserWin from '../components/TwodUserWin';
import TwoWinnerList from '../components/TwoWinnerList';
import ThreedUserWin from '../components/ThreeD/ThreedUserWin';
import HeroTwod from '../components/Hero/HeroTwod';
import HeroHkwayHtoe from '../components/Hero/HeroHkwayHtoe';
import HeroNumberList from '../components/Hero/HeroNumberList';
import HeroHistory from '../components/Hero/HeroHistory';
import HeroBetSubmit from '../components/Hero/HeroBetSubmit';
import GoldThreed from '../components/GoldThreeD/GoldThreed';
import GoldThreedNumberList from '../components/GoldThreeD/GoldThreedNumberList';
import GoldThreedWinnerList from '../components/GoldThreeD/GoldThreedWinnerList';
import GoldThreedHistory from '../components/GoldThreeD/GoldThreedHistory';
import GoldThreedBetSubmit from '../components/GoldThreeD/GoldThreedBetSubmit';
import Shan from '../components/Shan';
import { GAME_ONE_API, GAME_TWO_API } from '../services/constant';

const Routers = () => {
    const dispatch = useDispatch();
    const s_y_m_lang = localStorage.getItem("s_y_m_lang");
    if (s_y_m_lang) {
      dispatch(langTypeHandler(s_y_m_lang));
      langChange.setLanguage(s_y_m_lang);
    } else {
      localStorage.setItem("s_y_m_lang", "unicode");
      langChange.setLanguage("unicode");
      dispatch(langTypeHandler("unicode"));
    }
  return (
    <Router>
        <ScrollToTop />
        <Routes>
           {/* ----------- auth ---------- */}
            <Route path='/login' element={<Login />} />
            <Route path='/register' element={<Register />} />
            <Route path='/verify' element={<RegisterVerify />} />
            <Route path='/forgot-password' element={<ForgotPassword />} />
            <Route path='/verify/password' element={<ForgotVerify />} />
            <Route path='/verify/password-create' element={<PasswordCreate />} />
            <Route path='/change-password' element={<RouteGuard><ChangePassword /></RouteGuard>} />
            {/* ----------- games ---------- */}
            <Route path="/:name/:id" element={<RouteGuard><GameList /></RouteGuard>} />
            {/* ----------- start mm2d ----------- */}
            {
              ['/all/bet/2d','/2d3d/bet/2d'].map((path,i) => 
                <Route key={i} path={path} element={<RouteGuard><Twod /></RouteGuard>} />
              )
            }
            {
              ['/all/bet/2d/time/:time/hkway-htoe','/2d3d/bet/2d/time/:time/hkway-htoe'].map((path,i) => 
                <Route key={i} path={path} element={<RouteGuard><TwodHkwayHtoe /></RouteGuard>} />
              )
            }
            {
              ['/all/bet/2d/time/:time','/2d3d/bet/2d/time/:time'].map((path,i) => 
                <Route key={i} path={path} element={<RouteGuard><TwodNumberList /></RouteGuard>} />
              )
            }
            {
              ['/all/bet/2d/time/:time/confirm','/2d3d/bet/2d/time/:time/confirm'].map((path,i) => 
                <Route key={i} path={path} element={<RouteGuard><BetSubmit /></RouteGuard>} />
              )
            }
            {
              ['/all/bet/2d/history','/2d3d/bet/2d/history','/all/bet/2d/result-history','/2d3d/bet/2d/result-history'].map((path,i) => 
                <Route key={i} path={path} element={<RouteGuard><TwodHistory /></RouteGuard>} />
              )
            }
            {
              ['/all/bet/:lottery_type/winners','/2d3d/bet/:lottery_type/winners'].map((path,i) => 
                <Route key={i} path={path} element={<RouteGuard><TwoWinnerList /></RouteGuard>} />
              )
            }
            <Route path={'/2d-user-win'} element={<RouteGuard><TwodUserWin /></RouteGuard>} />
            {/* ----------- end mm2d ----------- */}
            {/* ----------- start dubai2d ----------- */}
            {
              ['/all/bet/dubai2d','/2d3d/bet/dubai2d'].map((path,i) => 
                <Route key={i} path={path} element={<RouteGuard><DubaiTwod /></RouteGuard>} />
              )
            }
            {
              ['/all/bet/dubai2d/time/:time/hkway-htoe','/2d3d/bet/dubai2d/time/:time/hkway-htoe'].map((path,i) => 
                <Route key={i} path={path} element={<RouteGuard><DubaiTwodHkwayHtoe /></RouteGuard>} />
              )
            }
            {
              ['/all/bet/dubai2d/time/:time','/2d3d/bet/dubai2d/time/:time'].map((path,i) => 
                <Route key={i} path={path} element={<RouteGuard><DubaiTwodNumberList /></RouteGuard>} />
              )
            }
            {
              ['/all/bet/dubai2d/time/:time/confirm','/2d3d/bet/dubai2d/time/:time/confirm'].map((path,i) => 
                <Route key={i} path={path} element={<RouteGuard><DubaiBetSubmit /></RouteGuard>} />
              )
            }
            {
              ['/all/bet/dubai2d/history','/2d3d/bet/dubai2d/history','/all/bet/dubai2d/result-history','/2d3d/bet/dubai2d/result-history'].map((path,i) => 
                <Route key={i} path={path} element={<RouteGuard><DubaiTwodHistory /></RouteGuard>} />
              )
            }
            {/* ----------- end dubai2d ----------- */}
            {/* ----------- start royal2d ----------- */}
            {
              ['/all/bet/royal2d','/2d3d/bet/royal2d'].map((path,i) => 
                <Route key={i} path={path} element={<RouteGuard><RoyalTwod /></RouteGuard>} />
              )
            }
            {
              ['/all/bet/royal2d/time/:time/hkway-htoe','/2d3d/bet/royal2d/time/:time/hkway-htoe'].map((path,i) => 
                <Route key={i} path={path} element={<RouteGuard><RoyalHkwayHtoe /></RouteGuard>} />
              )
            }
            {
              ['/all/bet/royal2d/time/:time','/2d3d/bet/royal2d/time/:time'].map((path,i) => 
                <Route key={i} path={path} element={<RouteGuard><RoyalNumberList /></RouteGuard>} />
              )
            }
            {
              ['/all/bet/royal2d/time/:time/confirm','/2d3d/bet/royal2d/time/:time/confirm'].map((path,i) => 
                <Route key={i} path={path} element={<RouteGuard><RoyalBetSubmit /></RouteGuard>} />
              )
            }
            {
              ['/all/bet/royal2d/history','/2d3d/bet/royal2d/history','/all/bet/royal2d/result-history','/2d3d/bet/royal2d/result-history'].map((path,i) => 
                <Route key={i} path={path} element={<RouteGuard><RoyalHistory /></RouteGuard>} />
              )
            }
            {/* ----------- end royal2d ----------- */}
            {/* ----------- start gold2d ----------- */}
            {
              ['/all/bet/gold2d','/2d3d/bet/gold2d'].map((path,i) => 
                <Route key={i} path={path} element={<RouteGuard><GoldTwod /></RouteGuard>} />
              )
            }
            {
              ['/all/bet/gold2d/time/:time/hkway-htoe','/2d3d/bet/gold2d/time/:time/hkway-htoe'].map((path,i) => 
                <Route key={i} path={path} element={<RouteGuard><GoldHkwayHtoe /></RouteGuard>} />
              )
            }
            {
              ['/all/bet/gold2d/time/:time','/2d3d/bet/gold2d/time/:time'].map((path,i) => 
                <Route key={i} path={path} element={<RouteGuard><GoldNumberList /></RouteGuard>} />
              )
            }
            {
              ['/all/bet/gold2d/time/:time/confirm','/2d3d/bet/gold2d/time/:time/confirm'].map((path,i) => 
                <Route key={i} path={path} element={<RouteGuard><GoldBetSubmit /></RouteGuard>} />
              )
            }
            {
              ['/all/bet/gold2d/history','/2d3d/bet/gold2d/history','/all/bet/gold2d/result-history','/2d3d/bet/gold2d/result-history'].map((path,i) => 
                <Route key={i} path={path} element={<RouteGuard><GoldHistory /></RouteGuard>} />
              )
            }
            {/* ----------- end gold2d ----------- */}
            {/* ----------- start hero2d ----------- */}
            {
              ['/all/bet/hero2d','/2d3d/bet/hero2d'].map((path,i) => 
                <Route key={i} path={path} element={<RouteGuard><HeroTwod /></RouteGuard>} />
              )
            }
            {
              ['/all/bet/hero2d/time/:time/hkway-htoe','/2d3d/bet/hero2d/time/:time/hkway-htoe'].map((path,i) => 
                <Route key={i} path={path} element={<RouteGuard><HeroHkwayHtoe /></RouteGuard>} />
              )
            }
            {
              ['/all/bet/hero2d/time/:time','/2d3d/bet/hero2d/time/:time'].map((path,i) => 
                <Route key={i} path={path} element={<RouteGuard><HeroNumberList /></RouteGuard>} />
              )
            }
            {
              ['/all/bet/hero2d/time/:time/confirm','/2d3d/bet/hero2d/time/:time/confirm'].map((path,i) => 
                <Route key={i} path={path} element={<RouteGuard><HeroBetSubmit /></RouteGuard>} />
              )
            }
            {
              ['/all/bet/hero2d/history','/2d3d/bet/hero2d/history','/all/bet/hero2d/result-history','/2d3d/bet/hero2d/result-history'].map((path,i) => 
                <Route key={i} path={path} element={<RouteGuard><HeroHistory /></RouteGuard>} />
              )
            }
            {/* ----------- end hero2d ----------- */}
            {/* ----------- start 3d ----------- */}
            {
              ['/all/bet/3d/number-lists/confirm','/2d3d/bet/3d/number-lists/confirm'].map((path,i) => 
                <Route key={i} path={path} element={<RouteGuard><ThreedBetSubmit /></RouteGuard>} />
              )
            }
            {
              ['/all/bet/3d/history','/2d3d/bet/3d/history','/all/bet/3d/result-history','/2d3d/bet/3d/result-history'].map((path,i) => 
                <Route key={i} path={path} element={<RouteGuard><ThreedHistory /></RouteGuard>} />
              )
            }
            {
              ['/all/bet/3d/winner-lists','/2d3d/bet/3d/winner-lists'].map((path,i) => 
                <Route key={i} path={path} element={<RouteGuard><ThreedWinnerList /></RouteGuard>} />
              )
            }
            {
              ['/all/bet/3d/number-lists','/2d3d/bet/3d/number-lists'].map((path,i) => 
                <Route key={i} path={path} element={<RouteGuard><ThreedNumberList /></RouteGuard>} />
              )
            }
            {
              ['/all/bet/3d','/2d3d/bet/3d'].map((path,i) => 
                <Route key={i} path={path} element={<RouteGuard><Threed /></RouteGuard>} />
              )
            }
            <Route path={'/3d-user-win'} element={<RouteGuard><ThreedUserWin /></RouteGuard>} />
            {/* ----------- end 3d ----------- */}
            {/* ----------- start gold 3d ----------- */}
            {
              ['/all/bet/gold3d/number-lists/confirm','/2d3d/bet/gold3d/number-lists/confirm'].map((path,i) => 
                <Route key={i} path={path} element={<RouteGuard><GoldThreedBetSubmit /></RouteGuard>} />
              )
            }
            {
              ['/all/bet/gold3d/history','/2d3d/bet/gold3d/history','/all/bet/gold3d/result-history','/2d3d/bet/gold3d/result-history'].map((path,i) => 
                <Route key={i} path={path} element={<RouteGuard><GoldThreedHistory /></RouteGuard>} />
              )
            }
            {
              ['/all/bet/gold3d/winner-lists','/2d3d/bet/gold3d/winner-lists'].map((path,i) => 
                <Route key={i} path={path} element={<RouteGuard><GoldThreedWinnerList /></RouteGuard>} />
              )
            }
            {
              ['/all/bet/gold3d/number-lists','/2d3d/bet/gold3d/number-lists'].map((path,i) => 
                <Route key={i} path={path} element={<RouteGuard><GoldThreedNumberList /></RouteGuard>} />
              )
            }
            {
              ['/all/bet/gold3d','/2d3d/bet/gold3d'].map((path,i) =>
                <Route key={i} path={path} element={<RouteGuard><GoldThreed /></RouteGuard>} />
              ) 
            }
            <Route path={'/3d-user-win'} element={<RouteGuard><ThreedUserWin /></RouteGuard>} />
            {/* ----------- end gold 3d ----------- */}
            {/* ----------- pages ---------- */}
            <Route path="/pocket" element={<RouteGuard><Pocket /></RouteGuard>} />
            <Route path="/profile" element={<RouteGuard><Profile /></RouteGuard>} />
            <Route path="/contact" element={<ContactPage />} />
            {/* ----------- Payment ---------- */}

            {
              ['/deposit','/deposit-history'].map((path,i) => 
                <Route key={i} path={path} element={<RouteGuard><Deposit /></RouteGuard>} />
              )
            }
            {
              ['/withdraw','/withdraw-history'].map((path,i) => 
                <Route key={i} path={path} element={<RouteGuard><Withdraw /></RouteGuard>} />
              )
            }
            {
              ['/deposit-transaction','/withdraw-transaction'].map((path,i) => 
                <Route key={i} path={path} element={<RouteGuard><TransactionHistory /></RouteGuard>} />
              )
            }
            <Route path='/' element={<Main />} />
            <Route path='/closed' element={<RouteGuard><Closed /></RouteGuard>} />
            <Route path='/recommendation-and-help' element={<RouteGuard><LetterAndHelp /></RouteGuard>} />
            <Route path='/promotions' element={<Promotion />} />
            <Route path='/notifications' element={<RouteGuard><Notification /></RouteGuard>} />
            <Route path='/rules-and-regulations' element={<RouteGuard><RuleAndRegulation /></RouteGuard>} />
            <Route path='/shan-one' element={<RouteGuard><Shan api={GAME_ONE_API} /></RouteGuard>} />
            <Route path='/shan-two' element={<RouteGuard><Shan api={GAME_TWO_API} /></RouteGuard>} />
        </Routes>
    </Router>
  )
}

export default Routers
import React from "react";
import noti_img from "../assets/images/icons/anounce.png";
import moment from "moment-timezone";
import Linkify from "react-linkify";

export default function NotificationItem({title, body}) {
  return (
    <div className="bg-[var(--light-color)] p-3 pb-0 shadow-lg rounded-lg flex items-center border border-slate-600 mb-2">
      <img src={noti_img} className="w-[20px] mx-1 mr-3" alt="Noti Img" />
      <div className="w-full">
        <div className="border-b-[1px] mb-4 border-b-[var(--text-color)] items-center">
          <h2 className="text-md mb-2 font-semibold break-all">{title}</h2>
        </div>
        <p className="text-slate-100 break-all indent-6">
          <Linkify>
            {body}
          </Linkify>
        </p>
        <p className="text-end text-sm my-3">{moment().format('YYYY-MM-DD')}</p>
      </div>
    </div>
  );
}

import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import OtpInput from 'react-otp-input';
import classes from '../assets/styles/RegisterVerify.module.css';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import logo from '../assets/images/logo/logo.png';
import { postMethod } from '../services';
import { REGISTER_API } from '../services/constant';
import Card from '../utils/Card';
import Loader from '../utils/Loader';
import { langChange } from '../lang';
import Back from '../utils/Back';
import { ToastContainer } from 'react-toastify';
import { errorToaster, successToaster } from '../utils/Helper';

const RegisterVerify = () => {
  const [loading,setLoading] = useState(false);
  const [error,setError] = useState("");
  const [otp, setOtp] = useState('');
  const {registerVal} = useSelector(state => state.registerSlice);
  const navigate = useNavigate();
  const toastId = useRef(null);

  useEffect(() => {
     if(!registerVal.name){
         navigate('/register');
     };
  },[registerVal]);

  const { lang } = useSelector(state => state.lang);
  useEffect(() => {}, [lang])
  
  const submitHandler = async () => {
      setLoading(true);
      const data = {
         ...registerVal,
         token: otp,
      }
      const res = await postMethod(REGISTER_API, data);
      if(res){
         setLoading(false);
         if(res === 'Failed to fetch'){
          errorToaster('Please check internet connection!', toastId);
        };
        if(res?.con){
          successToaster('Register successful!', toastId);
          localStorage.setItem('l_555_a', JSON.stringify(res.data));
          setTimeout(() => {
            navigate('/')
          }, 1500)
        }else{
          if(res?.data?.error){
            setError(res?.data?.error);
          };
          if(res?.data?.name){
            setError(res?.data?.name[0]);
          };
          if(res?.data?.phone){
            setError(res?.data?.phone[0]);
          };
        };
      }
  };
  useEffect(()=>{
    if(otp.length === 6){
        submitHandler();
    }
    setError("");
  },[otp]);

  return (
    <>
    <Back />
        <div className='mx-auto h-screen py-16'>
          <div className='mb-4'>
              <div className='w-32 mx-auto'>
                  <Link to="/">
                      <img src={logo} className='w-full rounded-lg'/>
                  </Link>
              </div>
          </div>
            <Card>
                <div className={classes['auth-card-header']}>
                    {langChange.enter_otp_from_sms}
                </div>
                <form>
                    <div className='flex items-center justify-center pb-5'>
                      <OtpInput
                          value={otp}
                          onChange={setOtp}
                          numInputs={6}
                          inputType='number'
                          renderSeparator={<span>&nbsp;&nbsp;&nbsp;</span>}
                          renderInput={(props) => <input {...props} className={classes['opt-input']} />}
                      />
                    </div>
                    {
                        error &&
                        <span className={classes['error-message']}>{error}</span>
                    }
                </form>
            </Card>
        </div>
        {
           ReactDOM.createPortal(loading && <Loader/>, document.getElementById('portal'))
        }
        <ToastContainer limit={1} />
    </>
  )
}

export default RegisterVerify
// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Login_login-container__inQJo {
  margin-top: 30px;
}
.Login_forget-pass__Q6phQ {
  width: 220px;
  margin: 20px auto 0 auto;
}
.Login_forget-text__kU0SZ {
  text-align: center;
  color: #fff;
}
.Login_line-through__aB7ac {
  border: .5px solid var(--accent-color);
  margin: 10px 0;
}
`, "",{"version":3,"sources":["webpack://./src/assets/styles/Login.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB;AACA;EACE,YAAY;EACZ,wBAAwB;AAC1B;AACA;EACE,kBAAkB;EAClB,WAAW;AACb;AACA;EACE,sCAAsC;EACtC,cAAc;AAChB","sourcesContent":[".login-container {\n  margin-top: 30px;\n}\n.forget-pass {\n  width: 220px;\n  margin: 20px auto 0 auto;\n}\n.forget-text {\n  text-align: center;\n  color: #fff;\n}\n.line-through {\n  border: .5px solid var(--accent-color);\n  margin: 10px 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"login-container": `Login_login-container__inQJo`,
	"forget-pass": `Login_forget-pass__Q6phQ`,
	"forget-text": `Login_forget-text__kU0SZ`,
	"line-through": `Login_line-through__aB7ac`
};
export default ___CSS_LOADER_EXPORT___;

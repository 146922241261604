import { createSlice } from "@reduxjs/toolkit";

const langSlice = createSlice({
  name: "lang",
  initialState: {
    lang: '',
  },
  reducers: {
    langTypeHandler: (state, action) => {
      state.lang = action.payload;
    },
  },
});

export const { langTypeHandler } = langSlice.actions;
export default langSlice.reducer;
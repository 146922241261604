import { errorToaster } from '../utils/Helper';

export const getMethod = async (api, token = "") => {
    try {
        const fetchApi = await fetch(api, {
            method: "GET",
            headers: {
                "accept": "application/json",
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`,
            }
        });
        const res = await fetchApi.json();
        if (res.status === 'success' || res.status === 200 || res.success || res?.data?.length > 0) {
            return {
                con: true,
                ...res
            }
        } else {
            if (res.message === 'Unauthenticated.') {
                localStorage.removeItem('l_555_a');
                return res.messge;
            };
            if (res.error === 'Unauthenticated.') {
                localStorage.removeItem('l_555_a');
                return res.error;
            };
            return {
                con: false,
                ...res
            }
        }
    }
    catch (err) {
        return err.message;
    }
}

export const postMethod = async (api, data, token = "", isShowToast = true) => {
    try {
        const fetchApi = await fetch(api, {
            method: 'POST',
            headers: {
                "accept": "application/json",
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`,
            },
            body: JSON.stringify(data)
        });
        const res = await fetchApi.json();
        if (res.status === 'success' || res.success) {
            return {
                con: true,
                ...res
            };
        } else {
            if (res.message === 'Unauthenticated.') {
                localStorage.removeItem('l_555_a');
                return res.messge;
            };
            if (res.error === 'Unauthenticated.') {
                localStorage.removeItem('l_555_a');
                return res.error;
            };
            if (res?.data?.[Object.keys(res.data)[0]]) {
                isShowToast && errorToaster(res?.data?.[Object.keys(res.data)[0]] || "Somethin wrong!", 1)
                return {
                    con: false,
                    ...res
                };
            } 
            if (res?.data?.error) {
                isShowToast && errorToaster(res?.data?.error || 'Something wrong!', 1)
                return {
                    con: false,
                    ...res
                };
            } else {
                isShowToast && errorToaster(res?.error ? res?.error : res?.message || "Somethin wrong!", 1);
                return {
                    con: false,
                    ...res
                };
            }
        };
    }
    catch (err) {
        return err.message
    }
};

export const postMethodFormData = async (api, formData, token = "", isShowToast = true) => {
    try {
        const fetchApi = await fetch(api, {
            method: 'POST',
            headers: {
                "accept": "multipart/form-data",
                "Authorization": `Bearer ${token}`,
            },
            body: formData
        });
        const res = await fetchApi.json();
        if (res.status === 'success' || res.success) {
            return {
                con: true,
                ...res
            };
        } else {
            if (res.message === 'Unauthenticated.') {
                localStorage.removeItem('l_555_a');
                return res.messge;
            };
            if (res.error === 'Unauthenticated.') {
                localStorage.removeItem('l_555_a');
                return res.error;
            };
            isShowToast && errorToaster(res?.message || 'Something wrong!', 1);
            return {
                con: false,
                ...res
            };
        };
    }
    catch (err) {
        return err.message
    }
};
export const deleteMethod = async (api, data, token = "", isShowToast = true) => {
    try {
        const fetchApi = await fetch(api, {
            method: 'DELETE',
            headers: {
                "accept": "multipart/form-data",
                "Authorization": `Bearer ${token}`,
            },
            body: data
        });
        const res = await fetchApi.json();
        if (res.status === 'success' || res.success) {
            return {
                con: true,
                ...res
            };
        } else {
            if (res.message === 'Unauthenticated.') {
                localStorage.removeItem('l_555_a');
                return res.messge;
            };
            if (res.error === 'Unauthenticated.') {
                localStorage.removeItem('l_555_a');
                return res.error;
            };
            isShowToast && errorToaster(res?.message || 'Something wrong!', 1);
            return {
                con: false,
                ...res
            };
        };
    }
    catch (err) {
        return err.message
    }
};

import { useRef } from "react";
import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import Input from "../../utils/Input";
import Select from "../../utils/Select";
import Button from "../../utils/Button";
import classes from "../../assets/styles/WithdrawForm.module.css";
import { getMethod, postMethod } from "../../services";
import { PAYMENT_PROVIDER_API, USER_WITHDRAW_API } from "../../services/constant";
import Loader from "../../utils/Loader";
import { useNavigate } from "react-router-dom";
import { AiFillDollarCircle } from "react-icons/ai";
import { FaRegMoneyBillAlt } from "react-icons/fa";
import { FiLock, FiTablet, FiUser } from "react-icons/fi";
import { useSelector } from "react-redux";
import { langChange } from "../../lang";
import { errorToaster, successToaster } from "../../utils/Helper";
import { ToastContainer } from "react-toastify";

const WithdrawForm = () => {
  //    ------ data -------
  const [loading, setLoading] = useState(false);
  const [payments, setPayments] = useState([]);
  const [amount, setAmount] = useState("");
  const [paymentType, setPaymentType] = useState("");
  const [paymentAccno, setPaymentAccno] = useState("");
  const [password, setPassword] = useState("");
  const [remark, setRemark] = useState("");
  const [minWithdraw, setMinWithdraw] = useState("");
  const [maxWithdraw, setMaxWithdraw] = useState("");

  //    ------ errors -------
  const [errorAmount, setErrorAmount] = useState("");
  const [errorPaymentType, setErrorPaymentType] = useState("");
  const [errorPaymentAccno, setErrorPaymentAccno] = useState("");
  const [errorRemark, setErrorRemark] = useState("");
  const navigate = useNavigate();
  const [errorPassword, setErrorPassword] = useState("");
  const taostId = useRef(null);

  const fetchPaymentProviders = async () => {
    const token = localStorage.getItem("l_555_a");
    if (token) {
      const deToken = JSON.parse(token);
      const res = await getMethod(
        `${PAYMENT_PROVIDER_API}`,
        deToken.token
      );
      if (res) {
        setLoading(false);
        if (res === "Failed to fetch") {
          setLoading(false);
          errorToaster("Please check internet connection!", taostId);
        }
        if (res.con) {
          setPayments(res.data);
        }
      }
    }
  };

  useEffect(() => {
    fetchPaymentProviders();
  }, []);

  //   withdraw status handler
  const { pocketStatusData } = useSelector((state) => state.pocketStatusSlice);
  useEffect(() => {
    !pocketStatusData.deposit_status && navigate("/pocket");
    setMinWithdraw(pocketStatusData.withdrawal_min);
    setMaxWithdraw(pocketStatusData.withdrawal_max);
  }, [pocketStatusData]);
  const withdrawSubmitHandler = async (e) => {
    e.preventDefault();
    if (amount) {
      if (Number(amount) >= minWithdraw && Number(amount) <= maxWithdraw) {
        if (paymentType) {
          if (paymentAccno) {
            if (paymentAccno.length >= 9 && paymentAccno.length <= 20) {
              if (remark) {
                if (password) {
                  setLoading(true);
                  const data = {
                    amount,
                    payment_provider_id: paymentType,
                    phone: paymentAccno,
                    password,
                    remark,
                  };
                  const token = localStorage.getItem("l_555_a");
                  if (token) {
                    const deToken = JSON.parse(token);
                    const res = await postMethod(
                      USER_WITHDRAW_API,
                      data,
                      deToken.token
                    );
                    if (res) {
                      setLoading(false);
                      if (res === "Failed to fetch") {
                        errorToaster(
                          "Please check internet connection!",
                          taostId
                        );
                      }
                      if (res.con) {
                        successToaster("Withdraw successful!", taostId);
                        setTimeout(() => {
                          navigate("/");
                        }, 1500);
                      } else {
                        if (res.data.error) {
                          errorToaster(res.data.error, taostId);
                        }
                        if (res.data.remark) {
                          errorToaster(res.data.remark, taostId);
                        }
                      }
                    }
                  }
                } else {
                  setErrorPassword("Password is required!");
                }
              } else {
                setErrorRemark("Bank Acc Name is required!");
              }
            } else {
              setErrorPaymentAccno(
                "Payment account no is minium is 9 and maximum is 20"
              );
            }
          } else {
            setErrorPaymentAccno("Payment account no is required!");
          }
        } else {
          setErrorPaymentType("Payment is required!");
        }
      } else {
        setErrorAmount(
          `Withdraw amount must be minimum ${minWithdraw} & maximum ${maxWithdraw}`
        );
      }
    } else {
      setErrorAmount("Amount is required!");
    }
  };

  return (
    <>
      <div className={classes["auth-card-body"]}>
        <div>
          <form onSubmit={withdrawSubmitHandler}>
            <Input
              icon={<AiFillDollarCircle />}
              label={langChange.amount}
              type="number"
              value={amount}
              placeholder={langChange.enter_amount}
              event={(e) => {
                setErrorAmount("");
                setAmount(e.target.value);
              }}
              error={errorAmount}
            />
            <Select
              icon={<FaRegMoneyBillAlt />}
              label={langChange.withdraw_account}
              event={(e) => {
                setPaymentType(e.target.value);
                setErrorPaymentType("");
              }}
              error={errorPaymentType}
            >
              <option value="#">{langChange.select_withdraw_account}</option>
              {payments.length > 0 &&
                payments.map((payment, i) => (
                  <option key={i} value={payment.id}>
                    {payment.name}
                  </option>
                ))}
            </Select>
            <Input
              icon={<FiTablet />}
              label={langChange.bank_acc_no_or_phone_no}
              type="number"
              value={paymentAccno}
              placeholder={langChange.enter_bank_acc_no_or_phone_no}
              event={(e) => {
                setErrorPaymentAccno("");
                setPaymentAccno(e.target.value);
              }}
              error={errorPaymentAccno}
            />
            <Input
              icon={<FiUser />}
              label={langChange.bank_acc_name}
              type="text"
              value={remark}
              placeholder={langChange.enter_bank_acc_name}
              event={(e) => {
                setErrorRemark("");
                setRemark(e.target.value);
              }}
              error={errorRemark}
            />
            <Input
              icon={<FiLock />}
              label={langChange.password}
              type="password"
              value={password}
              placeholder={langChange.enter_password}
              event={(e) => {
                setErrorPassword("");
                if (e.target.value.length > 6) {
                  return;
                }
                setPassword(e.target.value);
              }}
              error={errorPassword}
            />
            <div className={classes["btn-position"]}>
              <Button
                label={langChange.confirm}
                type="submit"
                bgColor="#6b67eb"
                bgHover="#333258"
              />
            </div>
          </form>
        </div>
      </div>
      {ReactDOM.createPortal(
        loading && <Loader />,
        document.getElementById("portal")
      )}
      <ToastContainer limit={1} />
    </>
  );
};

export default WithdrawForm;

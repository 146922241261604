import React, { useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import { getMethod } from "../../services";
import { WITHDRAW_HISTORY_API } from "../../services/constant";
import Loader from "../../utils/Loader";
import NoData from "../../utils/NoData";
import WithdrawHistoryCard from "./WithdrawHistoryCard";
import { errorToaster } from "../../utils/Helper";
import { ToastContainer } from "react-toastify";

const WithdrawHistory = () => {
  const [loading, setLoading] = useState(true);
  const [history, setHistory] = useState([]);
  const toastId = useRef(null);

  const fetchHistory = async () => {
    const token = localStorage.getItem("l_555_a");
    if (token) {
      const deToken = JSON.parse(token);
      const res = await getMethod(
        `${WITHDRAW_HISTORY_API}?user_id=${deToken.user_id}`,
        deToken.token
      );
      if (res) {
        setLoading(false);
        if (res === "Failed to fetch") {
          errorToaster("Please check internet connection!", toastId);
        }
        if (res.con) {
          setHistory(res.data);
        }
      }
    }
  };

  useEffect(() => {
    fetchHistory();
  }, []);

  return (
    <div className="mt-4 h-[70vh] overflow-auto">
      {history.length > 0 ? (
        history.map((hist) => <WithdrawHistoryCard data={hist} key={hist.id} />)
      ) : (
        <NoData loading={loading} />
      )}
      {ReactDOM.createPortal(
        loading && <Loader />,
        document.getElementById("portal")
      )}
      <ToastContainer limit={1} />
    </div>
  );
};
export default WithdrawHistory;

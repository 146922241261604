import React, { useState } from "react";
import ReactDOM from "react-dom";
import Layout from "../utils/Layout";
import Loader from "../utils/Loader";
import thai2d from "../assets/images/thai2d.png";
import thai3d from "../assets/images/thai3d.png";
import gold2d from "../assets/images/gold2d.png";
import gold3d from "../assets/images/gold3d.png";
import hero2d from "../assets/images/hero2d.png";
import football from "../assets/images/football.png";
import gameplay from "../assets/images/game_play.png";
import shankomee from "../assets/images/shankomee.png";
import frame from "../assets/images/frame.png";
import { ToastContainer } from "react-toastify";
import { FiArrowRight } from "react-icons/fi";
import { useNavigate } from "react-router-dom";

const ProviderList = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const gameOneHandler = () => {
    const token = JSON.parse(localStorage.getItem("l_555_a"));
    if (token) {
      navigate('/shan-one')
      // window.open(GAME_ONE_API + '?token=' + token.token);
    } else {
      navigate('/login');
    }
  }
  const gameTwoHandler = () => {
    const token = JSON.parse(localStorage.getItem("l_555_a"));
    if (token) {
      // window.open(GAME_TWO_API + '?token=' + token.token);
      navigate('/shan-two')
    } else {
      navigate('/login');
    }
  }

  return (
    <>
      <Layout type="" name="">
        <div className="w-[95%] mx-auto mt-3">
          
          <div className="max-w-[500px] mx-auto grid grid-flow-row grid-cols-2 gap-3 mt-3">
          <div className="p-2">
              <img
                onClick={gameOneHandler}
                className="w-full cursor-pointer rounded-lg border border-white"
                src={gameplay}
                alt="Game Play"
              />
            </div>
            <div className="p-2">
              <img
                onClick={gameTwoHandler}
                className="w-full cursor-pointer rounded-lg border border-white"
                src={shankomee}
                alt="Shankomeee"
              />
            </div>
            <img className="w-full cursor-pointer" onClick={() => navigate('/all/bet/2d')} src={thai2d} alt="Thai 2D" />
            <img className="w-full cursor-pointer" onClick={() => navigate('/all/bet/3d')} src={thai3d} alt="Thai 3D" />
            <img className="w-full cursor-pointer" onClick={() => navigate('/all/bet/gold2d')} src={gold2d} alt="Gold 2D" />
            <img className="w-full cursor-pointer" onClick={() => navigate('/all/bet/gold3d')} src={gold3d} alt="Gold 3D" />
            <img className="w-full cursor-pointer" onClick={() => navigate('/all/bet/hero2d')} src={hero2d} alt="Hero 2D" />
            <img
              className="w-full cursor-pointer"
              src={football}
              alt="Football"
            />
            
          </div>
          <div className="max-w-[720px] mx-auto grid grid-flow-row grid-cols-1 md:grid-cols-2 gap-3 mt-3">
            <div className="w-full relative cursor-pointer" onClick={() => navigate('/all/bet/2d/result-history')}>
              <img className="w-full" src={frame} alt="Frame" />
              <div className="flex justify-around gap-3 items-center absolute top-[50%] translate-y-[-50%] left-[50%] translate-x-[-50%]">
                <div className="w-10 h-10 bg-indigo-600 flex justify-center items-center font-bold rounded-full">
                  2D
                </div>
                <p className="whitespace-nowrap text-white stroke-current font-semibold text-lg">
                  နှစ်လုံးထီကြည့်ရန်
                </p>
                <FiArrowRight className="text-2xl" />
              </div>
            </div>
            <div className="w-full relative cursor-pointer" onClick={() => navigate("/all/bet/3d/result-history")}>
              <img className="w-full" src={frame} alt="Frame" />
              <div className="flex justify-around gap-3 items-center absolute top-[50%] translate-y-[-50%] left-[50%] translate-x-[-50%]">
                <div className="w-10 h-10 bg-indigo-600 flex justify-center items-center font-bold rounded-full">
                  3D
                </div>
                <p className="whitespace-nowrap text-white stroke-current font-semibold text-lg">
                  သုံးလုံးထီကြည့်ရန်
                </p>
                <FiArrowRight className="text-2xl" />
              </div>
            </div>
          </div>
        </div>
      </Layout>
      {ReactDOM.createPortal(
        loading && <Loader />,
        document.getElementById("portal")
      )}
      <ToastContainer limit={1} />
    </>
  );
};

export default ProviderList;

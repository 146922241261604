// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Footer_nav__nRQw2 {
  margin: auto;
  position: sticky;
  bottom: 0;
  z-index: 50;
  overflow: hidden;
  border-top: 1px solid var(--light-color);
}
.Footer_nav__nRQw2 ul li {
  font-size: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.Footer_nav__nRQw2 ul li.Footer_active__tz7qk a {
  color: var(--accent-color);
}
.Footer_nav__nRQw2 ul li a {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.Footer_nav__nRQw2 ul li span {
  font-size: 12px;
  display: block;
  margin-top: 7px;
}
`, "",{"version":3,"sources":["webpack://./src/assets/styles/Footer.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,gBAAgB;EAChB,SAAS;EACT,WAAW;EACX,gBAAgB;EAChB,wCAAwC;AAC1C;AACA;EACE,eAAe;EACf,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB;AACA;EACE,0BAA0B;AAC5B;AACA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;AACzB;AACA;EACE,eAAe;EACf,cAAc;EACd,eAAe;AACjB","sourcesContent":[".nav {\n  margin: auto;\n  position: sticky;\n  bottom: 0;\n  z-index: 50;\n  overflow: hidden;\n  border-top: 1px solid var(--light-color);\n}\n.nav ul li {\n  font-size: 22px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n.nav ul li.active a {\n  color: var(--accent-color);\n}\n.nav ul li a {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n}\n.nav ul li span {\n  font-size: 12px;\n  display: block;\n  margin-top: 7px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"nav": `Footer_nav__nRQw2`,
	"active": `Footer_active__tz7qk`
};
export default ___CSS_LOADER_EXPORT___;

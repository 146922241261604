import LocalizedStrings from 'react-localization';

export const langChange = new LocalizedStrings({
    unicode:{
        login: "အကောင့်ဝင်ပါ",
        register: "အကောင့်သစ်ဖွင့်ပါ",
        home:"ပင်မ",
        contact: "ဆက်သွယ်ရန်",
        deposit: "ငွေဖြည့်မည်",
        withdraw: "ငွေထုတ်မည်",
        confirm: "အတည်ပြုပါ",
        logout: "ထွက်မည်",
        password: "စကားဝှက်",
        pocket: 'ပိုက်ဆံအိတ်',
        profile: 'ကျွန်ုပ်',
        main_pocket: 'ပင်မပိုက်ဆံအိတ်',
        game_pocket: 'ဂိမ်းပိုက်ဆံအိတ်',
        kyat: 'ကျပ်',
        deposit_and_withdraw_condition: 'ငွေသွင်း/ ငွေထုတ် အခြေအနေ',
        how_to_deposit: 'ငွေဖြည့်နည်း ကြည့်ရန် နှိပ်ပါ။',
        how_to_withdraw: 'ငွေထုတ်နည်း ကြည့်ရန် နှိပ်ပါ။',
        deposit_and_withdraw_time: 'ငွေသွင်း/ ငွေထုတ် ဝန်ဆောင်မှုအချိန်',
        service_time: 'နေ့စဉ်(၂၄) နာရီ ၊ ပိတ်ရက်မရှိ ဝန်ဆောင်ပေးနေပါသည်။',
        transfer_to_main_pocket: 'ပင်မအကောင့်သို့ ငွေလွှဲခြင်း',
        transfer_to_game_pocket: 'ဂိမ်းအကောင့်သို့ ငွေလွှဲခြင်း',
        transfer_amount: 'ငွေပမာဏ',
        football: 'ဘောလုံး',
        slots_games: 'စလော့ဂိမ်းများ',
        live_casino_and_other_games: 'လိုက်ကာစီနိုနှင့် အခြားဂိမ်းများ',
        transfer: 'လွှဲပြောင်းမည်။',
        ok: 'ကောင်းပြီ',
        copy: 'ကူးယူမည်',
        next: 'ရှေ့ဆက်ရန်',
        transaction_last_six_number: 'လုပ်ဆောင်မှုအမှတ် နောက်ဆုံးဂဏန်း ၆ လုံး',
        last_six_number: 'နောက်ဆုံးဂဏန်း ၆ လုံး',
        note: 'မှတ်ချက်',
        please_transfer_only_this_account: 'ကျေးဇူးပြု၍ ဖော်ပြပါအကောင့်ကိုသာ ငွေလွှဲပါ။',
        enter_amount: 'ငွေပမာဏဖြည့်ပါ။',
        withdraw_account: 'ငွေထုတ်အကောင့်',
        select_withdraw_account: 'ငွေထုတ်အကောင့်ရွေးပါ။',
        bank_acc_no_or_phone_no: 'ဘဏ်အကောင့်နံပါတ် (သို့) ဖုန်းနံပါတ်',
        enter_bank_acc_no_or_phone_no: 'ဘဏ်အကောင့်နံပါတ် (သို့) ဖုန်းနံပါတ်ဖြည့်ပါ။',
        remark: 'မှတ်ချက်',
        enter_remark: 'မှတ်ချက်ရေးပါ',
        enter_password: 'စကားဝှက်ဖြည့်ပါ',
        recommendation_letter_and_help: 'အကြံပြုစာ',
        bet: 'ထိုးမည်။',
        choose_fast: 'အမြန်ရွေး',
        remove: 'ဖျက်မည်',
        number: 'ထိုးဂဏန်း',
        time: 'အချိန်',
        sr: 'စဉ်',
        select_lottery_time: 'ထီထိုးမည့်အချိန် ရွေးချယ်ပါ။',
        winner: 'ထီပေါက်သူ',
        lottery_history: 'ထီမှတ်တမ်း',
        date: 'ရက်စွဲ',
        morning: 'မနက်',
        evening: 'ညနေ',
        enter_otp_from_sms: 'OTP ကုဒ်ထည့်သွင်းပါ။',
        phone: 'ဖုန်းနံပါတ်',
        forgot_password: 'စကားဝှက်မေ့နေလား?',
        create_new_account: 'အကောင့်အသစ်ဖွင့်မည်။',
        already_have_an_account: 'အကောင့်ရှိပြီးသားလား? အကောင့်ဝင်မည်။',
        account_name: 'အကောင့်နာမည်',
        enter_account_name: 'အကောင့်နာမည်ထည့်ပါ။',
        get_otp_sms: 'SMS OTP ကုဒ်ရယူရန်',
        old_password: 'စကားဝှက်အဟောင်း',
        enter_old_password: 'စကားဝှက်အဟောင်းထည့်ပါ။',
        new_password: 'စကားဝှက်အသစ်',
        enter_new_password: 'စကားဝှက်အသစ် ၆ လုံးထည့်ပါ။',
        confirm_password: 'အတည်ပြုစကားဝှက်',
        enter_confirm_password: 'အတည်ပြုစကားဝှက်ထည့်ပါ။',
        login_first: 'အကောင့်အရင်ဝင်ပါ။',
        register_first: 'အကောင့်အရင်ဖွင့်ပါ။',
        let_s_advice: 'App သုံးရတာပိုကောင်းဖို့၊ အကြံပေးကြပါစို့။',
        upload_related_photo: 'သက်ဆိုင်ရာဓာတ်ပုံတင်ရန်',
        go_back: 'နောက်သို့',
        myanmar: 'မြန်မာ',
        loading: 'ခဏစောင့်ပါ။',
        history: 'မှတ်တမ်း',
        amount: 'ငွေပမာဏ',
        transaction_no: 'လုပ်ငန်းစဉ်နံပါတ်',
        status: 'အခြေအနေ',
        payment: 'ငွေပေးခြေမှုစနစ်',
        change_password: 'စကားဝှက်ပြောင်းမည်။',
        promotion: 'ပရိုမိုးရှင်း',
        apply: 'ရယူမည်',
        applied: 'ရယူပြီးပါပြီ',
        more_info: 'အသေးစိတ်',
        from: 'မှ',
        to: 'အထိ',
        later: 'နောက်မှ',
        notification: 'အသိပေးချက်',
        notifications: 'အသိပေးချက်များ',
        no_notifications: 'အသိပေးချက်မရှိသေးပါ။',
        min_max_number: 'ဂဏန်း(၃)လုံးမှ (၄)လုံးထိ',
        min_100: 'အနည်းဆုံး ၁၀၀',
        apu_number: 'အပူးပါ',
        no_apu_number: 'အပူးမပါ',
        send: 'ပေးပို့မည်',
        enter_number: 'ထိုးဂဏန်းဖြည့်ပါ။',
        add_new_2d_number: '2D ဂဏန်းထပ်ထည့်မည်',
        add_new_3d_number: '3D ဂဏန်းထပ်ထည့်မည်',
        add: 'ထပ်ထည့်မည်',
        full: 'ပေါက်ကွက်',
        twit: 'တွတ်',
        name: 'အမည်',
        bet_amount: 'ထိုးငွေ',
        total: 'စုစုပေါင်း',
        lucky_number: 'ပေါက်ဂဏန်း',
        no_data_found: 'မှတ်တမ်း မရှိသေးပါ',
        lottery_date: 'ရက်နေ့',
        total_bet_amount: 'စုစုပေါင်းထိုးငွေ',
        bank_acc_name: 'ဘဏ်အကောင့်အမည်',
        enter_bank_acc_name: 'ဘဏ်အကောင့်အမည်ထည့်ပါ',
        balance: 'လက်ကျန်ငွေ',
        search_game: 'ဂိမ်းရှာမည်',
        wallet: 'ဂိမ်းပိုက်ဆံအိတ်',
        game_wallet: 'ဂိမ်းပိုက်ဆံ',
        withdraw_type: 'ငွေထုတ်',
        deposit_type: 'ငွေသွင်း',
        rule_and_regulation: 'စည်းကမ်းသတ်မှတ်ချက်များ',
        bet_approved: 'ထိုးပြီး',
        deposit_history: 'ငွေသွင်းမှတ်တမ်း',
        withdraw_history: 'ငွေထုတ်မှတ်တမ်း',
        user_code: 'အသုံးပြုသူကုဒ်',
        profile_detail: 'ပရိုဖိုင် အသေးစိတ်',
        add_number: 'ဂဏန်းထည့်မည်။',
        prizes : 'ဆုကြေးများ',
        baht: 'ဘတ်',
        first_prize: 'ပထမဆု',
        second_prize: 'ဒုတိယဆု',
        third_prize: 'တတိယဆု',
        fourth_prize: 'စတုတ္ထဆု',
        fifth_prize: 'ပဉ္စမဆု',
        first_three_number: 'ရှေ့ဂဏန်း ၃ လုံးတူ',
        last_three_number: 'နောက်ဂဏန်း ၃ လုံးတူ',
        last_two_number: 'နောက်ဂဏန်း ၂ လုံးတူ',
        up_prize: 'အပေါ်ဆု',
        down_prize: 'အောက်ဆု',
        enter_user_name: 'အသုံးပြုသူ အမည်ထည့်ပါ။',
        up_down_prize: 'အပေါ် / အောက် ဆု',
        first_last_three_number_same: 'ရှေ့ / နောက် ၃ လုံးတူ',
        special_prize: 'အထူးဆု',
        consolation_prize: 'နှစ်သိမ့်ဆု',
        lottery_result: 'ထီပေါက်စဉ်',
        voucher: 'စောင်',
        vip_voucher: 'စောင်တွဲ',
        user_lottery_win: 'သင့်ထီပေါက်စာရင်း',
        referral_code: 'ရည်ညွှန်းကုဒ်',
        all: 'အားလုံး',
        card: 'ကဒ်',
        lottery: 'ထီ',
        other: 'အခြား',
        slots: 'စလော့',
        live: 'လိုက်ဖ်',
        fish: 'ငါးပစ်',
        sports: 'အားကစား',
        otp_code: 'OTP ကုဒ်',
    },
    zawgyi:{
        login: "အေကာင့္ဝင္ပါ",
        register: "အေကာင့္သစ္ဖြင့္ပါ",
        home:"ပင္မစာမ်က္ႏွာ",
        contact: "ဆက္သြယ္ရန္",
        deposit: "ေငြျဖည့္မည္",
        withdraw: "ေငြထုတ္မည္",
        confirm: "အတည္ျပဳပါ",
        logout: "ထြက္မည္",
        password: "စကားဝွက္",
        pocket: 'ပိုက္ဆံအိတ္',
        profile: 'ကြၽႏ္ုပ္',
        main_pocket: 'ပင္မပိုက္ဆံအိတ္',
        game_pocket: 'ဂိမ္းပိုက္ဆံအိတ္',
        kyat: 'က်ပ္',
        deposit_and_withdraw_condition: 'ေငြသြင္း/ ေငြထုတ္ အေျခအေန',
        how_to_deposit: 'ေငြျဖည့္နည္း ၾကည့္ရန္ ႏွိပ္ပါ။',
        how_to_withdraw: 'ေငြထုတ္နည္း ၾကည့္ရန္ ႏွိပ္ပါ။',
        deposit_and_withdraw_time: 'ေငြသြင္း/ ေငြထုတ္ ဝန္ေဆာင္မႈအခ်ိန္',
        service_time: 'ေန႔စဥ္(၂၄) နာရီ ၊ ပိတ္ရက္မရွိ ဝန္ေဆာင္ေပးေနပါသည္။',
        transfer_to_main_pocket: 'ပင္မအေကာင့္သို႔ ေငြလႊဲျခင္း',
        transfer_to_game_pocket: 'ဂိမ္းအေကာင့္သို႔ ေငြလႊဲျခင္း',
        transfer_amount: 'ေငြပမာဏ',
        football: 'ေဘာလုံး',
        slots_games: 'စေလာ့ဂိမ္းမ်ား',
        live_casino_and_other_games: 'လိုက္ကာစီႏိုႏွင့္ အျခားဂိမ္းမ်ား',
        transfer: 'လႊဲေျပာင္းမည္။',
        ok: 'ေကာင္းၿပီ',
        copy: 'ကူးယူမည္',
        next: 'ေရွ႕ဆက္ရန္',
        transaction_last_six_number: 'လုပ္ေဆာင္မႈအမွတ္ ေနာက္ဆုံးဂဏန္း ၆ လုံး',
        last_six_number: 'ေနာက္ဆုံးဂဏန္း ၆ လုံး',
        note: 'မွတ္ခ်က္',
        please_transfer_only_this_account: 'ေက်းဇူးျပဳ၍ ေဖာ္ျပပါအေကာင့္ကိုသာ ေငြလႊဲပါ။',
        enter_amount: 'ေငြပမာဏျဖည့္ပါ။',
        withdraw_account: 'ေငြထုတ္အေကာင့္',
        select_withdraw_account: 'ေငြထုတ္အေကာင့္ေ႐ြးပါ။',
        bank_acc_no_or_phone_no: 'ဘဏ္အေကာင့္နံပါတ္ (သို႔) ဖုန္းနံပါတ္',
        enter_bank_acc_no_or_phone_no: 'ဘဏ္အေကာင့္နံပါတ္ (သို႔) ဖုန္းနံပါတ္ျဖည့္ပါ။',
        remark: 'မွတ္ခ်က္',
        enter_remark: 'မွတ္ခ်က္ေရးပါ',
        enter_password: 'စကားဝွက္ျဖည့္ပါ',
        recommendation_letter_and_help: 'အႀကံျပဳစာ',
        bet: 'ထိုးမည္',
        choose_fast: 'အျမန္ေ႐ြး',
        remove: 'ဖ်က္မည္',
        number: 'ထိုးဂဏန္း',
        time: 'အခ်ိန္',
        sr: 'စဥ္',
        select_lottery_time: 'ထီထိုးမည့္အခ်ိန္ ေ႐ြးခ်ယ္ပါ။',
        winner: 'ထီေပါက္သူ',
        lottery_history: 'ထီမွတ္တမ္း',
        date: 'ရက္စြဲ',
        morning: 'မနက္',
        evening: 'ညေန',
        enter_otp_from_sms: 'OTP ကုဒ္ထည့္သြင္းပါ။',
        phone: 'ဖုန္းနံပါတ္',
        forgot_password: 'စကားဝွက္ေမ့ေနလား?',
        create_new_account: 'အေကာင့္အသစ္ဖြင့္မည္။',
        already_have_an_account: 'အေကာင့္ရွိၿပီးသားလား? အေကာင့္ဝင္မည္။',
        account_name: 'အေကာင့္နာမည္',
        enter_account_name: 'အေကာင့္နာမည္ထည့္ပါ။',
        get_otp_sms: 'SMS OTP ကုဒ္ရယူရန္',
        old_password: 'စကားဝွက္အေဟာင္း',
        enter_old_password: 'စကားဝွက္အေဟာင္းထည့္ပါ။',
        new_password: 'စကားဝွက္အသစ္',
        enter_new_password: 'စကားဝွက္အသစ္ ၆ လုံးထည့္ပါ။',
        confirm_password: 'အတည္ျပဳစကားဝွက္',
        enter_confirm_password: 'အတည္ျပဳစကားဝွက္ထည့္ပါ။',
        login_first: 'အေကာင့္အရင္ဝင္ပါ။',
        register_first: 'အေကာင့္အရင္ဖြင့္ပါ။',
        let_s_advice: 'App သုံးရတာပိုေကာင္းဖို႔၊ အႀကံေပးၾကပါစို႔။',
        upload_related_photo: 'သက္ဆိုင္ရာဓာတ္ပုံတင္ရန္',
        go_back: 'ေနာက္သို႔',
        myanmar: 'ျမန္မာ',
        loading: 'ခဏေစာင့္ပါ။',
        history: 'မွတ္တမ္း',
        amount: 'ေငြပမာဏ',
        transaction_no: 'လုပ္ငန္းစဥ္နံပါတ္',
        status: 'အေျခအေန',
        payment: 'ေငြေပးေျခမႈစနစ္',
        change_password: 'စကားဝွက္ေျပာင္းမည္။',
        promotion: 'ပ႐ိုမိုးရွင္း',
        apply: 'ရယူမည္',
        applied: 'ရယူၿပီးပါၿပီ',
        more_info: 'အေသးစိတ္',
        from: 'မွ',
        to: 'အထိ',
        later: 'ေနာက္မွ',
        notification: 'အသိေပးခ်က္',
        notifications: 'အသိေပးခ်က္မ်ား',
        no_notifications: 'အသိေပးခ်က္မရွိေသးပါ။',
        min_max_number: 'ဂဏန္း(၃)လုံးမွ (၄)လုံးထိ',
        min_100: 'အနည္းဆုံး ၁၀၀',
        apu_number: 'အပူးပါ',
        no_apu_number: 'အပူးမပါ',
        send: 'ေပးပို႔မည္',
        enter_number: 'ထိုးဂဏန္းျဖည့္ပါ။',
        add_new_2d_number: '2D ဂဏန္းထပ္ထည့္မည္',
        add_new_3d_number: '3D ဂဏန္းထပ္ထည့္မည္',
        add: 'ထပ္ထည့္မည္',
        full: 'ေပါက္ကြက္',
        twit: 'တြတ္',
        name: 'အမည္',
        bet_amount: 'ထိုးေငြ',
        total: 'စုစုေပါင္း',
        lucky_number: 'ေပါက္ဂဏန္း',
        no_data_found: 'မွတ္တမ္း မရွိေသးပါ',
        lottery_date: 'ရက္ေန႔',
        total_bet_amount: 'စုစုေပါင္းထိုးေငြ',
        bank_acc_name: 'ဘဏ္အေကာင့္အမည္',
        enter_bank_acc_name: 'ဘဏ္အေကာင့္အမည္ထည့္ပါ',
        balance: 'လက္က်န္ေငြ',
        search_game: 'ဂိမ္းရွာမည္',
        wallet: 'ဂိမ္းပိုက္ဆံအိတ္',
        game_wallet: 'ဂိမ္းပိုက္ဆံ',
        withdraw_type: 'ေငြထုတ္',
        deposit_type: 'ေငြသြင္း',
        rule_and_regulation: 'စည္းကမ္းသတ္မွတ္ခ်က္မ်ား',
        bet_approved: 'ထိုးၿပီး',
        deposit_history: 'ေငြသြင္းမွတ္တမ္း',
        withdraw_history: 'ေငြထုတ္မွတ္တမ္း',
        user_code: 'အသုံးျပဳသူကုဒ္',
        profile_detail: 'ပ႐ိုဖိုင္ အေသးစိတ္',
        add_number: 'ဂဏန္းထည့္မည္။',
        prizes : 'ဆုေၾကးမ်ား',
        baht: 'ဘတ္',
        first_prize: 'ပထမဆု',
        second_prize: 'ဒုတိယဆု',
        third_prize: 'တတိယဆု',
        fourth_prize: 'စတုတၳဆု',
        fifth_prize: 'ပၪၥမဆု',
        first_three_number: 'ေရွ႕ဂဏန္း ၃ လုံးတူ',
        last_three_number: 'ေနာက္ဂဏန္း ၃ လုံးတူ',
        last_two_number: 'ေနာက္ဂဏန္း ၂ လုံးတူ',
        up_prize: 'အေပၚဆု',
        down_prize: 'ေအာက္ဆု',
        enter_user_name: 'အသုံးျပဳသူ အမည္ထည့္ပါ။',
        up_down_prize: 'အေပၚ / ေအာက္ ဆု',
        first_last_three_number_same: 'ေရွ႕ / ေနာက္ ၃ လုံးတူ',
        special_prize: 'အထူးဆု',
        consolation_prize: 'ႏွစ္သိမ့္ဆု',
        lottery_result: 'ထီေပါက္စဥ္',
        voucher: 'ေစာင္',
        vip_voucher: 'ေစာင္တြဲ',
        user_lottery_win: 'သင့္ထီေပါက္စာရင္း',
        referral_code: 'ရည္ၫႊန္းကုဒ္',
        all: 'အားလုံး',
        card: 'ကဒ္',
        lottery: 'ထီ',
        other: 'အျခား',
        slots: 'စေလာ့',
        live: 'လိုက္ဖ္',
        fish: 'ငါးပစ္',
        sports: 'အားကစား',
        otp_code: 'OTP ကုဒ္',
    },

    english:{
        login: "Login",
        register: "Register",
        home:"Home",
        contact: "Contact",
        deposit: "Deposit",
        withdraw: "Withdraw",
        confirm: "Confirm",
        logout: "Logout",
        password: "Password",
        pocket: 'Pocket',
        profile: 'Profile',
        main_pocket: 'Main Pocket',
        game_pocket: 'Game Pocket',
        kyat: 'Kyats',
        deposit_and_withdraw_condition: 'Deposit and Withdraw Condition',
        how_to_deposit: 'How to make deposit?',
        how_to_withdraw: 'How to make withdraw?',
        deposit_and_withdraw_time: 'Deposit and Withdraw Service Time',
        service_time: 'Daily 24 Hours, Non-stop service.',
        transfer_to_main_pocket: 'Transfer to Main Pocket',
        transfer_to_game_pocket: 'Transfer to Game Pocket',
        transfer_amount: 'Transfer Amount',
        football: 'Football',
        slots_games: 'Slots Games',
        live_casino_and_other_games: 'Live Casino and Other Games',
        transfer: 'Transfer',
        ok: 'Ok',
        copy: 'Copy',
        next: 'Next',
        transaction_last_six_number: 'Last transaction six number',
        last_six_number: 'last six number',
        note: 'Note',
        please_transfer_only_this_account: 'Please transfer only this account.',
        enter_amount: 'Enter Amount',
        withdraw_account: 'Withdraw Account',
        select_withdraw_account: 'Select Withdraw Account',
        bank_acc_no_or_phone_no: 'Bank Account No. or Phone No.',
        enter_bank_acc_no_or_phone_no: 'Enter Bank Account No. or Phone No.',
        remark: 'Remark',
        enter_remark: 'Enter Remark',
        enter_password: 'Enter Password',
        recommendation_letter_and_help: 'Recommendation Letter',
        bet: 'Bet',
        choose_fast: 'Choose Fast',
        remove: 'Remove',
        number: 'Number',
        time: 'Time',
        sr: 'No.',
        select_lottery_time: 'Select Lottery Time',
        winner: 'Winner',
        lottery_history: 'Lottery History',
        date: 'Date',
        morning: 'Morning',
        evening: 'Evening',
        enter_otp_from_sms: 'Enter OTP from your SMS!',
        phone: 'Phone',
        forgot_password: 'Forgot Password?',
        create_new_account: 'Create New Account',
        already_have_an_account: 'Already have an account? Login',
        account_name: 'Name',
        enter_account_name: 'Enter Account Name',
        get_otp_sms: 'GET SMS OTP',
        old_password: 'Old Password',
        enter_old_password: 'Enter Old Password',
        new_password: 'New Password',
        enter_new_password: 'Enter New Password 6 digits',
        confirm_password: 'Confirm Password',
        enter_confirm_password: 'Enter Confirm Password',
        login_first: 'Login',
        register_first: 'Register Now',
        let_s_advice: "For better performance, let's advise...",
        upload_related_photo: 'Upload related photos',
        go_back: 'Go Back',
        myanmar: 'မြန်မာ',
        loading: 'Loading',
        history: 'History',
        amount: 'Amount',
        transaction_no: 'Transaction No.',
        status: 'Status',
        payment: 'Payment',
        change_password: 'Change Password',
        promotion: 'Promotion',
        apply: 'Apply',
        applied: 'Applied',
        more_info: 'More Info',
        from: 'FROM',
        to: 'TO',
        later: 'Later',
        notification: 'Notification',
        notifications: 'Notifications',
        no_notifications: 'No Notifications',
        min_max_number: '( Min 3, Max 4 ) digits',
        min_100: 'Minimum 100',
        apu_number: 'အပူးပါ',
        no_apu_number: 'အပူးမပါ',
        send: 'Send',
        enter_number: 'Enter Number',
        add_new_2d_number: 'Add New 2D Number',
        add_new_3d_number: 'Add New 3D Number',
        add: 'Add',
        full: 'Full',
        twit: 'Twit',
        name: 'Name',
        bet_amount: 'Bet Amount',
        total: 'Total',
        lucky_number: 'Lucky Number',
        no_data_found: 'No Data Found!',
        lottery_date: 'ရက်နေ့',
        total_bet_amount: 'Total Bet Amount',
        bank_acc_name: 'Bank Acc Name',
        enter_bank_acc_name: 'Enter Bank Acc Name',
        balance: 'Balance',
        search_game: 'Search Game',
        wallet: 'Wallet',
        game_wallet: 'Game Wallet',
        withdraw_type: 'Withdraw',
        deposit_type: 'Deposit',
        rule_and_regulation: 'Rules and Regulations',
        bet_approved: 'Approved',
        deposit_history: 'Deposit History',
        withdraw_history: 'Withdraw History',
        user_code: 'User Code',
        profile_detail: 'Profile Detail',
        add_number: 'Add Number',
        prizes : 'Prizes',
        baht: '฿',
        first_prize: '1st Prize',
        second_prize: '2nd Prize',
        third_prize: '3rd Prize',
        fourth_prize: '4th Prize',
        fifth_prize: '5th Prize',
        first_three_number: '1st 3 Number Same',
        last_three_number: 'Last 3 Number Same',
        last_two_number: 'Last 2 Number Same',
        up_prize: 'Up Prize',
        down_prize: 'Down Prize',
        enter_user_name: "Enter User Name",
        up_down_prize: 'Up / Down Prize',
        first_last_three_number_same: 'First / Last 3 Number Same',
        special_prize: 'Special Prize',
        consolation_prize: 'Consolation Prize',
        lottery_result: 'Lottery Result',
        voucher: 'Voucher',
        vip_voucher: 'Voucher',
        user_lottery_win: 'User Lottery Win',
        referral_code: 'Referral Code', 
        all: 'All',
        card: 'CARD',
        lottery: 'LOTTERY',
        other: 'OTHER',
        slots: 'SLOTS',
        live: 'LIVE',
        fish: 'FISH',
        sports: 'SPORTS',
        otp_code: 'OTP Code',
    }
});
export const getHeroLotteryType = (time) => {
  let type;
  switch (time) {
    case "09:30 AM":
      type = 'One';
      break;
    case "12:00 PM":
      type = 'Two';
      break;
    case "02:00 PM":
      type = 'Three';
      break;
    case "04:30 PM":
      type = 'Four';
      break;
    case "07:00 PM":
      type = 'Five';
      break;
    case "09:00 PM":
      type = 'Six';
      break;
  }
  return type;
};

export const getHeroLotteryTime = (type) => {
  let time;
  switch (type) {
    case 'One':
      time = "09:30 AM";
      break;
    case 'Two':
      time = "12:00 PM";
      break;
    case 'Three':
      time = "02:00 PM";
      break;
    case 'Four':
      time = "04:30 PM";
      break;
    case 'Five':
      time = "07:00 PM";
      break;
    case 'Six':
      time = "09:00 PM";
      break;
  }
  return time;
};

const heroes = {
  1: 'AAMON',
  2: 'AKAI',
  3: 'ALDUS',
  4: 'ALICE',
  5: 'ATLAS',
  6: 'AULUS',
  7: 'BANDANG',
  8: 'BANE',
  9: 'BAXIA',
  10: 'BEATRIX',
  11: 'BRODY',
  12: 'BRUNO',
  13: 'CHOU',
  14: 'CLINT',
  15: 'DYRROTH',
  16: 'EDITH',
  17: 'FANNY',
  18: 'FRANCO',
  19: 'GLOO',
  20: 'GORD',
  21: 'GROCK',
  22: 'HANZO',
  23: 'HELCURT',
  24: 'HYLOS',
  25: 'IRITHEL',
  26: 'KAGURA',
  27: 'KAJA',
  28: 'KARINA',
  29: 'KIMMY',
  30: 'LAYLA',
  31: 'LESLEY',
  32: 'LING',
  33: 'LUNOX',
  34: 'LUOYI',
  35: 'LYLIA',
  36: 'MASHA',
  37: 'MINSITTHAR',
  38: 'MIYA',
  39: 'NANA',
  40: 'NATALIA',
  41: 'NATAN',
  42: 'ROGER',
  43: 'RUBY',
  44: 'SELENA',
  45: 'URANUS',
  46: 'VALE',
  47: 'VALIR',
  48: 'X.BORG',
  49: 'YIN',
  50: 'YVE',
};

export const getHeroName = (id) => {
  return heroes[id]
}
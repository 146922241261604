import React from "react";

export default function Container({ children }) {
  return (
    <div className="min-h-[85vh]">
      <div className="container mx-auto">
        <div className="w-[95%] max-w-[500px] mx-auto mt-3">{children}</div>
      </div>
    </div>
  );
}

import { createSlice } from "@reduxjs/toolkit";

const prizeSlice = createSlice({
    name: "prizes",
    initialState: {
        prizes: {},
    },
    reducers: {
        prizeHandler: (state, action) => {
        state.prizes = action.payload;
        },
    },
});

export const { prizeHandler } = prizeSlice.actions;
export default prizeSlice.reducer;

import React, { useEffect } from "react";
import Header from "../../layout/Header";
import Footer from "../../layout/Footer";
import classes from "../../assets/styles/TwodHistory.module.css";
import { useNavigate } from "react-router-dom";
import noteImg from "../../assets/images/icons/note.png";
import calendarImg from "../../assets/images/icons/calendar.png";
import { langChange } from "../../lang";
import { useSelector } from "react-redux";
import Back from "../../utils/Back";
import GoldBetHistry from "./GoldBetHistry";
import GoldResultHistory from "./GoldResultHistory";
import Container from "../../utils/Container";

const RoyalHistory = () => {
  const navigate = useNavigate();
  const url = window.location.pathname;

  const { lang } = useSelector((state) => state.lang);
  useEffect(() => {}, [lang]);
  return (
    <>
      <Header />
      <Back />
      <Container>
        <div className={classes["history-card-header"]}>
          <span
            className={`${
              (url === "/all/bet/gold2d/history" ||
                url === "/2d3d/bet/gold2d/history") &&
              classes.active
            }`}
            onClick={() => navigate("/all/bet/gold2d/history")}
          >
            <img src={noteImg} className="w-[25px] mr-2" />
            <p className="text-[13px] mb-0 mt-1">{langChange.history}</p>
          </span>
          <span
            className={`${
              (url === "/all/bet/gold2d/result-history" ||
                url === "/2d3d/bet/gold2d/result-history") &&
              classes.active
            }`}
            onClick={() => navigate("/all/bet/gold2d/result-history")}
          >
            <img src={calendarImg} className="w-[25px] mr-2" />
            <p className="text-[13px] mb-0 mt-1">
              {langChange.lottery_history}
            </p>
          </span>
        </div>
        {url === "/all/bet/gold2d/history" ? (
          <GoldBetHistry />
        ) : (
          <GoldResultHistory />
        )}
      </Container>
      <Footer />
    </>
  );
};

export default RoyalHistory;

export const getDubaiLotteryType = (time) => {
  let type;
  switch (time) {
    case "11:00 AM":
      type = "One";
      break;
    case "01:00 PM":
      type = "Two";
      break;
    case "03:00 PM":
      type = "Three";
      break;
    case "05:00 PM":
      type = "Four";
      break;
    case "07:00 PM":
      type = "Five";
      break;
    case "09:00 PM":
      type = "Six";
      break;
    default:
      break;
  }
  return type;
};

export const getDubaiLotteryTime = (type) => {
  let time;
  switch (type) {
    case "One":
      time = "11:00 AM";
      break;
    case "Two":
      time = "01:00 PM";
      break;
    case "Three":
      time = "03:00 PM";
      break;
    case "Four":
      time = "05:00 PM";
      break;
    case "Five":
      time = "07:00 PM";
      break;
    case "Six":
      time = "09:00 PM";
      break;
    default:
      break;
  }
  return time;
};

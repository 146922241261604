// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Button_btn__Ue5sI {
  font-size: 14px;
  border: none;
  outline: none;
  width: 100%;
  padding: 13px 50px;
  background-color: var(--main-color) !important;
  color: var(--text-color);
  text-align: center;
  line-height: 18px;
  cursor: pointer;
  margin: 0 auto;
  border-radius: 5px;
}
`, "",{"version":3,"sources":["webpack://./src/assets/styles/Button.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,YAAY;EACZ,aAAa;EACb,WAAW;EACX,kBAAkB;EAClB,8CAA8C;EAC9C,wBAAwB;EACxB,kBAAkB;EAClB,iBAAiB;EACjB,eAAe;EACf,cAAc;EACd,kBAAkB;AACpB","sourcesContent":[".btn {\n  font-size: 14px;\n  border: none;\n  outline: none;\n  width: 100%;\n  padding: 13px 50px;\n  background-color: var(--main-color) !important;\n  color: var(--text-color);\n  text-align: center;\n  line-height: 18px;\n  cursor: pointer;\n  margin: 0 auto;\n  border-radius: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"btn": `Button_btn__Ue5sI`
};
export default ___CSS_LOADER_EXPORT___;

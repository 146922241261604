import React from 'react';
import Header from '../layout/Header';
import Footer from '../layout/Footer';
import Contact from './Contact';

const ContactPage = () => {
  return (
    <div>
        <Header />
          <div className='h-[75vh]'>
            <div className='mt-5 mx-auto'>
              <Contact /> 
            </div>
          </div>
        <Footer />
    </div>
  )
}

export default ContactPage

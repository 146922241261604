import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import imageLoader from '../assets/images/preload.png';
import classes from '../assets/styles/ProviderCard.module.css';
const ProviderCard = ({name,image,imageLoad,setImageLoad,event}) => {

  return (
    <div className='w-full cursor-pointer select-none m-0 p-0' onClick={event}>
        <div className='bg-[var(--accent-color)] pb-2 p-1 rounded-lg'>
            <LazyLoadImage
                className="object-cover w-full h-full rounded-lg"
                alt={image}
                src={imageLoad ? imageLoader: image}
                afterLoad={() => setImageLoad(false)}
                beforeLoad={() => setImageLoad(true)}/>
            <p className={`mb-0 mt-1.5 select-none text-center text-[var(--main-color)] text-sm lg:text-base font-semibold ${classes.mobileFont}`}>{name}</p>
        </div>
    </div>
  )
}

export default ProviderCard

import React, { useEffect, useState } from "react";
import Header from "../../layout/Header";
import Footer from "../../layout/Footer";
import PromotionItem from "./PromotionItem";
import PromotionDetail from "./PromotionDetail";
import { getMethod } from "../../services";
import { PROMOTION_API } from "../../services/constant";
import Loader from "../../utils/Loader";
import { langChange } from "../../lang";
import NoData from "../../utils/NoData";
import Layout from '../../utils/Layout';

export default function Promotion() {
  const [showDetail, setShowDetail] = useState({});
  const [promotions, setPromotions] = useState([]);
  const [isLoading, setIsLoaing] = useState(false);

  const fetchPromo = async () => {
    setIsLoaing(true);
    const res = await getMethod(PROMOTION_API);
    res && setIsLoaing(false);
    res?.con && setPromotions(res?.data)
  }

  useEffect(() => {
    // fetchPromo();
  }, []);

  return (
    <>
      <Header />
      <Layout type="Games" name={langChange.promotion}>
          <div className="w-[95%] container mx-auto my-5 h-[100vh]">
              <div className="flex flex-wrap">
                {
                  promotions?.length > 0 ? 
                  promotions?.map((promotion) => (
                    <PromotionItem data={promotion} key={promotion.id} setShowDetail={() => setShowDetail(promotion)} />
                  )) : <NoData loading={isLoading} />
                }
              </div>
          </div>
          <PromotionDetail
            showDetail={showDetail?.id ? true : false}
            promotion={showDetail}
            setShowDetail={() => setShowDetail({})}
          />
      </Layout>
      {isLoading && <Loader />}
      <Footer />
    </>
  );
}

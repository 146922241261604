import SweetAlert from 'sweetalert2';
import { Slide, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export async function copyToClipboard(copyMe) {
  await navigator.clipboard.writeText(copyMe);
  SweetAlert.fire({
      icon: 'success',
      width: 300,
      title: 'Success',
      text: "Copy to Clipboard",
  })
};

export const numericeValueFilter = (input) => {
  const numericValue = input.replace(/\D/g, "");
  return numericValue;
};

export const calculateTotal = (data, type, format = false) => {
  if (format) {
    return data?.reduce((a, b) => {
      return parseInt(a) + parseInt(b[type]);
    }, 0)?.toLocaleString('en-us');
  } else {
    return data?.reduce((a, b) => {
      return parseInt(a) + parseInt(b[type]);
    }, 0);
  }
};

export const errorToaster = (message) => {
    toast.error(message, {
        position: "top-center",
        autoClose: 1000,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        transition: Slide,
        hideProgressBar: true,
        theme: 'colored'
    });
};

export const successToaster = (message) => {
    toast.success(message, {
        position: "top-center",
        autoClose: 1000,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        transition: Slide,
        hideProgressBar: true,
        theme: 'colored'
    });
};

export const getThreeLotteryTime = (closingDate) => {
  const closing_date = new Date(closingDate).getDate();
  let lottery_time;
  if (parseInt(closing_date) <= 19 && parseInt(closing_date) >= 15) {
    lottery_time = "One";
  }
  if (parseInt(closing_date) >= 28 || parseInt(closing_date) <= 3) {
    lottery_time = "Two";
  }
  return lottery_time;
};

export const formatLotteryError = (res, betLists) => {
  try {
    let err;
    const data = res?.data || res?.errors;
    const keys = Object.keys(data);

    if (keys.length <= 0) {
      errorToaster(res?.message || res?.error, 1)
      return;
    }

    const getFirstKey = keys?.[0];
    const errorMessage = data?.[getFirstKey];

    const splitKey = keys?.toString()?.split(".");

    if (splitKey?.length > 1) {
      const numIndex = splitKey?.[1];
      const getErrorBet = betLists?.[numIndex];
      const getErrorNum = getErrorBet?.digit;
      if (getErrorNum) {
        err = getErrorNum + " " + errorMessage;
      } else {
        err = errorMessage;
      }
      errorToaster(err, 1);
    } else {
      errorToaster(errorMessage, 1);
    }
  } catch (error) {
    errorToaster(error?.message);
  }
};

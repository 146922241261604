import React, { useEffect, useRef, useState } from "react";
import { langChange } from "../../lang";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { PROMOTION_API } from "../../services/constant";
import { postMethod } from "../../services";
import Loader from "../../utils/Loader";
import { errorToaster, successToaster } from "../../utils/Helper";
import { ToastContainer } from "react-toastify";

export default function PromotionItem({ setShowDetail, data }) {
  const { lang } = useSelector((state) => state.lang);
  useEffect(() => {}, [lang]);
  const [showAction, setShowAction] = useState(false);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const toastId = useRef(null);
  const { value } = useSelector((state) => state.userInfoSlice);

  const applyPromotion = async (id) => {
    setIsLoading(true);
    const token = JSON.parse(localStorage.getItem("l_555_a"));
    if (token) {
      const res = await postMethod(
        `${PROMOTION_API}/${id}/claim?user_game_id=${value?.game_info?.id}`,
        {},
        token.token
      );
      res && setIsLoading(false);
      if (res?.con) {
        successToaster("Apply promotion successful!", toastId);
      } else {
        errorToaster(res?.data?.error || "Apply promotion failed!", toastId);
      }
    } else {
      errorToaster("Login first", toastId);
      setTimeout(() => {
        navigate("/login");
      }, 1000);
    }
  };

  return (
    <div className="w-full md:w-1/2 lg:w-1/3 p-[5px]">
      <div
        className="bg-[var(--primary-color)] border border-[var(--light-color)] rounded-md overflow-hidden relative cursor-pointer"
        onMouseEnter={() => setShowAction(true)}
        onMouseLeave={() => setShowAction(false)}
      >
        <div className="relative">
          <img
            className={`w-full ${showAction ? "blur-[50px]" : ""}`}
            src={data?.photo}
            alt="Promotion Photo"
          />
          <div className="h-[35px] bg-slate-700 bg-opacity-75 w-full absolute bottom-0 left-0">
            <div className="flex justify-between items-center h-full px-2">
              <div>
                {langChange.from}: {data?.from}
              </div>
              <div>
                {langChange.to}: {data?.to}
              </div>
            </div>
          </div>
        </div>
        {showAction && (
          <div className="h-full w-full absolute top-0 left-0">
            <div className="flex justify-center items-center h-full">
              <div className="text-2xl font-semibold">{data?.en_title}</div>
            </div>
          </div>
        )}
        {showAction && (
          <div className="h-[55px] w-full absolute top-0 left-0">
            <div className="flex justify-between items-center h-full px-2">
              <button
                className="p-2 px-4 rounded-md bg-orange-600 border border-[var(--accent-color)] hover:border-white"
                onClick={(e) => applyPromotion(data?.id)}
              >
                {langChange.apply}
              </button>
              <button
                className="p-2 px-3 rounded-md bg-purple-700 border border-purple-700 hover:border-white"
                onClick={setShowDetail}
              >
                {langChange.more_info}
              </button>
            </div>
          </div>
        )}
      </div>
      {isLoading && <Loader />}
      <ToastContainer limit={1} />
    </div>
  );
}

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Loader_loader-container__LXY5b {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #0f0f0fa2;
    z-index: 999999;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.Loader_loader__Iuf6t {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  display: block;
  margin:15px auto;
  position: relative;
  color: #FFF;
  box-sizing: border-box;
  animation: Loader_animloader__ZTuTA 1s linear infinite alternate;
}

@keyframes Loader_animloader__ZTuTA {
  0% {
    box-shadow: -38px -12px ,  -14px 0,  14px 0, 38px 0;
  }
  33% {
    box-shadow: -38px 0px, -14px -12px,  14px 0, 38px 0;
  }
  66% {
    box-shadow: -38px 0px , -14px 0, 14px -12px, 38px 0;
  }
  100% {
    box-shadow: -38px 0 , -14px 0, 14px 0 , 38px -12px;
  }
}

`, "",{"version":3,"sources":["webpack://./src/assets/styles/Loader.module.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,MAAM;IACN,QAAQ;IACR,SAAS;IACT,OAAO;IACP,2BAA2B;IAC3B,eAAe;IACf,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,sBAAsB;AAC1B;AACA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,cAAc;EACd,gBAAgB;EAChB,kBAAkB;EAClB,WAAW;EACX,sBAAsB;EACtB,gEAAkD;AACpD;;AAEA;EACE;IACE,mDAAmD;EACrD;EACA;IACE,mDAAmD;EACrD;EACA;IACE,mDAAmD;EACrD;EACA;IACE,kDAAkD;EACpD;AACF","sourcesContent":[".loader-container {\n    position: fixed;\n    top: 0;\n    right: 0;\n    bottom: 0;\n    left: 0;\n    background-color: #0f0f0fa2;\n    z-index: 999999;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    flex-direction: column;\n}\n.loader {\n  width: 12px;\n  height: 12px;\n  border-radius: 50%;\n  display: block;\n  margin:15px auto;\n  position: relative;\n  color: #FFF;\n  box-sizing: border-box;\n  animation: animloader 1s linear infinite alternate;\n}\n\n@keyframes animloader {\n  0% {\n    box-shadow: -38px -12px ,  -14px 0,  14px 0, 38px 0;\n  }\n  33% {\n    box-shadow: -38px 0px, -14px -12px,  14px 0, 38px 0;\n  }\n  66% {\n    box-shadow: -38px 0px , -14px 0, 14px -12px, 38px 0;\n  }\n  100% {\n    box-shadow: -38px 0 , -14px 0, 14px 0 , 38px -12px;\n  }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loader-container": `Loader_loader-container__LXY5b`,
	"loader": `Loader_loader__Iuf6t`,
	"animloader": `Loader_animloader__ZTuTA`
};
export default ___CSS_LOADER_EXPORT___;

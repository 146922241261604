import { createSlice } from "@reduxjs/toolkit";

const depositSlice = createSlice({
    name: 'deposit',
    initialState: {
        value: {},
    },
    reducers: {
        depositHandler: (state,action) => {
            state.value = action.payload;
        }
    }
});

export const {depositHandler} = depositSlice.actions;
export default depositSlice.reducer;
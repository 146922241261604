import React, { useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import Input from "../utils/Input";
import Card from "../utils/Card";
import Button from "../utils/Button";
import { FiLock, FiMessageSquare, FiTablet, FiUser } from "react-icons/fi";
import logo from "../assets/images/logo/logo.png";
import { Link, useNavigate } from "react-router-dom";
import classes from "../assets/styles/Login.module.css";
import { useSelector } from "react-redux";
import { postMethod } from "../services";
import { REGISTER_API, REGISTER_OTP_API } from "../services/constant";
import Loader from "../utils/Loader";
import { langChange } from "../lang";
import Back from "../utils/Back";
import { ToastContainer } from "react-toastify";
import { errorToaster, numericeValueFilter, successToaster } from "../utils/Helper";

const Register = () => {
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [otp, setOtp] = useState("");
  const [errorName, setErrorName] = useState("");
  const [errorPhone, setErrorPhone] = useState("");
  const [errorPassword, setErrorPasword] = useState("");
  const [errorConfirmPassword, setErrorConfirmPassword] = useState("");
  const [errorOtp, setErrorOtp] = useState("");

  const navigate = useNavigate();
  const toastId = useRef(null);

  const { lang } = useSelector((state) => state.lang);
  useEffect(() => {}, [lang]);

  const nameHandler = (e) => {
    setName(e.target.value);
    setErrorName("");
  };

  const phoneHandler = (e) => {
    setPhone(e.target.value);
    setErrorPhone("");
  };

  const otpHandler = (e) => {
    if (e.target.value.length > 6) {
      return;
    }
    setOtp(numericeValueFilter(e.target.value));
    setErrorOtp("");
  };

  const passwordHandler = (e) => {
    if (e.target.value.length > 6) {
      return;
    }
    setPassword(e.target.value);
    setErrorPasword("");
  };

  const confirmPasswordHandler = (e) => {
    if (e.target.value.length > 6) {
      return;
    }
    setConfirmPassword(e.target.value);
    setErrorConfirmPassword("");
  };

  const getOtpHandler = async (e) => {
    if (!phone) {
      setErrorPhone('Phone is required.');
      return;
    }
    if (phone.length < 9 || phone.length > 20) {
      setErrorPhone("Phone no is minium is 9 and maximum is 20.");
      return;
    }
    const data = {
      phone,
    }
    setLoading(true);
    const res = await postMethod(REGISTER_OTP_API, data);
    res && setLoading(false);
    if (res.con) {
      successToaster('Otp sent successfully!', toastId);
    }
  }

  const submitHandler = async (e) => {
    e.preventDefault();
    if (!name) {
      setErrorName("Name is required.");
      return;
    }
    if (!phone) {
      setErrorPhone("Phone is required.");
      return;
    }
    if (phone.length < 9 || phone.length > 20) {
      setErrorPhone("Phone no is minium is 9 and maximum is 20.");
      return;
    }
    if (!otp) {
      setErrorOtp("Otp is required.");
      return;
    }
    if (otp.length !== 6) {
      setErrorOtp("Otp must be 6 characters.");
      return;
    }
    if (!password) {
      setErrorPasword("Password is required.");
      return;
    }
    if (password.length !== 6) {
      setErrorPasword("Password must be 6 characters.");
      return;
    }
    if (!confirmPassword) {
      setErrorConfirmPassword("Confirm password is required.");
      return;
    }
    setLoading(true);
    const data = {
      name, phone,token: otp, password, password_confirmation: confirmPassword,
    }
    const res = await postMethod(REGISTER_API, data);
    res && setLoading(false);
    if (res === "Failed to fetch") {
      errorToaster("Please check internet connection!", toastId);
    }
    console.log(res)
    if (res?.con) {
      successToaster('Register successful!', toastId);
        localStorage.setItem('l_555_a', JSON.stringify(res.data));
        setTimeout(() => {
          navigate('/')
        }, 1000)
    } else {
      if (res?.data?.error) {
        setErrorName(res?.data?.error);
      }
      if (res?.data?.name) {
        setErrorName(res?.data?.name[0]);
      }
      if (res?.data?.phone) {
        setErrorPhone(res?.data?.phone[0]);
      }
      if (res?.data?.otp) {
        setErrorOtp(res?.data?.otp[0]);
      }
      if (res?.data?.password) {
        setErrorPasword(res?.data?.password[0]);
      }
    }
  };

  useEffect(() => {
    if (password === confirmPassword) {
      setErrorConfirmPassword("");
      setErrorPasword("");
    } else {
      setErrorConfirmPassword("Your password does not match.");
    }
  }, [confirmPassword, password]);

  return (
    <>
      <Back />
      <div className="mx-auto py-3">
        <div className="mb-4">
          <div className="w-24 mx-auto">
            <Link to="/">
              <img src={logo} className="w-full rounded-lg" />
            </Link>
          </div>
        </div>
        <Card>
          <form onSubmit={submitHandler}>
            <Input
              icon={<FiUser />}
              label={langChange.account_name}
              placeholder={langChange.enter_account_name}
              type="text"
              value={name}
              event={nameHandler}
              error={errorName}
            />
            <Input
              icon={<FiTablet />}
              label={langChange.phone}
              type="number"
              value={phone}
              event={phoneHandler}
              otp_event={getOtpHandler}
              error={errorPhone}
              placeholder={"09xxxxxxxxx"}
            />
            <Input
              icon={<FiMessageSquare />}
              label={langChange.otp_code}
              type={'text'}
              value={otp}
              event={otpHandler}
              error={errorOtp}
              placeholder={langChange.enter_otp_from_sms}
            />
            <Input
              icon={<FiLock />}
              label={langChange.new_password}
              type="password"
              value={password}
              event={passwordHandler}
              error={errorPassword}
              placeholder={langChange.enter_new_password}
            />
            <Input
              icon={<FiLock />}
              label={langChange.confirm_password}
              type="password"
              value={confirmPassword}
              event={confirmPasswordHandler}
              error={errorConfirmPassword}
              placeholder={langChange.enter_confirm_password}
            />
            <div className="text-center">
              <Button label={langChange.confirm} type="submit" />
            </div>
          </form>
        </Card>
        <div className={classes["forget-pass"]}>
          <Link to="/login">
            <p className={classes["forget-text"]}>
              {langChange.already_have_an_account}
            </p>
          </Link>
        </div>
      </div>
      {ReactDOM.createPortal(
        loading && <Loader />,
        document.getElementById("portal")
      )}
      <ToastContainer />
    </>
  );
};

export default Register;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media (min-width: 320px) and (max-width: 480px){
    .ProviderCard_mobileFont__f6\\+JD{
        font-size: 11px !important;
    }
}`, "",{"version":3,"sources":["webpack://./src/assets/styles/ProviderCard.module.css"],"names":[],"mappings":"AAAA;IACI;QACI,0BAA0B;IAC9B;AACJ","sourcesContent":["@media (min-width: 320px) and (max-width: 480px){\n    .mobileFont{\n        font-size: 11px !important;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mobileFont": `ProviderCard_mobileFont__f6+JD`
};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ThreedNumberList_arr__UZpvh {
    display: none;
}
.ThreedNumberList_arr__UZpvh:checked ~ .ThreedNumberList_arr_label__jhHnR {
   background-color: var(--accent-color);
   color: var(--main-color);
}
.ThreedNumberList_arr_label__jhHnR {
    border: 1px solid var(--accent-color);
    height: 40px;
    width: 40px;
    border-radius: 10px;
    text-align: center;
    line-height: 40px;
    display: block;
    margin-left: 10px;
    cursor: pointer;
    -webkit-user-select: none;
            user-select: none;
}
.ThreedNumberList_amount-insert__cg4Ci {
    border: 1px solid var(--accent-color);
    outline: none;
    color: #FFF;
    background-color: transparent;
    border-radius: 7.5px 0 0 7.5px;
    border-right: none;
    text-align: right;
}`, "",{"version":3,"sources":["webpack://./src/assets/styles/ThreedNumberList.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;AACjB;AACA;GACG,qCAAqC;GACrC,wBAAwB;AAC3B;AACA;IACI,qCAAqC;IACrC,YAAY;IACZ,WAAW;IACX,mBAAmB;IACnB,kBAAkB;IAClB,iBAAiB;IACjB,cAAc;IACd,iBAAiB;IACjB,eAAe;IACf,yBAAiB;YAAjB,iBAAiB;AACrB;AACA;IACI,qCAAqC;IACrC,aAAa;IACb,WAAW;IACX,6BAA6B;IAC7B,8BAA8B;IAC9B,kBAAkB;IAClB,iBAAiB;AACrB","sourcesContent":[".arr {\n    display: none;\n}\n.arr:checked ~ .arr_label {\n   background-color: var(--accent-color);\n   color: var(--main-color);\n}\n.arr_label {\n    border: 1px solid var(--accent-color);\n    height: 40px;\n    width: 40px;\n    border-radius: 10px;\n    text-align: center;\n    line-height: 40px;\n    display: block;\n    margin-left: 10px;\n    cursor: pointer;\n    user-select: none;\n}\n.amount-insert {\n    border: 1px solid var(--accent-color);\n    outline: none;\n    color: #FFF;\n    background-color: transparent;\n    border-radius: 7.5px 0 0 7.5px;\n    border-right: none;\n    text-align: right;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"arr": `ThreedNumberList_arr__UZpvh`,
	"arr_label": `ThreedNumberList_arr_label__jhHnR`,
	"amount-insert": `ThreedNumberList_amount-insert__cg4Ci`
};
export default ___CSS_LOADER_EXPORT___;

export const getRoyalLotteryType = (time) => {
  let type;
  switch (time) {
    case "09:30 AM":
      type = "9";
      break;
    case "11:00 AM":
      type = "10";
      break;
    case "12:30 PM":
      type = "11";
      break;
    case "02:00 PM":
      type = "12";
      break;
    case "03:30 PM":
      type = "13";
      break;
    case "05:00 PM":
      type = "14";
      break;
    case "06:30 PM":
      type = "15";
      break;
    case "08:00 PM":
      type = "16";
      break;
    case "09:30 PM":
      type = "17";
      break;
    default:
      break;
  }
  return type;
};

export const getRoyalLotteryTime = (type) => {
  let time;
  switch (type) {
    case "9":
      time = "09:30 AM";
      break;
    case "10":
      time = "11:00 AM";
      break;
    case "11":
      time = "12:30 PM";
      break;
    case "12":
      time = "02:00 PM";
      break;
    case "13":
      time = "03:30 PM";
      break;
    case "14":
      time = "05:00 PM";
      break;
    case "15":
      time = "06:30 PM";
      break;
    case "16":
      time = "08:00 PM";
      break;
    case "17":
      time = "09:30 PM";
      break;
    default:
      break;
  }
  return time;
};

import React, { useEffect } from "react";
import classes from "../assets/styles/Footer.module.css";
import { Link } from "react-router-dom";
import { FaBell, FaGetPocket, FaHome, FaUser } from "react-icons/fa";
import { langChange } from "../lang";
import { useSelector } from "react-redux";

const Footer = () => {
  const route = window.location.pathname;
  const { lang } = useSelector((state) => state.lang);
  useEffect(() => {}, [lang]);

  return localStorage.getItem("l_555_a") ? (
    <div className={`${classes.nav} lg:hidden`}>
      <ul className="flex bg-[var(--primary-color)] justify-center">
        <li className={`w-2/6 ${route === "/" && classes.active}`}>
          <Link to="/" className="py-3">
            <FaHome />
            <span className="whitespace-nowrap">{langChange.home}</span>
          </Link>
        </li>
        <li
          className={`w-2/6 ${
            (route === "/pocket" ||
              route === "/deposit" ||
              route === "/deposit-history" ||
              route === "/withdraw") &&
            classes.active
          }`}
        >
          <Link to="/pocket" className="py-3">
            <FaGetPocket />
            <span className="whitespace-nowrap">{langChange.pocket}</span>
          </Link>
        </li>
        <li className={`w-2/6 ${route === "/notifications" && classes.active}`}>
          <Link to="/notifications" className="py-3">
            <FaBell />
            <span className="whitespace-nowrap">{langChange.notification}</span>
          </Link>
        </li>
        <li className={`w-2/6 ${route === "/profile" && classes.active}`}>
          <Link to="/profile" className="py-3">
            <FaUser />
            <span className="whitespace-nowrap">{langChange.profile}</span>
          </Link>
        </li>
      </ul>
    </div>
  ) : (
    ""
  );
};

export default Footer;

import React from "react";
import Spinner from "./Spinner";

export default function SingleUserInfo({ label, value, extra_label = '' }) {
  return (
    <div className="flex justify-between px-3 py-2 mb-2 animate-fadein border-b border-slate-500 pb-2">
      <p className="text-white w-1/2 text-start">{label}</p>
      <span className="text-white">:</span> &nbsp;
      <p className="text-white w-1/2 text-end"> {(!value || value === 'undefined') ? <Spinner /> : `${value} ${extra_label}`}</p>
    </div>
  );
}
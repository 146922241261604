import React, { useEffect, useState } from "react";
import Header from "../../layout/Header";
import Footer from "../../layout/Footer";
import { FiClock } from "react-icons/fi";
import TwodLive from "./TwodLive";
import { useSelector } from "react-redux";
import noteImg from "../../assets/images/icons/history.png";
import prizeImg from "../../assets/images/icons/winner.png";
import numberImg from "../../assets/images/icons/number-2.png";
import TimeModel from "../../utils/TimeModel";
import { Link, useNavigate } from "react-router-dom";
import { getMethod } from "../../services";
import { TWO_D_REAL_TIME_API, TWO_D_STATUS_API } from "../../services/constant";
import moment from "moment-timezone";
import { langChange } from "../../lang";
import Back from "../../utils/Back";
import { createPortal } from "react-dom";
import Loader from "../../utils/Loader";
import Container from "../../utils/Container";

const Twod = () => {
  const [timeShow, setTimeShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [towDStatus, setTowDStatus] = useState(false);
  const [currentTime, setCurrentTime] = useState("");
  const [morningOpenTime, setMorninigOpenTime] = useState("");
  const [morningCloseTime, setMorningCloseTime] = useState("");
  const [eveningOpenTime, setEveningOpenTime] = useState("");
  const [eveningCloseTime, setEveningCloseTime] = useState("");

  const { lang } = useSelector((state) => state.lang);
  useEffect(() => {}, [lang]);

  const token = JSON.parse(localStorage.getItem("l_555_a"));
  const fetchtowDStatus = async () => {
    if (token) {
      const res = await getMethod(TWO_D_STATUS_API, token.token);
      res.status === 200 && setTowDStatus(true);
    }
  };

  useEffect(() => {
    fetchtowDStatus();
  }, []);

  const fetchTowDRealTime = async () => {
    if (token) {
      setLoading(true);
      const res = await getMethod(TWO_D_REAL_TIME_API, token.token);
      res && setLoading(false);
      if (res.con) {
        if (res.data) {
          res.data.map((row) => {
            if (row.lottery_time === "Six") {
              setMorninigOpenTime(row.opening_time);
              setMorningCloseTime(row.closing_time);
            }
            if (row.lottery_time === "Seven") {
              setEveningOpenTime(row.opening_time);
              setEveningCloseTime(row.closing_time);
            }
          });
        }
        if (towDStatus) {
          setTimeShow(true);
        } else {
          navigate("/closed");
        }
      }
    }
  };

  const towDStatusHandler = () => {
    fetchTowDRealTime();
    setCurrentTime(moment().tz("Asia/Yangon").format("HH:mm:ss"));
  };

  return (
    <>
      <Header />
      <Back />
      <Container>
        <ul>
          <li className="bg-[var(--light-color)] border border-[var(--light-color)] p-3 rounded-lg text-[13px] flex items-center justify-between">
            <div
              className="w-1/3 flex items-center justify-center flex-col cursor-pointer"
              onClick={() => navigate("history")}
            >
              <img src={noteImg} className="w-[25px] mr-2" />
              <p className="text-[13px] mb-0 mt-1">{langChange.history}</p>
            </div>
            <div
              className="w-1/3 flex items-center justify-center flex-col cursor-pointer"
              onClick={() => navigate("winners")}
            >
              <img src={prizeImg} className="w-[25px]" />
              <p className="text-[13px] mb-0 mt-1">{langChange.winner}</p>
            </div>
            <div
              className="w-1/3 flex items-center justify-center flex-col cursor-pointer"
              onClick={towDStatusHandler}
            >
              <img src={numberImg} className="w-[25px]" />
              <p className="text-[13px] mb-0 mt-1">{langChange.bet}</p>
            </div>
          </li>
        </ul>
        <div className="mt-6">
          <TwodLive />
        </div>
      </Container>
      <Footer />
      <TimeModel isShow={timeShow} setIsShow={setTimeShow}>
        <h3 className="mb-5 flex items-center justify-center text-[13px] font-bold">
          <FiClock className="mr-2 text-xl text-[#eb5c00]" />{" "}
          <span className="text-black">{langChange.select_lottery_time}</span>
        </h3>
        <ul>
          {currentTime >= morningOpenTime &&
            currentTime <= morningCloseTime && (
              <li>
                <Link to="time/12:01">
                  <div className="text-center text-[13px] block border-[#3A55C8] border p-3 mb-4 rounded-[50px] text-black cursor-pointer">
                    12:01 PM
                  </div>
                </Link>
              </li>
            )}
          {currentTime >= eveningOpenTime &&
            currentTime <= eveningCloseTime && (
              <li>
                <Link to="time/04:30">
                  <div className="text-center text-[13px] block border-[#3A55C8] border p-3 mb-4 rounded-[50px] text-black cursor-pointer">
                    04:30 PM
                  </div>
                </Link>
              </li>
            )}
        </ul>
      </TimeModel>
      {createPortal(loading && <Loader />, document.getElementById("portal"))}
    </>
  );
};

export default Twod;

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getMethod } from "../../services";
import { THREE_D_LUCKY_NUMS_API } from "../../services/constant";
import { langChange } from "../../lang";
import Loader from "../../utils/Loader";
import { useSelector } from "react-redux";
import NoData from "../../utils/NoData";

const GoldThreedResultHistory = () => {
  const [loading, setLoading] = useState(false);
  const [resultHistory, setResultHistory] = useState([]);
  const navigate = useNavigate();

  const fetchResultHistory = async () => {
    setLoading(true);
    const token = JSON.parse(localStorage.getItem("l_555_a"));
    if (token) {
      const res = await getMethod(`${THREE_D_LUCKY_NUMS_API}?type=Gold`, token.token);
      if (res) {
        setLoading(false);
        if (res.data) {
          setResultHistory(res.data);
        }
      }
    } else {
      navigate("/login");
    }
  };

  useEffect(() => {
    fetchResultHistory();
  }, []);

  const { lang } = useSelector((state) => state.lang);
  useEffect(() => {}, [lang]);

  return (
    <div className="mt-5">
      {resultHistory.length > 0 && (
        <div className="mb-5">
          <h2 className="text-center text-[100px] font-bold">
            {resultHistory.sort((a, b) => b.id - a.id)[0].three_digit_number ??
              "!!"}
          </h2>
          <p className="text-center">
            {resultHistory.sort((a, b) => b.id - a.id)[0].lottery_date ??
              "--:--:--   --:--:--"}
          </p>
        </div>
      )}
      {resultHistory.length > 0 ? (
        resultHistory
          .sort((a, b) => b.id - a.id)
          .map((result, i) => (
            <div key={`3d_history_key_${result.id}_${i}`} className="my-3 flex justify-between items-center px-5 md:px-10 h-[100px] bg-[var(--light-color)] border border-[var(--light-color)] rounded-md">
              <div className="w-[25%]">
                <p className="text-start text-[15px]">
                  {result.lottery_date.split(",")[0]}
                </p>
              </div>
              <div className="w-[50%]">
                <p className="text-center text-[16px] tracking-widest text-[var(--gold-color)]">
                  {langChange.twit}
                </p>
                <p className="text-center text-[15px] border-t-[1px] pt-2">
                  {(result?.twit).join(", ")}
                </p>
              </div>
              <div className="w-[25%] text-end">
              <p className="text-center text-[23px] font-semibold tracking-widest text-[var(--gold-color)]">
                  {result.three_digit_number ?? "!!"}
                </p>
              </div>
            </div>
          ))
      ) : (
        <NoData loading={loading} />
      )}
      {loading && <Loader />}
    </div>
  );
};

export default GoldThreedResultHistory;

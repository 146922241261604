// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.GameList_form__group__thK0R {
  position: relative;
  padding: 15px 0 0;
  width: 300px;
}

.GameList_form__field__9XF7Z {
  width: 100%;
  border: 0;
  border-bottom: 2px solid gray;
  outline: 0;
  font-size: 16px;
  color: #fff;
  padding: 7px 0;
  background: transparent;
  transition: border-color 0.2s;

  &::placeholder {
    color: transparent;
  }

  &:placeholder-shown ~ .GameList_form__label__YE8it {
    font-size: 15px;
    cursor: text;
    top: 20px;
  }
}

.GameList_form__label__YE8it {
  position: absolute;
  top: 0;
  display: block;
  transition: 0.2s;
  font-size: 14px;
  color: gray;
}

.GameList_form__field__9XF7Z:focus {
  ~ .GameList_form__label__YE8it {
    position: absolute;
    top: 0;
    display: block;
    transition: 0.2s;
    font-size: 14px;
    color: primary;
  }
  padding-bottom: 6px;
  font-weight: 700;
  border-width: 2px;
  border-bottom-color: var(--main-color);
  border-image: linear-gradient(to right, primary, secondary);
  border-image-slice: 1;
}
/* reset input */
.GameList_form__field__9XF7Z {
  &:required,
  &:invalid {
    box-shadow: none;
  }
}
`, "",{"version":3,"sources":["webpack://./src/assets/styles/GameList.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,iBAAiB;EACjB,YAAY;AACd;;AAEA;EACE,WAAW;EACX,SAAS;EACT,6BAA6B;EAC7B,UAAU;EACV,eAAe;EACf,WAAW;EACX,cAAc;EACd,uBAAuB;EACvB,6BAA6B;;EAE7B;IACE,kBAAkB;EACpB;;EAEA;IACE,eAAe;IACf,YAAY;IACZ,SAAS;EACX;AACF;;AAEA;EACE,kBAAkB;EAClB,MAAM;EACN,cAAc;EACd,gBAAgB;EAChB,eAAe;EACf,WAAW;AACb;;AAEA;EACE;IACE,kBAAkB;IAClB,MAAM;IACN,cAAc;IACd,gBAAgB;IAChB,eAAe;IACf,cAAc;EAChB;EACA,mBAAmB;EACnB,gBAAgB;EAChB,iBAAiB;EACjB,sCAAsC;EACtC,2DAA2D;EAC3D,qBAAqB;AACvB;AACA,gBAAgB;AAChB;EACE;;IAEE,gBAAgB;EAClB;AACF","sourcesContent":[".form__group {\n  position: relative;\n  padding: 15px 0 0;\n  width: 300px;\n}\n\n.form__field {\n  width: 100%;\n  border: 0;\n  border-bottom: 2px solid gray;\n  outline: 0;\n  font-size: 16px;\n  color: #fff;\n  padding: 7px 0;\n  background: transparent;\n  transition: border-color 0.2s;\n\n  &::placeholder {\n    color: transparent;\n  }\n\n  &:placeholder-shown ~ .form__label {\n    font-size: 15px;\n    cursor: text;\n    top: 20px;\n  }\n}\n\n.form__label {\n  position: absolute;\n  top: 0;\n  display: block;\n  transition: 0.2s;\n  font-size: 14px;\n  color: gray;\n}\n\n.form__field:focus {\n  ~ .form__label {\n    position: absolute;\n    top: 0;\n    display: block;\n    transition: 0.2s;\n    font-size: 14px;\n    color: primary;\n  }\n  padding-bottom: 6px;\n  font-weight: 700;\n  border-width: 2px;\n  border-bottom-color: var(--main-color);\n  border-image: linear-gradient(to right, primary, secondary);\n  border-image-slice: 1;\n}\n/* reset input */\n.form__field {\n  &:required,\n  &:invalid {\n    box-shadow: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form__group": `GameList_form__group__thK0R`,
	"form__field": `GameList_form__field__9XF7Z`,
	"form__label": `GameList_form__label__YE8it`
};
export default ___CSS_LOADER_EXPORT___;

import React, { useEffect, useRef, useState } from "react";
import Header from "../../layout/Header";
import Footer from "../../layout/Footer";
import classes from "../../assets/styles/TwodHkwayHtoe.module.css";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { errorToaster } from "../../utils/Helper";
import { useDispatch, useSelector } from "react-redux";
import { betNumbersHandler } from "../../store/slices/betNumbersSlice";
import { getMethod } from "../../services";
import { TWO_D_LIMIT_API, TWO_D_REAL_TIME_API } from "../../services/constant";
import moment from "moment-timezone";
import { langChange } from "../../lang";
import NoData from "../../utils/NoData";
import { getRoyalLotteryType } from "./RoyalHelper";
import Back from "../../utils/Back";
import { createPortal } from "react-dom";
import Loader from "../../utils/Loader";

const RoyalHkwayHtoe = () => {
  const [amount, setAmount] = useState("");
  const navigate = useNavigate();
  const toastId = useRef(null);
  const params = useParams();
  const dispatch = useDispatch();
  const [closedNums, setClosedNums] = useState([]);
  const { value } = useSelector((state) => state.userInfoSlice);
  const [openTime, setOpenTime] = useState("");
  const [closeTime, setCloseTime] = useState("");
  const currentTime = useState(moment().tz("Asia/Yangon").format("HH:mm:ss"));
  const [targetNum, setTargetNum] = useState("");
  const [resultNums, setResultNums] = useState([]);
  const [isApu, setIsApu] = useState("null");
  const [loading, setLoading] = useState(true);

  const token = JSON.parse(localStorage.getItem("l_555_a"));

  const { lang } = useSelector((state) => state.lang);
  useEffect(() => {}, [lang]);

  const fetchTowDRealTime = async () => {
    if (token) {
      setLoading(true);
      const res = await getMethod(
        TWO_D_REAL_TIME_API + "?type=Royal Thai",
        token.token
      );
      res && setLoading(false);
      if (res.con) {
        if (res.data) {
          res.data.map((row) => {
            if (row.lottery_time === getRoyalLotteryType(params.time)) {
              setOpenTime(row.opening_time);
              setCloseTime(row.closing_time);
            }
          });
        }
      }
    }
  };

  useEffect(() => {
    fetchTowDRealTime();
  }, []);

  const fetchTwoDLimit = async () => {
    if (!getRoyalLotteryType(params.time)) {
      navigate(-1);
      return;
    }

    if (!token) {
      navigate("/login");
      return;
    }
    setLoading(true);
    const res = await getMethod(
      `${TWO_D_LIMIT_API}?type=Royal Thai&time=${getRoyalLotteryType(
        params.time
      )}`,
      token.token
    );

    if (!res) {
      return;
    } else {
      setLoading(false);
    }

    const tempLimitNums = res.data
      .filter((item) => !item.status || item.percentage >= 100)
      .map((item) => ({ number: item.number }));

    setClosedNums(tempLimitNums);
  };

  useEffect(() => {
    if (currentTime && closeTime && openTime) {
      if (currentTime > openTime && currentTime < closeTime) {
        fetchTwoDLimit();
      } else {
        errorToaster("သတ်မှတ်ထီထိုးချိန် ကျော်လွန်သွားပါပြီ။", toastId);
        setTimeout(() => {
          navigate(-2);
        }, 1000);
      }
    }
  }, [openTime, closeTime]);

  const submitHandler = () => {
    const totalAmount = resultNums.length * amount;
    if (value?.user_info?.current_balance >= totalAmount) {
      if (amount >= 100) {
        if (resultNums.length > 0) {
          dispatch(betNumbersHandler(resultNums));
          navigate(`/all/bet/royal2d/time/${params.time}/confirm`);
        }
      } else {
        errorToaster("Minium bet amount is 100", toastId);
      }
    } else {
      errorToaster("Balance insufficient to bet!", toastId);
    }
  };

  const targetNumHandler = (e) => {
    const value = e.target.value;
    const positiveNumberPattern = /^[+]?\d*\.?\d*$/;
    positiveNumberPattern.test(value) && setTargetNum(value.slice(0, 4));
  };

  const amountHandler = (e) => {
    const value = e.target.value;
    const positiveNumberPattern = /^[+]?\d*\.?\d*$/;
    positiveNumberPattern.test(value) && setAmount(value);
  };

  const generateNumber = (apu) => {
    if (!targetNum) {
      errorToaster("ခွေထိုးမည့် ဂဏန်းထည့်ပါ။", toastId);
      return;
    }
    if (targetNum.length < 3) {
      errorToaster(
        "အနည်းဆုံး (၃) လုံးမှ အများဆုံး (၄) လုံးအတွင်း ဖြစ်ရမည်။",
        toastId
      );
      return;
    }
    const isUnique = new Set(targetNum.split("")).size === targetNum.length;
    if (!isUnique) {
      errorToaster("အပူး (သို့) ဂဏန်းတူ (၂)လုံး ထည့်၍ မရပါ", toastId);
      return;
    }
    if (!amount) {
      errorToaster("ငွေပမာဏ ထည့်ပါ", toastId);
      return;
    }
    if (amount < 100) {
      errorToaster("အနည်းဆုံး ၁၀၀ ကျပ်ထည့်ပါ။");
      return;
    }
    const results = [];
    const closeAlert = [];
    for (let i = 0; i < targetNum.length; i++) {
      for (let j = 0; j < targetNum.length; j++) {
        const combination = targetNum[i] + targetNum[j];
        const find = closedNums.find((num) => num.number === combination);
        if (apu) {
          setIsApu("apu");
          if (find) {
            closeAlert.push(find.number);
          } else {
            results.push({
              id: +combination,
              number: combination,
              amount,
              time: params.time,
            });
          }
        } else {
          setIsApu("no_apu");
          if (i !== j) {
            if (find) {
              closeAlert.push(find.number);
            } else {
              results.push({
                id: +combination,
                number: combination,
                amount,
                time: params.time,
              });
            }
          }
        }
      }
    }
    if (closeAlert.length > 0) {
      errorToaster(
        `( ${[...closeAlert]} ) ${
          closeAlert.length > 1 ? "are" : "is"
        } not avaliable!`,
        toastId
      );
    }
    setResultNums(results);
  };

  const apuHandler = () => {
    if (isApu !== "apu") {
      generateNumber(true);
    } else {
      setIsApu("null");
      setResultNums([]);
    }
  };
  const noApuHandler = () => {
    if (isApu !== "no_apu") {
      generateNumber(false);
    } else {
      setIsApu("null");
      setResultNums([]);
    }
  };

  return (
    <>
      <Header />
      <Back />
      <div className="min-h-[85vh]">
        <div className="container mx-auto">
          <div className="w-[95%] max-w-[500px] mx-auto mt-3 mb-10">
            <div className="py-3 sticky lg:top-[70px] top-[56px] z-10 bg-[var(--secondary-color)] rounded-lg">
              <div className="flex flex-col md:flex-row gap-2">
                <div className="flex gap-2 w-[100%] md:w-[70%]">
                  <input
                    className={`text-slate-200 w-[50%] p-2 rounded-md border border-[var(--main-color)] bg-transparent ${
                      isApu !== "null" ? "cursor-not-allowed" : ""
                    }`}
                    type="text"
                    value={targetNum}
                    onChange={targetNumHandler}
                    disabled={isApu !== "null"}
                    placeholder={langChange.min_max_number}
                  />
                  <input
                    className={`text-slate-200 w-[50%] p-2 rounded-md border border-[var(--main-color)] bg-transparent text-end ${
                      isApu !== "null" ? "cursor-not-allowed" : ""
                    }`}
                    type="text"
                    placeholder={langChange.min_100}
                    value={amount}
                    disabled={isApu !== "null"}
                    onChange={amountHandler}
                  />
                </div>
                <div className="flex gap-2 w-[100%] md:w-[30%]">
                  <button
                    className={`w-[50%] border border-[var(--accent-color)] rounded-lg py-2 px-3 whitespace-nowrap ${
                      isApu === "no_apu"
                        ? "cursor-not-allowed hover:border-[var(--accent-color)"
                        : "cursor-pointer hover:border-slate-200"
                    } ${
                      isApu === "apu"
                        ? "bg-red-600 text-white"
                        : "bg-[var(--accent-color)] text-[var(--text-color)]"
                    }`}
                    onClick={apuHandler}
                    disabled={isApu === "no_apu"}
                  >
                    {isApu === "apu"
                      ? langChange.remove
                      : langChange.apu_number}
                  </button>
                  <button
                    className={`w-[50%] bg-[var(--accent-color)] border border-[var(--accent-color)] rounded-lg py-2 px-3 whitespace-nowrap ${
                      isApu === "apu"
                        ? "cursor-not-allowed hover:border-[var(--accent-color)]"
                        : "cursor-pointer hover:border-slate-200"
                    } ${
                      isApu === "no_apu"
                        ? "bg-red-600 text-white"
                        : "bg-[var(--accent-color)] text-[var(--text-color)]"
                    }`}
                    onClick={noApuHandler}
                    disabled={isApu === "apu"}
                  >
                    {isApu === "no_apu"
                      ? langChange.remove
                      : langChange.no_apu_number}
                  </button>
                </div>
              </div>
            </div>
            <div className={`${classes["table-card-container"]} mt-3`}>
              <ul className={classes["table-header"]}>
                <li>{langChange.sr}</li>
                <li>{langChange.number}</li>
                <li>{langChange.amount}</li>
              </ul>
              {resultNums.length > 0 ? (
                resultNums.map((num, i) => (
                  <ul key={num.number} className={classes["table-header"]}>
                    <li>{i + 1}.</li>
                    <li className="text-[#f6e25d]">{num.number}</li>
                    <li>{num.amount?.toLocaleString("en-us")}</li>
                  </ul>
                ))
              ) : (
                <NoData loading={loading} />
              )}
              {resultNums.length > 0 && (
                <button
                  className="w-full border border-[var(--accent-color)] hover:border-slate-200 bg-[var(--accent-color)] transition-[1s] rounded-lg py-[10px] px-3 cursor-pointer whitespace-nowrap text-[var(--text-color)] sticky bottom-0"
                  onClick={submitHandler}
                >
                  {langChange.bet}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <ToastContainer limit={1} />
      {createPortal(loading && <Loader />, document.getElementById("portal"))}
    </>
  );
};

export default RoyalHkwayHtoe;

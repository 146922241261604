import { createSlice } from "@reduxjs/toolkit";

const betNumbersSlice = createSlice({
    name: 'bet',
    initialState: {
        betListVal: [],
    },
    reducers: {
        betNumbersHandler: (state,action) => {
            state.betListVal = action.payload;
        }
    }
});

export const {betNumbersHandler} = betNumbersSlice.actions;
export default betNumbersSlice.reducer;
// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PaymentList_payment-image__qlVIU {
  width: 70px;
  margin: 0 5px;
  cursor: pointer;
}
.PaymentList_payment-image__qlVIU img {
  width: 100%;
  border-radius: 5px;
}
.PaymentList_payment-list__3TKig {
  position: relative;
}

.PaymentList_payment-check__79Fpw {
  display: none;
}
.PaymentList_check-icon__itUk6 {
  color: #16a34a;
  font-size: 16px;
  position: absolute;
  right: 10px;
  bottom: 5px;
}
`, "",{"version":3,"sources":["webpack://./src/assets/styles/PaymentList.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,aAAa;EACb,eAAe;AACjB;AACA;EACE,WAAW;EACX,kBAAkB;AACpB;AACA;EACE,kBAAkB;AACpB;;AAEA;EACE,aAAa;AACf;AACA;EACE,cAAc;EACd,eAAe;EACf,kBAAkB;EAClB,WAAW;EACX,WAAW;AACb","sourcesContent":[".payment-image {\n  width: 70px;\n  margin: 0 5px;\n  cursor: pointer;\n}\n.payment-image img {\n  width: 100%;\n  border-radius: 5px;\n}\n.payment-list {\n  position: relative;\n}\n\n.payment-check {\n  display: none;\n}\n.check-icon {\n  color: #16a34a;\n  font-size: 16px;\n  position: absolute;\n  right: 10px;\n  bottom: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"payment-image": `PaymentList_payment-image__qlVIU`,
	"payment-list": `PaymentList_payment-list__3TKig`,
	"payment-check": `PaymentList_payment-check__79Fpw`,
	"check-icon": `PaymentList_check-icon__itUk6`
};
export default ___CSS_LOADER_EXPORT___;

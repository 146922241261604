import React, { useEffect, useState } from "react";
import Layout from "../utils/Layout";
import { FiFacebook, FiPhone } from "react-icons/fi";
import { getMethod } from "../services";
import { CONTACT_API } from "../services/constant";
import { FaTelegram, FaViber } from "react-icons/fa";

const Contact = () => {
  const [contact, setContact] = useState([]);
  const fetchContact = async () => {
    const res = await getMethod(`${CONTACT_API}`);
    if (res.con) {
      setContact(res.data);
    }
  };
  useEffect(() => {
    fetchContact();
  }, []);
  return (
    <Layout type="Contact" name="Us">
      <div className="w-[95%] mx-auto flex items-center justify-between pb-10 flex-wrap">
        <div className="my-3 w-full md:w-1/2 lg:w-1/4 group">
          <a
            href={contact?.facebook}
            target="_blank"
            className="flex items-center lg:justify-center"
          >
            <div className="w-12 min-w-[3rem] h-12 rounded-full bg-neutral-200 group-hover:bg-[var(--accent-color)] transition duration-700 ease-in-out flex items-center justify-center">
              <FiFacebook className="text-[var(--primary-color)] text-xl lg:text-2xl transition duration-700 ease-in-out" />
            </div>
            <div className="pl-2 md:pl-5">
              <h4 className="text-[#FFF] text-base font-extrabold">
                Facebook :
              </h4>
              <p className="mb-0 text-[#FFF] text-sm font-extrabold">
                {contact?.facebook}
              </p>
            </div>
          </a>
        </div>
        <div className="my-3 w-full md:w-1/2 lg:w-1/4 group">
          <a
            href={`viber://chat/?number=${contact?.viber}`}
            target="_blank"
            className="flex items-center lg:justify-center"
          >
            <div className="w-12 min-w-[3rem] h-12 rounded-full bg-neutral-200 group-hover:bg-[var(--accent-color)] transition duration-700 ease-in-out flex items-center justify-center">
              <FaViber className="text-[var(--primary-color)] text-xl lg:text-2xl transition duration-700 ease-in-out" />
            </div>
            <div className="pl-2 md:pl-5">
              <h4 className="text-[#FFF] text-base font-extrabold">Viber :</h4>
              <p className="mb-0 text-[#FFF] text-sm font-extrabold">
                {contact?.viber}
              </p>
            </div>
          </a>
        </div>
        <div className="my-3 w-full md:w-1/2 lg:w-1/4 group">
          <a
            href={`${contact?.gmail}`}
            target="_blank"
            className="flex items-center lg:justify-center"
          >
            <div className="w-12 min-w-[3rem] h-12 rounded-full bg-neutral-200 group-hover:bg-[var(--accent-color)] transition duration-700 ease-in-out flex items-center justify-center">
              <FaTelegram className="text-[var(--primary-color)] text-xl lg:text-2xl transition duration-700 ease-in-out" />
            </div>
            <div className="pl-2 md:pl-5">
              <h4 className="text-[#FFF] text-base font-extrabold">
                Telegram :
              </h4>
              <p className="mb-0 text-[#FFF] text-sm font-extrabold">
                {contact?.gmail}
              </p>
            </div>
          </a>
        </div>
        <div className="my-3 w-full md:w-1/2 lg:w-1/4 group">
          <a
            href={`tel:${contact?.phone}`}
            target="_blank"
            className="flex items-center lg:justify-center"
          >
            <div className="w-12 min-w-[3rem] h-12 rounded-full bg-neutral-200 group-hover:bg-[var(--accent-color)] transition duration-700 ease-in-out flex items-center justify-center">
              <FiPhone className="text-[var(--primary-color)] text-xl lg:text-2xl transition duration-700 ease-in-out" />
            </div>
            <div className="pl-2 md:pl-5">
              <h4 className="text-[#FFF] text-base font-extrabold">Call :</h4>
              <p className="mb-0 text-[#FFF] text-sm font-extrabold">
                {contact?.phone}
              </p>
            </div>
          </a>
        </div>
      </div>
    </Layout>
  );
};

export default Contact;

import React, { useEffect, useRef, useState } from 'react'
import { FiPlus } from 'react-icons/fi'
import Header from '../layout/Header'
import { FaTrash } from 'react-icons/fa';
import { langChange } from '../lang';
import { useSelector } from 'react-redux';
import { errorToaster, successToaster } from '../utils/Helper';
import { ToastContainer } from 'react-toastify';
import { deleteMethod, postMethod, postMethodFormData } from '../services';
import { FEEDBACK_API, FEEDBACK_PHOTO_API, FEEDBACK_PHOTO_REMOVE_API } from '../services/constant';
import Loader from '../utils/Loader';
import { useNavigate } from 'react-router-dom';
import Layout from '../utils/Layout';

export default function LetterAndHelp() {
    const [images, setImages] = useState([]);
    const [letter, setLetter] = useState('');
    const [files, setFiles] = useState([]);
    const [fileNames, setFileNames] = useState([]);
    const [showImage, setShowImage] = useState('');
    const toastId = useRef(null);
    const [isLoading, setIsLoaing] = useState(false);
    const navigate = useNavigate();

    const { lang } = useSelector(state => state.lang);
    useEffect(() => {}, [lang]);

    const sendPhoto = async (file) => {
        setIsLoaing(true);
        const formData = new FormData();
        formData.append('file', file)
        const token = JSON.parse(localStorage.getItem('l_555_a'));
        const res = await postMethodFormData(FEEDBACK_PHOTO_API, formData, token.token)
        if (res) {
            setIsLoaing(false);
            res?.con && setFileNames(prev => [...prev, res?.data?.filename])
            res?.con && setFiles(prev => [...prev, {name: res?.data?.filename, id: images.length + 1}])
        }
    }
    
    const imageHandler = e => {
        const file = e.target.files[0]
        const fileTypes = ['image/png', 'image/jpeg', 'image/jpg'];
        if (file && fileTypes.includes(file.type)) {
            const imageUrl = URL.createObjectURL(file);
            const image = { id: images.length + 1 , name: file.name, url: imageUrl }
            if (images.length > 0) {
                if (images.find(img => img.name === file.name)) {
                    errorToaster('Image is already upload!', toastId)
                } else {
                    sendPhoto(file);
                    setImages([...images, image])
                }
            } else {
                sendPhoto(file);
                setImages([image])
            }
        } else {
            errorToaster(`Invalid file type!`, toastId);
        }
    }
    
    const makeDelete = async (file) => {
        setIsLoaing(true)
        const data = {
            file: file,
        }
        const token = JSON.parse(localStorage.getItem('l_555_a'));
        const res = await deleteMethod(FEEDBACK_PHOTO_REMOVE_API, data, token.token)
        if (res) {
            setIsLoaing(false);
            if (res.con) {
                showImage && setImages(images.filter(img => img.id !== showImage))
                const filter = fileNames?.filter(fileName => fileName !== file)
                const filterFile = files?.filter(name => name.name !== file);
                setFileNames(filter);
                setFiles(filterFile);
            }
        }
    }

    const imageDeleteHandler = () => {
        const finder = files.find(file => file.id === showImage);
        makeDelete(finder.name)
    }

    const letterHandler = e => {
        const current = e.target.value;
        current.length <= 1000 && setLetter(current)
    }

    const sendFeedback = async () => {
        setIsLoaing(true);
       if (letter) {
        const data = {
            'description': letter,
            'photo': fileNames
        }
        const token = JSON.parse(localStorage.getItem('l_555_a'));
        const res = await postMethod(FEEDBACK_API, data, token.token)
        if (res) {
            setIsLoaing(false);
            if (res?.con) {
                setImages([]);
                setFileNames([]);
                setFiles([]);
                setLetter('');
                setShowImage('');
                successToaster(res.message, toastId);
                setTimeout(() => {
                    navigate('/')
                }, 1000)
            }
        }
       } else {
        setIsLoaing(false);
        errorToaster('Feedback letter is required!', toastId);
       }
    }

    return (<>
        <Header />
        <Layout type="Games" name={langChange.recommendation_letter_and_help}>
            <div className='w-[95%] max-w-[500px] mx-auto'>
                <div className='mt-4'>{langChange.let_s_advice}</div>
                <textarea value={letter} className='resize-none w-full mt-2 rounded-lg bg-slate-100 text-slate-800 p-3' rows="8" onChange={letterHandler}></textarea>
                <div className='text-end text-sm'>{letter.length}/1000</div>

                {/* for image  */}
                <div className='mt-10 flex justify-between'><div>{langChange.upload_related_photo}</div><div>{images.length}/5</div></div>
                <div className='flex flex-wrap justify-start mt-3 gap-1'>
                    {
                        images.length > 0 ? <>                         {
                                    images.map((img, i) => <div onMouseEnter={() => setShowImage(img.id)} onMouseLeave={() => setShowImage('')} className='w-[19%] rounded-lg bg-[var(--primary-color)] border border-[var(--light-color)] cursor-pointer relative hover:border-red-900' key={i} ><img className='w-full p-2' src={img.url} alt={img.name} />
                                    {
                                        showImage === img.id && <FaTrash onClick={imageDeleteHandler} className='absolute top-1 left-[2px] text-lg hover:text-red-400' />
                                    }
                                    </div>)
                                } </>
                            : <><input className='hidden' id='image_one' type="file" accept='image/*' onChange={imageHandler} />
                                <label htmlFor='image_one' className='w-[19%] min-h-[90px] h-[auto] border-2 border-[var(--light-color)] flex justify-center items-center rounded-lg hover:bg-[var(--primary-color)] cursor-pointer mr-3'>
                                    <FiPlus className='text-[50px]' />
                                </label></>
                    }
                    {
                        images.length === 5 ? '' : <><input className='hidden' id='image_two' type="file" accept='image/*' onChange={imageHandler} />
                            <label htmlFor='image_two' className='w-[19%] min-h-[90px] h-[auto] border-2 border-[var(--light-color)] flex justify-center items-center rounded-lg hover:bg-[var(--primary-color)] cursor-pointer'>
                                <FiPlus className='text-[50px]' />
                            </label></>
                    }
                </div>
                <div className='flex justify-end mt-5'>
                <button className="p-2 px-5 rounded-md bg-[var(--main-color)] border border-[var(--accent-color)] hover:border-white text-[var(--text-color)] " onClick={sendFeedback}>
                    {langChange.send}
                </button>
                </div>
            </div>
        </Layout>
        <ToastContainer limit={1} />
        {
            isLoading && <Loader />
        }
    </>
    )
}

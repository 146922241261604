import React, { useEffect, useRef, useState } from "react";
import Header from "../layout/Header";
import Footer from "../layout/Footer";
import { FiPocket, FiRepeat } from "react-icons/fi";
import { BiSolidRightArrow } from "react-icons/bi";
import { FaGamepad } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import depositImg from "../assets/images/deposit.png";
import withdrawImg from "../assets/images/withdraw.png";
import footballImg from "../assets/images/icons/football.png";
import slotImg from "../assets/images/icons/slot.png";
import liveCasinoImg from "../assets/images/icons/livecasino.png";
import DetailModel from "../utils/DetailModel";
import { useDispatch, useSelector } from "react-redux";
import Marquee from "react-fast-marquee";
import { getMethod, postMethod } from "../services";
import {
  POCKET_STATUS_API,
  TRANSACTION_BETWEEN_API,
} from "../services/constant";
import { pocketStatusDataHandler } from "../store/slices/pocketStatusSlice";
import PopupFrame from "./PopupFrame";
import { langChange } from "../lang";
import { ToastContainer } from "react-toastify";
import { errorToaster, successToaster } from "../utils/Helper";
import { infoHandler } from "../store/slices/userInfoSlice";
import { createPortal } from "react-dom";
import Loader from "../utils/Loader";

const Pocket = () => {
  const [pocketShow, setPocketShow] = useState(false);
  const [gamePocketShow, setGamePocketShow] = useState(false);
  const { value } = useSelector((state) => state.userInfoSlice);
  const [toGameAmount, setToGameAmount] = useState("");
  const [errorGameAmount, setErrorToGameAmount] = useState("");
  const [transferToGameShow, setTransferToGameShow] = useState(false);
  const [toPocketAmount, setToPocketAmount] = useState("");
  const [errorPocketAmount, setErrorToPocketAmount] = useState("");
  const [transferToPocketShow, setTransferToPocketShow] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [videoUrl, setVideoUrl] = useState("");
  const toastId = useRef(null);

  const { lang } = useSelector((state) => state.lang);
  useEffect(() => {}, [lang]);

  const toPocketAmountHandler = (e) => {
    const amount = e.target.value;
    const positiveNumberPattern = /^[+]?\d*\.?\d*$/;
    positiveNumberPattern.test(amount) && setToPocketAmount(amount);
    setErrorToPocketAmount("");
  };

  useEffect(() => {
    if (!transferToGameShow) {
      setToGameAmount("");
    }
    if (!transferToPocketShow) {
      setToPocketAmount("");
    }
  }, [transferToGameShow, transferToPocketShow]);

  const transferToGameShowHandler = () => {
    if (value?.user_info?.current_balance > 0) {
      // setToGameAmount(value?.user_info?.current_balance)
      setTransferToGameShow(true);
    } else {
      errorToaster("Balance insufficient to transfer!", toastId);
    }
  };
  const transferToPocketShowHandler = () => {
    if (value?.game_info?.balance > 0) {
      // setToPocketAmount(value?.game_info?.balance)
      setTransferToPocketShow(true);
    } else {
      errorToaster("Balance insufficient to transfer!", toastId);
    }
  };
  const toGameAmountHandler = (e) => {
    const amount = e.target.value;
    const positiveNumberPattern = /^[+]?\d*\.?\d*$/;
    positiveNumberPattern.test(amount) && setToGameAmount(amount);
    setErrorToGameAmount("");
  };
  const submitToGameTransfer = async () => {
    setLoading(true);
    const token = JSON.parse(localStorage.getItem("l_555_a"));
    if (token) {
      if (toGameAmount) {
        if (Number(toGameAmount) <= Number(value?.user_info?.current_balance)) {
          setLoading(true);
          const data = {
            amount: toGameAmount,
            status: "main_to_787game",
            game_token: token.game_token,
          };
          const res = await postMethod(
            TRANSACTION_BETWEEN_API,
            data,
            token.token
          );
          if (res) {
            setLoading(false);
            setTransferToGameShow(false);
            if (res === "Failed to fetch") {
              errorToaster("Please check internet connection!", toastId);
            }
            if (res.con) {
              setToGameAmount("");
              setErrorToGameAmount("");
              successToaster("Transfer successful!", toastId);
              dispatch(infoHandler({...value,refresh: true}));
            } else if (res?.data.amount) {
              errorToaster(res.data.amount[0], toastId);
            }
          }
        } else {
          setErrorToGameAmount("User balance insufficient to transfer!");
        }
      } else {
        setErrorToGameAmount("Transfer amount is required!");
      }
    } else {
      setLoading(false);
      setTransferToGameShow(false);
      navigate("/login");
    }
  };
  const submitToPocketTransfer = async () => {
    setLoading(true);
    const token = JSON.parse(localStorage.getItem("l_555_a"));
    if (token) {
      if (toPocketAmount) {
        if (Number(toPocketAmount) <= Number(value?.game_info?.balance)) {
          setLoading(true);
          const data = {
            amount: toPocketAmount,
            status: "787game_to_main",
            game_token: token.game_token,
          };
          const res = await postMethod(
            TRANSACTION_BETWEEN_API,
            data,
            token.token
          );
          if (res) {
            setLoading(false);
            setTransferToPocketShow(false);
            if (res === "Failed to fetch") {
              errorToaster("Please check internet connection!", toastId);
            }
            if (res.con) {
              setToPocketAmount("");
              setErrorToPocketAmount("");
              successToaster("Transfer successful!", toastId);
              dispatch(infoHandler({...value,refresh: true}));
            } else if (res?.data.amount) {
              errorToaster(res.data.amount[0], toastId);
            }
          }
        } else {
          setErrorToPocketAmount("Game balance insufficient to transfer!");
        }
      } else {
        setErrorToPocketAmount("Transfer amount is required!");
      }
    } else {
      setLoading(false);
      setTransferToPocketShow(false);
      navigate("/login");
    }
  };
  // deposit and withdraw status handler
  const token = JSON.parse(localStorage.getItem("l_555_a"));
  const fetchPocketStatus = async (route) => {
    if (token) {
      const res = await getMethod(`${POCKET_STATUS_API}`, token.token);
      if (res.con) {
        dispatch(pocketStatusDataHandler(res.data));
        if (route === "Deposit") {
          res.data.deposit_status
            ? navigate("/deposit")
            : errorToaster("Deposit is temporary closed!", toastId);
        }
        if (route === "Withdraw") {
          res.data.withdrawal_status
            ? navigate("/withdraw")
            : errorToaster("Withdraw is temporary closed!", toastId);
        }
      } else {
        errorToaster("Login session expired, please login again.", toastId);
        setTimeout(() => {
          navigate('/login');
        }, 1000);
      }
    } else {
      errorToaster("Login session expired, please login again.", toastId);
      navigate('/login');
    }
  };
  return (
    <>
      <div>
        <Header />
        <div className="min-h-[85vh]">
          <div className="container mx-auto">
            {/* <ul className="w-[95%] flex justify-between max-w-[500px] mx-auto mt-4 bg-[var(--light-color)] p-3 rounded-lg border border-[var(--light-color)] cursor-pointer duration-300">
              <li
                onClick={(e) => navigate("/deposit-transaction")}
              >
                {langChange.balance}
              </li>
              <li>
              {value?.user_info?.current_balance?.toLocaleString(
                      "en-us"
                    ) ?? 0}{" "}
                    {langChange.kyat}
              </li>
            </ul> */}
            <ul className="w-[95%] max-w-[500px] mx-auto mt-10 bg-[var(--light-color)] rounded-lg p-1 border border-[var(--light-color)]">
              <li className=" p-3 rounded-lg text-[13px] mb-2 flex items-center justify-between">
                <div className="flex items-center">
                  <FiPocket className="text-2xl text-[var(--gold-color)]" />
                  <span className="ml-2">{langChange.main_pocket}</span>
                  <span
                    className="ml-2 block w-[23px] h-[23px] border text-[13px] text-center leading-[23px] rounded-full cursor-pointer"
                    onClick={() => setPocketShow(true)}
                  >
                    &#63;
                  </span>
                </div>
                <div className="flex items-center">
                  <span>
                    {value?.user_info?.current_balance?.toLocaleString(
                      "en-us"
                    ) ?? 0}{" "}
                    {langChange.kyat}
                  </span>
                  <span
                    className="ml-2 block w-[23px] h-[23px] border border-[var(--font-color)] text-[13px] text-center leading-[23px] rounded-full cursor-pointer text-[var(--font-color)]"
                    onClick={transferToPocketShowHandler}
                  >
                    &#43;
                  </span>
                </div>
              </li>
              <div className="px-4">
                <hr className="" />
              </div>
              <li className=" p-3 rounded-lg text-[13px] mb-2 flex items-center justify-between">
                <div className="flex items-center">
                  <FaGamepad className="text-2xl text-[tomato]" />
                  <span className="ml-2">{langChange.game_pocket}</span>
                  <span
                    className="ml-2 block w-[23px] h-[23px] border text-[13px] text-center leading-[23px] rounded-full cursor-pointer"
                    onClick={() => setGamePocketShow(true)}
                  >
                    &#63;
                  </span>
                </div>
                <div className="flex items-center">
                  <span>
                    {value?.game_info?.balance?.toLocaleString("en-us") ?? 0}{" "}
                    {langChange.kyat}
                  </span>
                  <span
                    className="ml-2 block w-[23px] h-[23px] border border-[var(--font-color)] text-[13px] text-center leading-[23px] rounded-full cursor-pointer text-[var(--font-color)]"
                    onClick={transferToGameShowHandler}
                  >
                    &#43;
                  </span>
                </div>
              </li>
            </ul>
            <ul className="w-[95%] max-w-[500px] mx-auto mt-6 flex items-center justify-center">
              <li
                className="mr-2 cursor-pointer w-[120px]"
                onClick={() => fetchPocketStatus("Deposit")}
              >
                <div className="group p-1 pb-2 rounded-lg text-[13px] mb-2 border border-[var(--light-color)] bg-[var(--light-color)] border-solid hover:bg-[var(--accent-color)] duration-300">
                  <img
                    src={depositImg}
                    alt="deposit"
                    className="w-full rounded-md mx-auto"
                  />
                  <p className="mb-0 text-center mt-2 text-white group-hover:text-[var(--main-color)] ">
                    {langChange.deposit}
                  </p>
                </div>
              </li>
              <li
                className="ml-2 cursor-pointer w-[120px]"
                onClick={() => fetchPocketStatus("Withdraw")}
              >
                <div className="group p-1 pb-2 rounded-lg text-[13px] mb-2 border border-[var(--light-color)] bg-[var(--light-color)] hover:bg-[var(--accent-color)] duration-300">
                  <img
                    src={withdrawImg}
                    alt="deposit"
                    className="w-full rounded-md mx-auto"
                  />
                  <p className="mb-0 text-center mt-2 text-white group-hover:text-[var(--main-color)]">
                    {langChange.withdraw}
                  </p>
                </div>
              </li>
            </ul>
            <ul className="w-[95%] max-w-[500px] mx-auto mt-4">
              <li
                className="bg-[var(--light-color)] w-full text-center p-3 rounded-lg border border-[var(--light-color)] cursor-pointer hover:bg-[var(--accent-color)] hover:text-[var(--main-color)] duration-300"
                onClick={(e) => navigate("/deposit-transaction")}
              >
                {langChange.deposit_and_withdraw_condition}
              </li>
            </ul>
            <div className="w-[95%] max-w-[500px] mx-auto mt-6 bg-[var(--light-color)] border border-[var(--light-color)] p-6 rounded-lg text-[13px] mb-2">
              <h5 className="text-center text-[13px] mb-0">
                {langChange.deposit_and_withdraw_time}
              </h5>
              <hr className="my-4" />
              <p className="mb-0 text-center">{langChange.service_time}</p>
            </div>
          </div>
        </div>
        <Footer />
      </div>
      <DetailModel isShow={pocketShow} setIsShow={setPocketShow}>
        <div className="text-[13px] mb-2">
          <div className="mb-4 flex items-center justify-center text-[13px] font-bold">
            <FiPocket className="mr-2 text-xl text-[#eb5c00]" />{" "}
            <span className="text-white">{langChange.main_pocket}</span>
          </div>
          <div className="mb-3 flex items-center justify-start text-sm">
            <p className="text-[12px] mr-2 w-[25px] h-[25px] text-center leading-[25px] rounded-full bg-[tomato]">
              2D
            </p>{" "}
            <span className="text-white">2D</span>
          </div>
          <div className="mb-3 flex items-center justify-start text-sm">
            <p className="text-[12px] mr-2 w-[25px] h-[25px] text-center leading-[25px] rounded-full bg-[skyblue]">
              3D
            </p>{" "}
            <span className="text-white">3D</span>
          </div>
          <div className="mb-3 flex items-center justify-start text-sm">
            <img src={withdrawImg} className="w-5 mr-2" />{" "}
            <span className="text-white">{langChange.withdraw}</span>
          </div>
          <div className="mb-3 flex items-center justify-start text-sm">
            <img src={depositImg} className="w-5 mr-2" />{" "}
            <span className="text-white">{langChange.deposit}</span>
          </div>
          <div className="mb-0 flex items-center justify-start text-sm">
            <FiRepeat className="text-lg text-[#eb5c00] mr-[10px]" />{" "}
            <span className="text-white">
              {langChange.transfer_to_game_pocket}
            </span>
          </div>
        </div>
      </DetailModel>
      <DetailModel isShow={gamePocketShow} setIsShow={setGamePocketShow}>
        <div className="text-[13px] mb-2">
          <div className="mb-4 flex items-center justify-center text-[13px] font-bold">
            <FaGamepad className="mr-2 text-xl text-[tomato]" />{" "}
            <span className="text-white">{langChange.game_pocket}</span>
          </div>
          <div className="mb-3 flex items-center justify-start text-sm">
            <img src={footballImg} className="w-5 mr-2" />{" "}
            <span className="text-white">{langChange.football}</span>
          </div>
          <div className="mb-3 flex items-center justify-start text-sm">
            <img src={slotImg} className="w-5 mr-2" />{" "}
            <span className="text-white">{langChange.slots_games}</span>
          </div>
          <div className="mb-3 flex items-center justify-start text-sm">
            <img src={liveCasinoImg} className="w-5 mr-2" />{" "}
            <span className="text-white">
              {langChange.live_casino_and_other_games}
            </span>
          </div>
          <div className="mb-0 flex items-center justify-start text-sm">
            <FiRepeat className="text-lg text-[#eb5c00] mr-[10px]" />{" "}
            <span className="text-white">
              {langChange.transfer_to_main_pocket}
            </span>
          </div>
        </div>
      </DetailModel>
      <DetailModel
        isShow={transferToPocketShow}
        setIsShow={setTransferToPocketShow}
        loading={loading}
        event={() => submitToPocketTransfer()}
      >
        <div className="flex justify-center items-center py-3">
          <FaGamepad className="mr-2 text-5xl text-[tomato]" />
          <div className="w-[75px]">
            <Marquee direction="right">
              <BiSolidRightArrow className="text-[#fbba49] text-lg" />
              <BiSolidRightArrow className="text-[#fbba49] text-lg" />
              <BiSolidRightArrow className="text-[#fbba49] text-lg" />
              <BiSolidRightArrow className="text-[#fbba49] text-lg" />
            </Marquee>
          </div>
          <FiPocket className="ml-2 text-5xl text-[#fbba49]" />
        </div>
        <div className="text-[13px] mb-2">
          <input
            className="w-full bg-white p-3 border border-sky-600 rounded-md text-slate-900"
            placeholder={langChange.transfer_amount}
            type="text"
            value={toPocketAmount}
            onChange={toPocketAmountHandler}
            onKeyDown={(e) => e.key === "Enter" && submitToPocketTransfer()}
          />
        </div>
        {errorPocketAmount && (
          <span className="mt-2 block text-red-600">{errorPocketAmount}</span>
        )}
      </DetailModel>
      <DetailModel
        isShow={transferToGameShow}
        setIsShow={setTransferToGameShow}
        loading={loading}
        event={() => submitToGameTransfer()}
      >
        <div className="flex justify-center items-center py-3">
          <FiPocket className="mr-2 text-5xl text-[#fbba49]" />
          <div className="w-[75px]">
            <Marquee direction="right">
              <BiSolidRightArrow className="text-[#fbba49] text-lg" />
              <BiSolidRightArrow className="text-[#fbba49] text-lg" />
              <BiSolidRightArrow className="text-[#fbba49] text-lg" />
              <BiSolidRightArrow className="text-[#fbba49] text-lg" />
            </Marquee>
          </div>
          <FaGamepad className="ml-2 text-5xl text-[tomato]" />
        </div>
        <div className="text-[13px] mb-2">
          <input
            className="w-full bg-white p-3 border border-sky-600 rounded-md text-slate-900"
            placeholder={langChange.transfer_amount}
            type="text"
            value={toGameAmount}
            onChange={toGameAmountHandler}
            onKeyDown={(e) => e.key === "Enter" && submitToGameTransfer()}
          />
        </div>
        {errorGameAmount && (
          <span className="mt-2 block text-red-600">{errorGameAmount}</span>
        )}
      </DetailModel>
      <PopupFrame setUrl={setVideoUrl} url={videoUrl} />
      <ToastContainer limit={1} />
      {
        createPortal(loading && <Loader/> , document.getElementById('portal'))
      }
    </>
  );
};

export default Pocket;

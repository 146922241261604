// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TwodHistory_history-card-header__m7z7D {
    display: flex;
    align-items: center;
}
.TwodHistory_history-card-header__m7z7D span {
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    letter-spacing: 1px;
    cursor: pointer;
    -webkit-user-select: none;
            user-select: none;
    border-bottom: 2px solid transparent;
    padding: 10px;
    font-size: 14px;
    width: 100%;
    border-right: 1px solid #636363
}
.TwodHistory_history-card-header__m7z7D span:last-child {
    border-right: none;
}
.TwodHistory_history-card-header__m7z7D span.TwodHistory_active__tiIQo {
    border-bottom-color: var(--accent-color);
}`, "",{"version":3,"sources":["webpack://./src/assets/styles/TwodHistory.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;AACvB;AACA;IACI,yBAAyB;IACzB,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,sBAAsB;IACtB,mBAAmB;IACnB,eAAe;IACf,yBAAiB;YAAjB,iBAAiB;IACjB,oCAAoC;IACpC,aAAa;IACb,eAAe;IACf,WAAW;IACX;AACJ;AACA;IACI,kBAAkB;AACtB;AACA;IACI,wCAAwC;AAC5C","sourcesContent":[".history-card-header {\n    display: flex;\n    align-items: center;\n}\n.history-card-header span {\n    text-transform: uppercase;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    flex-direction: column;\n    letter-spacing: 1px;\n    cursor: pointer;\n    user-select: none;\n    border-bottom: 2px solid transparent;\n    padding: 10px;\n    font-size: 14px;\n    width: 100%;\n    border-right: 1px solid #636363\n}\n.history-card-header span:last-child {\n    border-right: none;\n}\n.history-card-header span.active {\n    border-bottom-color: var(--accent-color);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"history-card-header": `TwodHistory_history-card-header__m7z7D`,
	"active": `TwodHistory_active__tiIQo`
};
export default ___CSS_LOADER_EXPORT___;

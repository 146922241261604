export const getGoldLotteryType = (time, short_type = false) => {
  let type;
  let short;
  switch (time) {
    case "09:30 AM":
      type = 'One_Gold';
      short = 'One';
      break;
    case "12:00 PM":
      type = 'Two_Gold';
      short = 'Two';
      break;
    case "02:00 PM":
      type = 'Three_Gold';
      short = 'Three';
      break;
    case "04:30 PM":
      type = 'Four_Gold';
      short = 'Four';
      break;
    case "07:00 PM":
      type = 'Five_Gold';
      short = 'Five';
      break;
    case "09:00 PM":
      type = 'Six_Gold';
      short = 'Six';
      break;
  }
  if (short_type) {
    return short;
  }
  return type;
};

export const getGoldLotteryTime = (type) => {
  let time;
  switch (type) {
    case 'One_Gold':
      time = "09:30 AM";
      break;
    case 'Two_Gold':
      time = "12:00 PM";
      break;
    case 'Three_Gold':
      time = "02:00 PM";
      break;
    case 'Four_Gold':
      time = "04:30 PM";
      break;
    case 'Five_Gold':
      time = "07:00 PM";
      break;
    case 'Six_Gold':
      time = "09:00 PM";
      break;
  }
  return time;
};

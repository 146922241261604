import React, { useEffect } from "react";
import { langChange } from "../../lang";
import { useSelector } from "react-redux";

export default function DepositHistoryCard({data}) {

  const { lang } = useSelector(state => state.lang);
  useEffect(() => {}, [lang]);

  return (
    <div className="bg-slate-50 rounded-md mb-3">
      <div className="flex justify-between border-b-[1px] border-b-indigo-500 py-3 mx-3">
        <p className="text-slate-900">{data?.created_at}</p>
        <p className={`${data.status === 'Rejected' ? 'text-red-500' : data.status === 'Pending' ? 'text-orange-500' : 
    'text-green-500'}`}>{data?.status}</p>
      </div>
      <div className="flex justify-between border-b-[1px] border-b-indigo-500 py-3 mx-3">
        <p className="text-green-500">{langChange.deposit_type}</p>
        <p className="text-slate-900">{data?.amount?.toLocaleString('en-us')} {langChange.kyat}</p>
      </div>
      <div className="flex justify-between p-3">
        <p className="text-slate-900">{data?.payment_provider?.name}</p>
        <p className="text-slate-900">{`${langChange.transaction_no} - ${data?.transation_no}`}</p>
      </div>
    </div>
  );
}

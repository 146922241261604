import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import Button from '../utils/Button';
import { Link, useNavigate } from 'react-router-dom';
import {postMethod} from '../services';
import Input from '../utils/Input';
import logo from '../assets/images/logo/logo.png';
import { CHANGE_PASSWORD_API } from '../services/constant';
import Card from '../utils/Card';
import { FiLock } from 'react-icons/fi';
import Loader from '../utils/Loader';
import { langChange } from '../lang';
import { useSelector } from 'react-redux';
import Back from '../utils/Back';
import { errorToaster, successToaster } from '../utils/Helper';
import { ToastContainer } from 'react-toastify';

const ChangePassword = () => {
  const [loading,setLoading] = useState(false);
  const [oldPassword,setOldPassword] = useState("");
  const [newPassword,setNewPassword] = useState("");
  const [confirmPassword,setConfirmPassword] = useState(""); 

  const [errorOldPassword,setErrorOldPassword] = useState("");
  const [errorNewPassword,setErrorNewPassword] = useState("");
  const [errorConfirmPassword,setErrorConfirmPassword] = useState("");
  const navigate = useNavigate();
  const toastId = useRef(null);

  const { lang } = useSelector(state => state.lang);
  useEffect(() => {}, [lang]);

  const oldPasswordHandler = e => {
    if(e.target.value.length > 6){
      return;
    }
    setOldPassword(e.target.value);
    setErrorOldPassword("");
  };

  const newPasswordHandler = e => {
    if(e.target.value.length > 6){
      return;
    };
     setNewPassword(e.target.value);
     setErrorNewPassword("");
  };

  const confirmPasswordHandler = e => {
    if(e.target.value.length > 6){
      return;
    }
     setConfirmPassword(e.target.value);
     setErrorConfirmPassword("");
  }

  const changePasswordHandler = async e => {
     e.preventDefault();
     setLoading(true);
     const token = localStorage.getItem('l_555_a');
     const data = {
        old_password: oldPassword,
        new_password: newPassword,
        confirm_password: confirmPassword
     };
     if(token){
          const deToken = JSON.parse(token);
          const res = await postMethod(CHANGE_PASSWORD_API, data, deToken.token);
          if(res){
            setLoading(false);
            if(res === 'Failed to fetch'){
              errorToaster('Please Check internet connection!', toastId)
            }
            if(res.con){
                successToaster('Password change successful!', toastId);
                localStorage.removeItem('l_555_a');
                setTimeout(() => {
                  navigate('/login');
                }, 1000)
            };
          }
     }else{
        navigate('/login');
     }
     
  }
  return (
    <>
    <Back />
      <div className='mx-auto py-4 h-screen'>
            <div>
                <div className='mb-4'>
                    <div className='w-24 mx-auto'>
                        <Link to="/">
                            <img src={logo} className='w-full rounded-lg'/>
                        </Link>
                    </div>
                </div>
                <Card>
                    <form onSubmit={changePasswordHandler}>
                        <Input icon={<FiLock />} label={langChange.old_password} type="password" value={oldPassword} event={oldPasswordHandler} error={errorOldPassword} placeholder={langChange.enter_old_password}/>
                        <Input icon={<FiLock />} label={langChange.new_password} type="password" value={newPassword} event={newPasswordHandler} error={errorNewPassword}  placeholder={langChange.enter_new_password}/>
                        <Input icon={<FiLock />} label={langChange.confirm_password} type="password" value={confirmPassword} event={confirmPasswordHandler} error={errorConfirmPassword}  placeholder={langChange.enter_confirm_password}/>
                        <div className='text-center'>
                            <Button label={langChange.confirm} type="submit" disable={loading}/>
                        </div>
                    </form>
                </Card>
            </div>
      </div>
      {
        ReactDOM.createPortal(loading && <Loader />, document.getElementById('portal'))
      }
      <ToastContainer limit={1} />
    </>
  )
}

export default ChangePassword

import React, { useEffect, useState } from "react";
import Header from "../../layout/Header";
import Footer from "../../layout/Footer";
import { FiClock } from "react-icons/fi";
import { useSelector } from "react-redux";
import noteImg from "../../assets/images/icons/history.png";
import prizeImg from "../../assets/images/icons/winner.png";
import numberImg from "../../assets/images/icons/number-2.png";
import { Link, useNavigate } from "react-router-dom";
import { getMethod } from "../../services";
import { DUBAI_TWO_D_REAL_TIME_API } from "../../services/constant";
import moment from "moment-timezone";
import { langChange } from "../../lang";
import { createPortal } from "react-dom";
import Loader from "../../utils/Loader";
import DubaiTwodResultHistory from "./DubaiTwodResultHistory";
import Back from "../../utils/Back";
import { getDubaiLotteryTime } from "./DubaiHelper";
import Container from "../../utils/Container";

const DubaiTwod = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [lotteries, setLotteries] = useState([]);
  const navigate = useNavigate();

  const { lang } = useSelector((state) => state.lang);
  useEffect(() => {}, [lang]);

  const currentTime = moment().tz("Asia/Yangon").format("HH:mm:ss");

  const token = JSON.parse(localStorage.getItem("l_555_a"));

  const fetchTowDRealTime = async () => {
    if (token) {
      setIsLoading(true);
      const res = await getMethod(
        `${DUBAI_TWO_D_REAL_TIME_API}?type=Dubai`,
        token.token
      );
      res && setIsLoading(false);
      if (res.con) {
        res.data && setLotteries(res.data);
      }
    }
  };

  const towDStatusHandler = () => {
    fetchTowDRealTime();
  };

  return (
    <>
      <Header />
      <Back />
      <Container>
        <ul>
          <li className="bg-[var(--light-color)] border border-[var(--light-color)] p-3 rounded-lg text-[13px] flex items-center justify-between">
            <div
              className="w-1/3 flex items-center justify-center flex-col cursor-pointer"
              onClick={() => navigate("history")}
            >
              <img src={noteImg} className="w-[25px] mr-2" />
              <p className="text-[13px] mb-0 mt-1">{langChange.history}</p>
            </div>
            <div
              className="w-1/3 flex items-center justify-center flex-col cursor-pointer"
              onClick={() => navigate("winners")}
            >
              <img src={prizeImg} className="w-[25px]" />
              <p className="text-[13px] mb-0 mt-1">{langChange.winner}</p>
            </div>
            <div
              className="w-1/3 flex items-center justify-center flex-col cursor-pointer"
              onClick={towDStatusHandler}
            >
              <img src={numberImg} className="w-[25px]" />
              <p className="text-[13px] mb-0 mt-1">{langChange.bet}</p>
            </div>
          </li>
        </ul>
        <div className="mt-6">
          {lotteries.length > 0 ? (
            <>
              <h3 className="mb-5 flex items-center justify-center text-[16px]">
                <FiClock className="mr-2 text-xl text-[var(--gold-color)] text-[20px]" />{" "}
                <span className="text-[var(--font-color)]">
                  {langChange.select_lottery_time}
                </span>
              </h3>
              <ul>
                {lotteries.map(
                  (lottery, i) =>
                    currentTime >= lottery?.opening_time &&
                    currentTime <= lottery?.closing_time && (
                      <li key={`dubai_key_${i}_${lottery?.opening_time}`}>
                        <Link
                          to={`time/${getDubaiLotteryTime(
                            lottery.lottery_time
                          )}`}
                        >
                          <div className="text-center text-[13px] block bg-[var(--light-color)] border-[var(--light-color)] border p-2 text-xl mb-4 rounded-lg text-[var(--gold-color)] cursor-pointer">
                            {getDubaiLotteryTime(lottery.lottery_time)}
                          </div>
                        </Link>
                      </li>
                    )
                )}
              </ul>
            </>
          ) : (
            <DubaiTwodResultHistory />
          )}
        </div>
      </Container>
      <Footer />
      {createPortal(isLoading && <Loader />, document.getElementById("portal"))}
    </>
  );
};

export default DubaiTwod;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Caption_banner-frame__nOIcB{
    height:40px;
    text-align: center;
    background: var(--light-color);
    padding: 8px 10px;
    color:var(--gold-color);
    overflow: hidden;
    font-weight: 400;
    font-size: 16px;
    margin-top: 12px;
}
/*----------------------------------------------------------------------------- */
@media (min-width: 318px) and (max-width: 480px) {
   .Caption_banner-frame__nOIcB {
    font-size: 14px;
   }
}`, "",{"version":3,"sources":["webpack://./src/assets/styles/Caption.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,kBAAkB;IAClB,8BAA8B;IAC9B,iBAAiB;IACjB,uBAAuB;IACvB,gBAAgB;IAChB,gBAAgB;IAChB,eAAe;IACf,gBAAgB;AACpB;AACA,iFAAiF;AACjF;GACG;IACC,eAAe;GAChB;AACH","sourcesContent":[".banner-frame{\n    height:40px;\n    text-align: center;\n    background: var(--light-color);\n    padding: 8px 10px;\n    color:var(--gold-color);\n    overflow: hidden;\n    font-weight: 400;\n    font-size: 16px;\n    margin-top: 12px;\n}\n/*----------------------------------------------------------------------------- */\n@media (min-width: 318px) and (max-width: 480px) {\n   .banner-frame {\n    font-size: 14px;\n   }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"banner-frame": `Caption_banner-frame__nOIcB`
};
export default ___CSS_LOADER_EXPORT___;

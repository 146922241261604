import React, { useEffect, useRef, useState } from "react";
import Footer from "../../layout/Footer";
import Header from "../../layout/Header";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import EditInput from "../../utils/EditInput";
import { ToastContainer } from "react-toastify";
import { errorToaster, successToaster } from "../../utils/Helper";
import { betNumbersHandler } from "../../store/slices/betNumbersSlice";
import { FaTrashAlt } from "react-icons/fa";
import { postMethod } from "../../services";
import { HERO_BET_API } from "../../services/constant";
import { langChange } from "../../lang";
import { infoHandler } from "../../store/slices/userInfoSlice";
import { createPortal } from "react-dom";
import Loader from "../../utils/Loader";
import { getHeroLotteryTime } from "./HeroHelper";
import Back from "../../utils/Back";

const HeroBetSubmit = () => {
  const [betLists, setBetLists] = useState([]);
  const { betListVal } = useSelector((state) => state.betNumbersSlice);
  const navigate = useNavigate();
  const toastId = useRef(null);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [editStatus, setEditStatus] = useState(false);
  const [totalBetAmount, setTotalBetAmount] = useState("");
  const { value } = useSelector((state) => state.userInfoSlice);

  const token = JSON.parse(localStorage.getItem("l_555_a"));

  useEffect(() => {
    if (betListVal.length > 0) {
      setBetLists(betListVal);
    } else {
      navigate(-1);
    }
  }, [betListVal]);

  const editStatusHandler = (status) => {
    setEditStatus(status);
  };

  const getEditAmount = (id, amount) => {
    let temp = [];
    betLists.map((bet) => {
      if (bet.id === id) {
        const cloneObj = { ...bet };
        cloneObj["amount"] = amount;
        temp.push(cloneObj);
      } else {
        temp.push(bet);
      }
    });
    setBetLists(temp);
  };

  const betSubmitHandler = async () => {
    if (!editStatus) {
      if (value?.user_info?.current_balance >= totalBetAmount) {
        setLoading(true);
        if (token) {
          const res = await postMethod(
            HERO_BET_API,
            { data: { ...betLists.map(el => {
              return { id: el.id, lottery_time: el.lottery_time, amount: el.amount}
            }) } },
            token.token
          );
          if (res.con) {
            successToaster("Successful", toastId);
            dispatch(infoHandler({ ...value, refresh: true }));
            setTimeout(() => {
              dispatch(betNumbersHandler([]));
              setLoading(false);
            }, 1000);
          } else {
            setLoading(false);
          }
        } else {
          navigate("/login");
        }
      } else {
        errorToaster("Balance insufficient to bet!", toastId);
      }
    } else {
      errorToaster("Confirm amount edit!", toastId);
    }
  };

  const deleteHandler = (id) => {
    setBetLists(betLists.filter((bet) => bet.id !== id));
  };

  useEffect(() => {
    if (betLists.length > 0) {
      const sumWithInitial = betLists.reduce(
        (accumulator, currentValue) =>
          Number(accumulator) + Number(currentValue.amount),
        0
      );
      setTotalBetAmount(sumWithInitial);
    }
  }, [betLists]);


  const { lang } = useSelector((state) => state.lang);
  useEffect(() => {}, [lang]);

  return (
    <>
      <Header />
      <Back />
      <div className="min-h-[85vh] mt-3">
        <div className="container mx-auto">
          <div className="w-[95%] max-w-[500px] mx-auto mb-10">
            <div className="relative overflow-x-auto">
              <table className="w-full text-sm text-left text-gray-500">
                <thead className="text-xs text-gray-700 uppercase bg-[--light-color] border border-[var(--light-color)]">
                  <tr className="text-white whitespace-nowrap">
                    <th scope="col" className="px-6 py-4 text-center">
                      {langChange.sr}
                    </th>
                    <th scope="col" className="px-6 py-4 text-center">
                      {langChange.number}
                    </th>
                    <th scope="col" className="px-6 py-4 text-center">
                      {langChange.amount}
                    </th>
                    <th scope="col" className="px-6 py-4 text-center">
                      {langChange.time}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {
                    console.log(betLists)
                  }
                  {betLists.length > 0 &&
                    betLists.map((list, i) => (
                      <tr
                        className="bg-[#2d3658] border border-[var(--light-color)] text-white whitespace-nowrap align-middle"
                        key={list.id + list.amount}
                      >
                        <td
                          scope="row"
                          className="px-6 py-4 font-medium whitespace-nowrap text-center"
                        >
                          {++i}.
                        </td>
                        <td className="text-center">
                          <div className="flex justify-center items-center py-2">
                            <img width={50} className="rounded-md border border-white" src={require(`../../assets/images/hero/${list.id}.jpg`)} alt={list.name} />
                          </div>
                        </td>
                        <td className="px-6">
                          <div className="flex justify-center items-center">
                          <EditInput
                            id={list.id}
                            type="text"
                            amount={list.amount}
                            editStatusHandler={editStatusHandler}
                            totalAmount={totalBetAmount - +list.amount}
                            getEditAmount={getEditAmount}
                          />
                          <FaTrashAlt
                            className="ms-3 text-red-600 cursor-pointer hover:text-red-700"
                            onClick={() => deleteHandler(list.id)}
                          />
                          </div>
                        </td>
                        <td className="px-6 py-4 text-center">{getHeroLotteryTime(list.lottery_time)}</td>
                      </tr>
                    ))}
                  {betLists.length > 0 && (
                    <tr className="bg-[--light-color] border border-[var(--light-color)] text-white whitespace-nowrap">
                      <td colSpan={2} className="py-3 text-center">
                        {langChange.total_bet_amount}
                      </td>
                      <td className="py-3 text-center">
                        {totalBetAmount.toLocaleString("en-us")}
                      </td>
                      <td></td>
                    </tr>
                  )}
                </tbody>
                <tfoot>
                  <tr>
                    <td colSpan={4}>
                      <button
                        type="button"
                        className="border mt-3 w-full border-[var(--main-color)] bg-[var(--accent-color)] text-[var(--main-color)] rounded py-3 text-[16px]"
                        disabled={loading}
                        onClick={betSubmitHandler}
                      >
                        {loading ? langChange.loading : langChange.bet}
                      </button>
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <ToastContainer limit={1} />
      {createPortal(loading && <Loader />, document.getElementById("portal"))}
    </>
  );
};

export default HeroBetSubmit;

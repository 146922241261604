import React from 'react'
import { FiArrowLeft } from 'react-icons/fi'
import { useNavigate } from 'react-router-dom'

export default function Back({home = false}) {
    const navigate = useNavigate();
  return (
    <div className='ml-3 mt-3'>
        <FiArrowLeft className='text-4xl cursor-pointer p-1 bg-[var(--main-color)] rounded-full text-[var(--text-color)]' onClick={e => navigate(home ? '/' : -1)} />
    </div>
  )
}

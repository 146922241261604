import React, { useEffect, useRef, useState } from "react";
import Header from "../../layout/Header";
import Footer from "../../layout/Footer";
import classes from "../../assets/styles/TwodNumberList.module.css";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { errorToaster } from "../../utils/Helper";
import { useDispatch, useSelector } from "react-redux";
import { betNumbersHandler } from "../../store/slices/betNumbersSlice";
import { getMethod } from "../../services";
import { HERO_LIST_API } from "../../services/constant";
import { langChange } from "../../lang";
import Back from "../../utils/Back";
import { createPortal } from "react-dom";
import Loader from "../../utils/Loader";
import { getHeroLotteryType } from "./HeroHelper";

const HeroNumberList = () => {
  const [amount, setAmount] = useState("");
  const [selectedHeros, setSelectedHeros] = useState([]);
  const navigate = useNavigate();
  const toastId = useRef(null);
  const params = useParams();
  const dispatch = useDispatch();
  const { value } = useSelector((state) => state.userInfoSlice);
  const [loading, setLoading] = useState(true);
  const [heros, setHeros] = useState([]);
  const token = JSON.parse(localStorage.getItem("l_555_a"));

  const { lang } = useSelector((state) => state.lang);
  useEffect(() => {}, [lang]);

  const selectHandler = (el) => {
    if (selectedHeros.length > 0 ){
      const find = selectedHeros.includes(el);
      if (find) {
        const filterExists = selectedHeros.filter(el2 => el2.id !== el.id);
        setSelectedHeros(filterExists)
      } else {
        setSelectedHeros([...selectedHeros, el])
      }
    } else {
      setSelectedHeros([el])
    }
    console.log(selectedHeros)
  }

  const fetchHeros = async () => {
    if (token) {
      setLoading(true);
      const res = await getMethod(HERO_LIST_API, token.token);
      res && setLoading(false);
      if (res.con) {
        setHeros(res.data);
      }
    }
  };

  useEffect(() => {
    fetchHeros();
  }, []);

  const setAmountHandler = (e) => {
    const amount = e.target.value;
    const positiveNumberPattern = /^[+]?\d*\.?\d*$/;
    positiveNumberPattern.test(amount) && setAmount(amount);
  };

  const submitHandler = () => {
    const submitNums = [];
    const totalAmount = selectedHeros.length * amount;
    if (selectedHeros.length > 0) {
      const lottery_time = getHeroLotteryType(params.time)
      if (amount) {
        if (value?.user_info?.current_balance >= totalAmount) {
          if (amount >= 100) {
            selectedHeros.map((num, i) =>
              submitNums.push({
                id: num.id,
                name: num.name,
                amount,
                lottery_time: lottery_time,
              })
            );
            if (submitNums.length > 0) {
              dispatch(betNumbersHandler(submitNums));
              navigate("confirm");
            }
          } else {
            errorToaster("Minium bet amount is 100", toastId);
          }
        } else {
          errorToaster("Balance insufficient to bet!", toastId);
        }
      } else {
        errorToaster("Amount is required", toastId);
      }
    } else {
      errorToaster("Please choose bet numbers", toastId);
    }
  };

  return (
    <>
      <Header />
      <Back />
      <div className="min-h-[85vh]">
        <div className="container mx-auto">
          <div className="w-[95%] max-w-[500px] mx-auto mb-10">
            <div className="py-3 sticky lg:top-[70px] top-[63px] z-10 bg-[var(--secondary-color)]">
              <div className="flex items-center">
                <input
                  className={`p-2 w-full ${classes["amount-insert"]}`}
                  type="text"
                  placeholder={langChange.min_100}
                  value={amount}
                  onChange={setAmountHandler}
                />
                <button
                  className="border w-[30%] whitespace-nowrap border-[var(--accent-color)] bg-[var(--accent-color)] text-[var(--main-color)] transition-[1s] rounded-e-lg py-2 px-3"
                  onClick={submitHandler}
                >
                  {langChange.bet}
                </button>
              </div>
            </div>
            <ul className="flex items-center justify-center flex-wrap">
              <div className="grid grid-cols-3 grid-flow-row gap-3">
                {heros.length > 0 &&
                  heros.map((el) => (
                    <img
                      key={el.name}
                      className={`w-full rounded-md cursor-pointer ${selectedHeros.includes(el) ? 'grayscale-0' : 'grayscale'}`}
                      src={require(`../../assets/images/hero/${el.id}.jpg`)}
                      alt={el.name}
                      onClick={() => selectHandler(el)}
                    />
                  ))}
              </div>
            </ul>
          </div>
        </div>
      </div>
      <Footer />
      <ToastContainer limit={1} />
      {createPortal(loading && <Loader />, document.getElementById("portal"))}
    </>
  );
};

export default HeroNumberList;

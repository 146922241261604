import React, { useEffect, useState } from "react";
import Footer from "../layout/Footer";
import Header from "../layout/Header";
import NotificationItem from "./NotificationItem";
import { MESSAGE_API } from "../services/constant";
import { getMethod } from "../services";
import Loader from "../utils/Loader";
import { langChange } from "../lang";
import Layout from "../utils/Layout";
import NoData from "../utils/NoData";

export default function Notification() {
  const [messages, setMessages] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const token = JSON.parse(localStorage.getItem('l_555_a'));
  const fetchMessage = async () => {
    setIsLoading(true);
    if (token) {
      const res = await getMethod(MESSAGE_API, token.token)
      res.con && setMessages(res.data);
      res && setIsLoading(false);
    }
  }

  useEffect(()=>{
    fetchMessage();
  }, [])

  return (
    <>
      <Header />
      <Layout type=" " name={langChange.notifications}>
          <div className="container mx-auto">
            <div className="w-[95%] min-h-[100vh] max-w-[500px] mx-auto mb-10">
              {
                messages.length > 0 ?
                messages.map(message => <NotificationItem key={message?.id} title={message.title} body={message.body} />):
                <NoData loading={isLoading} />
              }
            </div>
          </div>
      </Layout>
      {
        isLoading && <Loader />
      }
      <Footer />
    </>
  );
}

import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../../layout/Header";
import Card from "../../utils/Card";
import Footer from "../../layout/Footer";
import classes from "../../assets/styles/Deposit.module.css";
import WithdrawForm from "./WithdrawForm";
import WithdrawHistory from "./WithdrawHistory";
import { langChange } from "../../lang";
import { useSelector } from "react-redux";
import Container from "../../utils/Container";
import { BsBank2 } from "react-icons/bs";
import { FiClock } from "react-icons/fi";

const Withdraw = () => {
  const navigate = useNavigate();
  const url = window.location.pathname;
  const { lang } = useSelector((state) => state.lang);
  useEffect(() => {}, [lang]);
  return (
    <>
      <Header />
        <Container>
        <Card>
        <div className="grid grid-flow-row grid-cols-2 mb-3">
            <div
              onClick={() => navigate("/withdraw")}
              className={`flex justify-center items-center gap-2 pb-3 cursor-pointer  border-r border-[var(--accent-color)] ${
                url === "/withdraw"
                  ? "border-b-[3px] border-[var(--accent-color)] text-[var(--accent-color)]"
                  : "border-b-0"
              }`}
            >
              <BsBank2 className="text-2xl" />
              <p>{langChange.withdraw}</p>
            </div>
            <div
              onClick={() => navigate("/withdraw-history")}
              className={`flex justify-center items-center gap-2 pb-3 cursor-pointer ${
                url === "/withdraw-history"
                  ? "border-b-[3px] border-[var(--accent-color)] text-[var(--accent-color)]"
                  : "border-b-0"
              }`}
            >
              <FiClock className="text-2xl" />
              <p>{langChange.history}</p>
            </div>
          </div>
          {url === "/withdraw" ? <WithdrawForm /> : <WithdrawHistory />}
        </Card>
        </Container>
      <Footer />
    </>
  );
};

export default Withdraw;

import { createSlice } from "@reduxjs/toolkit";

const registerSlice =  createSlice({
    name: 'register',
    initialState: {
        registerVal: {}
    },
    reducers: {
        userDataHandler: (state,action) => {
            state.registerVal = action.payload;
        },
        cleanUserDataHandler: (state) => {
            state.registerVal = {};
        },
    }
});

export const {userDataHandler,cleanUserDataHandler} = registerSlice.actions;
export default registerSlice.reducer;
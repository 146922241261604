// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TwodNumberList_arr__BhPx3 {
  display: none;
}
.TwodNumberList_arr__BhPx3:checked ~ .TwodNumberList_arr_label__7wwjl {
  background-color: var(--accent-color);
  color: var(--main-color);
}
.TwodNumberList_arr_label__7wwjl {
  border: 1px solid var(--accent-color);
  height: 40px;
  width: 40px;
  border-radius: 10px;
  text-align: center;
  line-height: 40px;
  display: block;
  margin-left: 10px;
  cursor: pointer;
  -webkit-user-select: none;
          user-select: none;
}
.TwodNumberList_amount-insert__Ktngk {
  border: 1px solid var(--accent-color);
  outline: none;
  color: #fff;
  background-color: transparent;
  border-radius: 7.5px 0 0 7.5px;
  border-right: none;
  text-align: right;
}
`, "",{"version":3,"sources":["webpack://./src/assets/styles/TwodNumberList.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;AACA;EACE,qCAAqC;EACrC,wBAAwB;AAC1B;AACA;EACE,qCAAqC;EACrC,YAAY;EACZ,WAAW;EACX,mBAAmB;EACnB,kBAAkB;EAClB,iBAAiB;EACjB,cAAc;EACd,iBAAiB;EACjB,eAAe;EACf,yBAAiB;UAAjB,iBAAiB;AACnB;AACA;EACE,qCAAqC;EACrC,aAAa;EACb,WAAW;EACX,6BAA6B;EAC7B,8BAA8B;EAC9B,kBAAkB;EAClB,iBAAiB;AACnB","sourcesContent":[".arr {\n  display: none;\n}\n.arr:checked ~ .arr_label {\n  background-color: var(--accent-color);\n  color: var(--main-color);\n}\n.arr_label {\n  border: 1px solid var(--accent-color);\n  height: 40px;\n  width: 40px;\n  border-radius: 10px;\n  text-align: center;\n  line-height: 40px;\n  display: block;\n  margin-left: 10px;\n  cursor: pointer;\n  user-select: none;\n}\n.amount-insert {\n  border: 1px solid var(--accent-color);\n  outline: none;\n  color: #fff;\n  background-color: transparent;\n  border-radius: 7.5px 0 0 7.5px;\n  border-right: none;\n  text-align: right;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"arr": `TwodNumberList_arr__BhPx3`,
	"arr_label": `TwodNumberList_arr_label__7wwjl`,
	"amount-insert": `TwodNumberList_amount-insert__Ktngk`
};
export default ___CSS_LOADER_EXPORT___;

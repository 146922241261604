import { createSlice } from "@reduxjs/toolkit";

const userInfoSlice = createSlice({
    name: 'user-info',
    initialState: {
        value: {},
    },
    reducers: {
        infoHandler: (state,action) => {
            state.value = action.payload;
        }
    }
});

export const {infoHandler} = userInfoSlice.actions;
export default userInfoSlice.reducer;
import React, { useEffect, useRef, useState } from 'react';
import {FaPen,FaCheck} from 'react-icons/fa';
import { errorToaster } from './Helper';
import { useSelector } from 'react-redux';

const EditInput = ({id,type,amount,getEditAmount,editStatusHandler,totalAmount}) => {
  const [editAmountStatus,setEditAmountStatus] = useState(false);
  const [editAmount,setEditAmount] = useState(amount);
  const toastId = useRef(null);
  const { value } = useSelector(state => state.userInfoSlice);

  const editAmountHandler = e => {
    const amount = e.target.value;
    const positiveNumberPattern = /^[+]?\d*\.?\d*$/;
    positiveNumberPattern.test(amount) && setEditAmount(amount);
  }
  useEffect(() => {
    setEditAmount(amount)
  }, [amount])
  return (
    <>
        <div className='flex items-center justify-end cursor-pointer'>
            {
                editAmountStatus? 
                <input type={type} value={editAmount} className='text-black text-end w-[70px] outline-none rounded p-1' onChange={editAmountHandler}/> :
                Number(amount)?.toLocaleString('en-us')
            }
            {

                editAmountStatus? <FaCheck color='#38ff38' size="13px" className='ms-2' onClick={() => {
                    if (editAmount < 100) {
                        errorToaster('Minimum bet amount is 100!', toastId);
                    } else {
                        if (editAmount <= value?.user_info?.current_balance - totalAmount) {
                            setEditAmountStatus(!editAmountStatus);
                            getEditAmount(id,editAmount);
                            editStatusHandler(false);
                        } else {
                            errorToaster('Balance insufficient to bet!', toastId)
                        }
                    }
                }}/>:
                <FaPen color='#38ff38' size="13px" className='ms-2' onClick={() => {setEditAmountStatus(!editAmountStatus); editStatusHandler(true);}}/>
            }
        </div>
    </>
  )
}

export default EditInput

import React, { useEffect } from "react";
import Header from "../../layout/Header";
import Footer from "../../layout/Footer";
import classes from "../../assets/styles/TwodHistory.module.css";
import { useNavigate } from "react-router-dom";
import noteImg from "../../assets/images/icons/note.png";
import calendarImg from "../../assets/images/icons/calendar.png";
import DubaiTwodResultHistory from "./DubaiTwodResultHistory";
import DubaiTwodBetHistory from "./DubaiTwodBetHistory";
import { langChange } from "../../lang";
import { useSelector } from "react-redux";
import Back from "../../utils/Back";

const DubaiTwodHistory = () => {
  const navigate = useNavigate();
  const url = window.location.pathname;

  const { lang } = useSelector((state) => state.lang);
  useEffect(() => {}, [lang]);
  return (
    <>
      <Header />
      <Back />
      <div className="min-h-[85vh]">
        <div className="container mx-auto">
          <div className="w-[95%] max-w-[500px] mx-auto">
            <div className={classes["history-card-header"]}>
              <span
                className={`${
                  (url === "/all/bet/dubai2d/history" ||
                    url === "/2d3d/bet/dubai2d/history") &&
                  classes.active
                }`}
                onClick={() => navigate("/all/bet/dubai2d/history")}
              >
                <img src={noteImg} className="w-[25px] mr-2" />
                <p className="text-[13px] mb-0 mt-1">{langChange.history}</p>
              </span>
              <span
                className={`${
                  (url === "/all/bet/dubai2d/result-history" ||
                    url === "/2d3d/bet/dubai2d/result-history") &&
                  classes.active
                }`}
                onClick={() => navigate("/all/bet/dubai2d/result-history")}
              >
                <img src={calendarImg} className="w-[25px] mr-2" />
                <p className="text-[13px] mb-0 mt-1">
                  {langChange.lottery_history}
                </p>
              </span>
            </div>
            {url === "/all/bet/dubai2d/history" ? (
              <DubaiTwodBetHistory />
            ) : (
              <DubaiTwodResultHistory />
            )}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default DubaiTwodHistory;

import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import classes from "../../assets/styles/BetHistory.module.css";
import NoData from "../../utils/NoData";
import Loader from "../../utils/Loader";
import { useNavigate } from "react-router-dom";
import { getMethod } from "../../services";
import { TWO_D_BET_LIST_API } from "../../services/constant";
import Pagination from "../../utils/Pagination";
import { useSelector } from "react-redux";
import moment from "moment-timezone";
import Select from "react-select";
import SingleDatePicker from "../../utils/SingleDatePicker";
import HeroBetHistoryCard from "./HeroBetHistoryCard";

const HeroBetHistory = () => {
  const [loading, setLoading] = useState(false);
  const [history, setHistory] = useState([]);
  const [totalPage, setTotalPage] = useState(0);
  const [page, setPage] = useState(1);
  const [time, setTime] = useState("9");
  const [date, setDate] = useState(
    moment().tz("Asia/Yangon").format("YYYY-MM-DD")
  );
  const navigate = useNavigate();

  const { lang } = useSelector((state) => state.lang);
  useEffect(() => {}, [lang]);

  const groupDateByDate = (groupData) => {
    const groupedData = {};
    groupData.map((item) => {
      const date = item.date_time;
      if (!groupedData[date]) {
        groupedData[date] = [];
      }
      groupedData[date].push(item);
    });
    return groupedData;
  };

  const fetchHistory = async () => {
    setLoading(true);
    const token = JSON.parse(localStorage.getItem("l_555_a"));
    if (token) {
      const res = await getMethod(
        `${TWO_D_BET_LIST_API}?type=hero&page=${page}&date=${date}&time=${time}&limit=1000`,
        token.token
      );
      if (res) {
        setLoading(false);
        if (res.data) {
          setHistory(groupDateByDate(res.data));
        }
        if (res.meta) {
          if (res.meta.last_page) {
            setTotalPage(res.meta.last_page);
          }
        }
      }
    } else {
      navigate("/login");
    }
  };

  useEffect(() => {
    fetchHistory();
  }, [page, time, date]);

  const lotteryRanges = [
    { value: 'One', label: "09:30 AM" },
    { value: 'Two', label: "12:00 PM" },
    { value: 'Three', label: "02:00 PM" },
    { value: 'Four', label: "04:30 PM" },
    { value: 'Five', label: "07:00 PM" },
    { value: 'Six', label: "09:00 PM" },
  ];

  return (
    <>
      <div
        className={`${classes["auth-card-body"]} bg-[var(--light-color)] border border-[var(--light-color)] rounded-lg my-3`}
      >
        <div className="flex justify-between mb-3">
          <SingleDatePicker date={date} setDate={setDate} />
          <Select
            className="w-[150px] text-[var(--secondary-color)] z-[21]"
            placeholder={`${lotteryRanges[0]?.label || ""}`}
            onChange={(e) => setTime(e.value)}
            options={lotteryRanges}
          />
        </div>
        <div className={classes["table-card-container"]}>
          {Object.keys(history).length > 0 ? (
            Object.keys(history).map((group_date, i) => (
              <HeroBetHistoryCard
                key={group_date}
                data={history[group_date]}
                date={group_date}
                count={i}
              />
            ))
          ) : (
            <NoData loading={loading} />
          )}
        </div>
        {totalPage > 1 && (
          <div className="flex justify-center pt-2">
            <Pagination
              setPage={setPage}
              totalPage={totalPage}
              setLoading={setLoading}
            />
          </div>
        )}
      </div>
      {ReactDOM.createPortal(
        loading && <Loader />,
        document.getElementById("portal")
      )}
    </>
  );
};

export default HeroBetHistory;

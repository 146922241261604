import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import classes from "../../assets/styles/WinnerList.module.css";
import NoData from "../../utils/NoData";
import Loader from "../../utils/Loader";
import { useNavigate } from "react-router-dom";
import { getMethod } from "../../services";
import { THREE_D_WINNER_RECORD_API } from "../../services/constant";
import Pagination from "../../utils/Pagination";
import Header from "../../layout/Header";
import Footer from "../../layout/Footer";
import prizeImg from "../../assets/images/icons/winner.png";
import { langChange } from "../../lang";
import { useSelector } from "react-redux";
import Back from "../../utils/Back";

const GoldThreedWinnerList = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [winnerLists, setWinnerLists] = useState([]);
  const [totalPage, setTotalPage] = useState(0);
  const [from, setFrom] = useState(1);
  const [page, setPage] = useState(1);

  const fetchWinnerList = async () => {
    setLoading(true);
    const token = JSON.parse(localStorage.getItem("l_555_a"));
    if (token) {
      const res = await getMethod(`${THREE_D_WINNER_RECORD_API}`, token.token);
      if (res) {
        setLoading(false);
        if (res.data) {
          setWinnerLists(res.data);
        }
        if (res.meta) {
          if (res.meta.from) {
            setFrom(res.meta.from);
          }
          if (res.meta.last_page) {
            setTotalPage(res.meta.last_page);
          }
        }
      }
    } else {
      navigate("/login");
    }
  };
  useEffect(() => {
    fetchWinnerList();
  }, [page]);

  const { lang } = useSelector((state) => state.lang);
  useEffect(() => {}, [lang]);
  return (
    <>
      <Header />
      <Back />
      <div className="container mx-auto min-h-[85vh] mb-3">
        <div className="w-[95%] max-w-[500px] mx-auto">
          <div className="flex items-center justify-center flex-col cursor-pointer">
            <img src={prizeImg} className="w-[50px]" />
            <p className="text-[16px] mb-0 mt-3">ထီပေါက်သူ</p>
          </div>
          <div
            className={`${classes["auth-card-body"]} bg-[var(--light-color)] border border-[var(--light-color)] rounded-lg mt-2`}
          >
            <div className={classes["table-card-container"]}>
              <ul className={classes["table-header"]}>
                <li className="text-center">
                  <b>{langChange.sr}</b>
                </li>
                <li className="text-center">{langChange.lucky_number}</li>
                <li className="text-center">{langChange.twit}</li>
                <li className="text-center">{langChange.bet_amount}</li>
                <li className="text-center">{langChange.total}</li>
                <li className="text-center">{langChange.status}</li>
                <li className="text-center">{langChange.date}</li>
              </ul>
              {winnerLists.length > 0 ? (
                winnerLists.map((winner, i) => (
                  <ul key={i} className={classes["table-header"]}>
                    <li className="text-center">{i + from}.</li>
                    <li className="text-center text-[#f6e25d]">
                      {winner.lucky_number ?? "--"}
                    </li>
                    <li className="text-center">{winner.votes ?? "--"}</li>
                    <li className="text-end">
                      {winner.amount.toLocaleString("en-us")}
                    </li>
                    <li className="text-end">
                      {winner.total.toLocaleString("en-us")}
                    </li>
                    <li className="text-center">
                      {winner.status === "Full"
                        ? langChange.full
                        : langChange.twit}
                    </li>
                    <li className="text-center">{winner.date}</li>
                  </ul>
                ))
              ) : (
                <NoData loading={loading} />
              )}
            </div>
            {totalPage > 1 && (
              <div className="flex justify-center pt-2">
                <Pagination
                  setPage={setPage}
                  totalPage={totalPage}
                  setLoading={setLoading}
                />
              </div>
            )}
          </div>
          {ReactDOM.createPortal(
            loading && <Loader />,
            document.getElementById("portal")
          )}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default GoldThreedWinnerList;

import React from 'react'
import { motion } from 'framer-motion'

const AddNewNumModal = ({ isShow, setIsShow, children }) => {
  return (
    <>
      <div onClick={() => setIsShow(false)} className="fixed top-0 right-0 bottom-0 left-0 z-[99999] bg-[#00000082] flex items-center justify-center"
        style={{ display: isShow ? 'flex' : 'none' }}
      >
        <motion.div
          initial={{ top: isShow ? '-100px' : 0 }}
          animate={{ top: isShow ? 0 : '-100px' }}
          transition={{ duration: 0.5 }}
          className='w-[95%] max-w-[500px] relative mx-auto bg-[var(--light-color)] border border-[var(--light-color)] p-4 rounded-2xl'
          onClick={e => e.stopPropagation()}
        >
          {children}
        </motion.div>
      </div>
    </>
  )
}

export default AddNewNumModal

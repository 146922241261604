import React, { useEffect, useState } from "react";
import { FcRotateToLandscape } from "react-icons/fc";

export default function Shan({ api }) {
  const token = JSON.parse(localStorage.getItem("l_555_a"));
  const [isPortrait, setIsPortrait] = useState(window.matchMedia("(orientation: portrait)").matches);

  const handleOrientationChange = () => {
    setIsPortrait(window.matchMedia("(orientation: landscape)").matches);
    console.log(window.matchMedia("(orientation: landscape)").matches);
  };

  useEffect(() => {
    window.addEventListener("orientationchange", handleOrientationChange);

    return () => {
      window.removeEventListener("orientationchange", handleOrientationChange);
    };
  }, []);

  return (
    <div>
      {isPortrait ? (
        <div className="min-h-screen w-full bg-green-900 flex flex-col justify-center items-center">
          <FcRotateToLandscape className="text-8xl mx-auto mb-4" />
          <p className="text-center">Please rotate your screen.</p>
        </div>
      ) : (
        <iframe className="w-full min-h-screen" src={api + "?token=" + token.token}></iframe>
      )}
    </div>
  );
}

import React from 'react';
import classes from '../assets/styles/PaymentList.module.css';
import { FiCheckCircle } from 'react-icons/fi';

const PaymentList = ({id,photo,data,event,name}) => {
    
  return (
    <li className={classes['payment-list']} onChange={event}>
        <input type='radio' id={id} name="payment" className={classes['payment-check']}/>
        <label htmlFor={id}>
                <div className={classes['payment-image']}>
                    <img src={photo} style={{filter: data?.id === id? 'grayscale(0)': 'grayscale(100%)'}} alt={name} />
                </div>
        </label>
        {
            data?.id === id &&
            <span className={classes['check-icon']}>
                <FiCheckCircle />
            </span>
        }
    </li>
  )
}

export default PaymentList
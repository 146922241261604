import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import imageLoader from '../assets/images/preload.png';
import classes from '../assets/styles/ProviderCard.module.css';
const GameCard = ({name,image,imageLoad,setImageLoad,event}) => {


  return (
    <li className='w-1/3 md:w-1/4 lg:w-1/6 cursor-pointer select-none p-2' onClick={event}>
        <div className='bg-[var(--accent-color)] pb-2 rounded-lg'>
            <LazyLoadImage
                className="object-cover w-full h-full rounded-lg"
                alt=""
                // effect="blur"
                src={imageLoad ? imageLoader: image}
                afterLoad={() => setImageLoad(false)}
                beforeLoad={() => setImageLoad(true)}/>
            <p className={`mb-0 mt-1.5 select-none text-center text-[var(--main-color)] text-sm lg:text-base font-semibold ${classes.mobileFont}`}>{name}</p>
        </div>
    </li>
  )
}

export default GameCard

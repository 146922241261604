const domain = process.env.REACT_APP_DOMAIN;
const provider_domain = process.env.REACT_APP_PROVIDER_DOMAIN;
// const domain = 'http://192.168.89.119:8080/';

export const REGISTER_API = `${domain}api/register`;
export const GAME_REGISTER_API = `${domain}api/game-register`;
export const REGISTER_OTP_API = `${domain}api/create-login`;
export const CHECK_OTP_API = `${domain}api/verify`;
export const NAME_CHECK_API = `${domain}api/check-name`;
export const PASSWORD_CREATE_OTP_API = `${domain}api/password/create`;
export const PASSWORD_RESET_API = `${domain}api/password/reset`;
export const LOGIN_API = `${domain}api/login`;
export const CHANGE_PASSWORD_API = `${domain}api/change-password`;
export const USER_INFO_API = `${domain}api/user-info`;
export const PROVIDER_CONNECT_API = `${provider_domain}api/provider-connect-lists`;
export const PROVIDER_API = `${provider_domain}api/providers`;
export const GAMES_LIST_API = `${provider_domain}api/game-lists`;
export const GAME_LAUNCH_API = `${provider_domain}api/slot/init`;
export const PAYMENT_PROVIDER_API = `${domain}api/providers`;
export const USER_DEPOSIT_API = `${domain}api/deposits`;
export const DEPOSIT_HISTORY_API = `${domain}api/deposits`;
export const USER_WITHDRAW_API = `${domain}api/withdrawals`;
export const WITHDRAW_HISTORY_API = `${domain}api/withdrawals`;
export const BANNER_API = `${domain}api/advertise`;
export const CAPTION_API = `${domain}api/caption`;
export const POCKET_STATUS_API = `${domain}api/app-system`;
export const CONTACT_API = `${domain}api/contact`;
export const TRANSACTION_BETWEEN_API = `${domain}api/transaction-between-787`;
export const MESSAGE_API = `${domain}api/messages`;
export const FEEDBACK_API = `${domain}api/feedback`;
export const FEEDBACK_PHOTO_API = `${domain}api/file-upload`;
export const FEEDBACK_PHOTO_REMOVE_API = `${domain}api/file-remove`;
export const PROMOTION_API = `${domain}api/promotions`;
// for 2D 
export const TWO_D_BET_LIST_API = `${domain}api/bets`;
export const TWO_D_STATUS_API = `${domain}api/close-mm2d`;
export const TWO_D_CLOSED_STATUS_API = `${domain}api/two-digits/limit-myanmar`;
export const TWO_D_LIMIT_API = `${domain}api/two-digits/limit`;
export const TWO_D_BET_THAI_API = `${domain}api/bets-thai`;
export const TWO_D_WINNER_API = `${domain}api/two_winners`;
export const TWO_D_WINNER_RECORD_API = `${domain}api/two-winner-records`;
export const TWO_D_RESULT_API = `${domain}api/2d-results`;
export const TWO_D_REAL_TIME_API = `${domain}api/two-lottery-close`;
// for Dubai 2D
export const DUBAI_TWO_D_STATUS_API = `${domain}api/close-dubai2d`;
export const DUBAI_TWO_D_REAL_TIME_API = `${domain}api/two-lottery-close`;
export const DUBAI_TWO_D_LIMIT_API = `${domain}api/two-digits/limit`;
export const DUBAI_TWO_D_BET_API = `${domain}api/bets`;
export const DUBAI_TWO_D_BET_LIST_API = `${domain}api/bets`;
export const DUBAI_TWO_D_RESULT_API = `${domain}api/2d-results`;
export const DUBAI_TWO_D_WINNER_API = `${domain}api/two-winner-records`;

// for Royal Thai 
export const ROYAL_THAI_BET_API = `${domain}api/royal-thai2d-bets`;

// for gold 2D
export const GOLD_BET_API = `${domain}api/bets`;
export const GOLD_RESULT_API = `${domain}api/gold-2d-results`;
export const GOLD_HISTORY_API = `${domain}api/gold-2d-history`;
export const GOLD_USER_WIN_API = `${domain}api/two_winners`;

// for hero 2d 
export const HERO_LIST_API = `${domain}api/animal-2d`;
export const HERO_BET_API = `${domain}api/bets-animal-2d`;
export const HERO_RESULT_API = `${domain}api/animal-2d-results`;

// for BTC 2D
export const BTC_TWO_D_STATUS_API = `${domain}api/close`;
export const BTC_TWO_D_REAL_TIME_API = `${domain}api/two-lottery-close`;
export const BTC_TWO_D_BET_LIST_API = `${domain}api/bets`;
export const BTC_TWO_D_RESULT_API = `${domain}api/2d-results`;
export const BTC_TWO_D_WINNER_API = `${domain}api/two-winner-records`;
export const BTC_TWO_D_LIMIT_API = `${domain}api/two-digits/limit`;

// for 3D
export const THREE_D_STATUS_API = `${domain}api/close_three-mm3d`;
export const THREE_D_LUCKY_DRAWS_API = `${domain}api/three_lucky_draws`;
export const THREE_D_LUCKY_NUMS_API = `${domain}api/three_lucky_numbers`;
export const THREE_D_WINNER_API = `${domain}api/three_winners`;
export const THREE_D_LIMIT_API = `${domain}api/three-digits/limit`;
export const THREE_D_WINNER_RECORD_API = `${domain}api/three-winner-records`;
export const THREE_D_LOTTERY_CLOSE_API = `${domain}api/three-lottery-close`;

// for gold 3d 
export const GOLD_THREE_D_STATUS_API = `${domain}api/close_three`;

// for 4d
export const FOUR_D_BET_API = `${domain}api/four-bets`;
export const FOUR_D_RESULT_API = `${domain}api/four-results`;
export const FOUR_D_USER_REPORT_API = `${domain}api/four-user-reports`;
export const FOUR_D_WINNER_API = `${domain}api/four-win-records`;
export const FOUR_D_WINNER_RECORD_API = `${domain}api/four-winners`;
export const FOUR_D_ClOSE_API = `${domain}api/four-lottery-close`;
export const FOUR_D_LAOS_RESULT_API = `${domain}api/laos-four-results`;

// for sixd 
export const SIX_CLOSE_API = `${domain}api/close_six`;
export const SIX_BET_API = `${domain}api/six-bets`;
export const SIX_LOTTERY_LIST_API = `${domain}api/lottery-volumes`;
export const ADD_REFERRAL_CODE_API = `${domain}api/add-referral-code`;
export const SIX_DIGIT_SETTING_API = `${domain}api/six-digits-setting`;
export const SIX_RESULT_API = `${domain}api/six-results`;
export const SIX_LOTTERY_CLOSE_API = `${domain}api/six-lottery-close`;
export const CHECK_NUMBER_API = num => `${domain}api/check-number/${num}`;
export const SHUFFLE_NUMBER_API = `${domain}api/shuffle-numbers`;
export const OLD_HISTORY_API = `${domain}api/old-histories`;
export const SIX_WINNER_API = `${domain}api/six-win-records`;
export const SIX_WINNER_RECORD_API = `${domain}api/six-winners`;

// for game 
export const GAME_ONE_API = `https://sea-turtle-app-b5qso.ondigitalocean.app`;
export const GAME_TWO_API = `https://effortless-arithmetic-e3ef90.netlify.app`;
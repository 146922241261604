import React from 'react'
import classes from '../assets/styles/Loader.module.css';;

const Loader = () => {
  return (
    <div className={classes['loader-container']}>
        <span className={classes.loader}></span>
    </div>
  )
}

export default Loader

import React, { useEffect } from 'react';
import classes from '../assets/styles/NoData.module.css';
import { langChange } from '../lang';
import { useSelector } from 'react-redux';

const NoData = ({loading = false}) => {
  const { lang } = useSelector(state => state.lang);
  useEffect(() => {}, [lang])
  return (
    <div className={classes['nofount']}>
      {
        loading ? langChange.loading : langChange.no_data_found
      }
    </div>
  )
}

export default NoData